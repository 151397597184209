import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Select, message, List, Avatar, Upload } from 'antd';

// 
import { IconCloseModal, IconArrowSelect } from '../../Widgets/Iconos';

import { Paypear } from '../../Widgets/paypear';

import './../../../Styles/modales.css'

import TextArea from "antd/lib/input/TextArea";

const axios = require('axios');
const moment = require('moment');

const { Title, Text } = Typography;

const { Option } = Select;


class ModalComprobantes extends Component {

    formModalMantenimiento = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            loading: false,

            pago_mantenimiento: null
        }
    };

    componentDidMount() {

        this.getPagoMantenimiento()

    }

    getPagoMantenimiento = () => axios.get('/mantenimiento/get', {
        params: {
            _id: this.props._id,
        }
    })
        .then(({ data }) => {
            console.log('data', data)
            this.setState({ pago_mantenimiento: data.data })
        })
        .catch(error => {
            message.error('No fue posible guardar')
        })


    render() {
        const { id, accionModal, visible } = this.props;
        return (
            <Row>
                <Col span={24} >
                    <Title level={3} style={{ textAlign: "center", marginTop: "-50px" }}>Lista de Pagos Realizados</Title>
                </Col>
                <Col span={24} >
                    <List
                        itemLayout="horizontal"
                        dataSource={(Array.isArray(this.state.pago_mantenimiento?.historial_pagos)) ? this.state.pago_mantenimiento?.historial_pagos : []}

                        header={
                            <Row style={{ width: '100%' }}>
                                <Col xs={24} md={5} className="center">
                                    <strong>Cantidad Pagada</strong>
                                </Col>
                                <Col xs={24} md={4} className="center">
                                    <strong>Cantidad Restante</strong>
                                </Col>
                                <Col xs={24} md={5} className="center">
                                    <strong>Total abonado</strong>
                                </Col>
                                <Col xs={24} md={5} className="center">
                                    <strong>Fecha</strong>
                                </Col>
                                <Col xs={24} md={5} className="center">
                                    <strong>Realizada por</strong>
                                </Col>
                            </Row>
                        }
                        renderItem={({ cantidad, restante, abono }) => (

                            <List.Item
                            // actions={[<a key="list-loadmore-edit">edit</a>, <a key="list-loadmore-more">more</a>]}
                            >
                                <Row style={{ width: '100%', marginTop: '2em' }}>
                                    <Col xs={24} md={5} className="center">
                                        <strong>$ {cantidad.toMoney()} MXN</strong>
                                    </Col>
                                    <Col xs={24} md={4} className="center">
                                        <Text>$ {restante.toMoney()} MXN</Text>
                                    </Col>
                                    <Col xs={24} md={5} className="center">
                                        <Text>$ {abono.abono.toMoney()} MXN</Text>
                                    </Col>
                                    <Col xs={24} md={5} className="center">
                                        <Text>{moment(abono.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</Text>
                                    </Col>
                                    <Col xs={24} md={5} className="center">
                                        {abono.admin_id?.nombre} {abono.admin_id?.apellidos}
                                    </Col>

                                    <Col xs={24} md={24} style={{ marginTop: '1em', padding: "0 10px" }}>
                                        <Upload
                                            // listType="picture"
                                            showUploadList={{
                                                showRemoveIcon: false,
                                                // showDownloadIcon: false
                                            }}
                                            className="upload-list-inline"
                                            fileList={

                                                abono.comprobantes.map((comprobante, index) => ({

                                                    uid: index,
                                                    name: comprobante,
                                                    status: 'done',
                                                    url: axios.defaults.baseURL + '/upload/' + comprobante,

                                                }))
                                            }>
                                        </Upload>
                                    </Col>
                                </Row>
                            </List.Item>
                        )
                        }
                    />

                </Col>
            </Row>
        )
    }
}



export default function (props) {


    const { onClose, visible } = props
    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={null}
        closable={false}
        className={"modal-form "}
        destroyOnClose={true}
        zIndex={1000}
        width={800}
        okText="Guardar"
        cancelText="Salir"

    >
        <Row className="modal-header">
            <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={onClose} id="btn-modalPaymentClose">
                <IconCloseModal />
            </Button>
        </Row>
        <ModalComprobantes {...props} />
    </Modal>
}
import React, { Component } from 'react';
import { Row, Col, Button, Radio, List, Card, Typography, Popconfirm, message, Pagination } from 'antd'
import { Link } from "react-router-dom";
import axios from 'axios'
//componentes
import { IconPlus, IconEdit, IconDelete } from '../../Widgets/Iconos.js'
//modal
import ModalProveedores from '../Modales/Proveedores/ModalProveedores';
//css
import './../../../Styles/Global.css';

const {Text} = Typography;

export default class Proveedores extends Component{
	constructor(props){
		super(props)
		this.state = {
            loading: false,
			proveedores: [{
                categoria_id: {}
            }],
            modalProveedoresVisible: false,
            modalTitle: 'Añadir',
            proveedor_id: undefined,

            /*Paginador*/
            page: 1,
            limit: 10,
            pageCount: 0,
            itemCount: 1,
		}
	}

    
	/**
     * @methodOf Proveedores
     * @function componentDidMount
     *
     */
    componentDidMount() {
        this.props.updateNavigationInformation('Proveedores');
        this.listProveedores(1)
    }


    /**
     * @methodOf Proveedores
     * @function hideModal
     * @description Cierra el modal y actualiza la lista de proveedores
     */
    hideModal = () => {
        this.setState({modalProveedoresVisible: false, proveedor_id: undefined})
        this.listProveedores(1)
    }


     /**
     * @methodOf Proveedores
     * @function listProveedores
     * @description trae la lista de proveedores
     */
    listProveedores = (page) => {
        this.setState({loading: true})
        axios.get('proveedores/list',{
            params:{
                page: page,
                limit: 10,
            }
        }).then(response => {
            this.setState({proveedores: response.data.data.itemsList})
        }).catch(error => {
            console.log(error)
            message.error('No se cargaron los Proveedores')
        }).finally(()=>{this.setState({loading:false})})
    }


     /**
     * @methodOf Proveedores
     * @function deleteProveedor
     * @description Elimina un proveedor
     */
    deleteProveedor = (id) => {
        this.setState({loading:true})
        axios.post('proveedores/delete',{
           _id: id
        }).then(response => {
            message.success('El proveedor ha sido eliminado')
        }).catch(error => {
            console.log(error)
            message.error('No se elimino el Proveedor')
        }).finally(()=>{this.listProveedores(1)})
    }

	render(){
		return(
 
				<section className="section">
					<Row className="section-header">
						<Col span={12} className="">
							<Radio.Group className="radio-grp-green" value="a">
								<Radio.Button value="a">Proveedores</Radio.Button>
      							<Radio.Button value="b">
      								<Link className="text-white" to={"/admin/categorias"}>Categorias</Link>
      							</Radio.Button>
							</Radio.Group>
						</Col>
						<Col span={12} className=" flex-right">
							<Button className="btn-plus right" onClick={()=>{this.setState({modalProveedoresVisible: true, modalTitle: 'Añadir'})}}>
								<IconPlus/>
							</Button>
						</Col>
					</Row>
					
                    <Row className="section-content">
						<Col span={24}>
							 <List
                                className="component-list"
                                itemLayout="horizontal"
                                loading={this.state.loading}
                                dataSource={this.state.proveedores}
                                renderItem={item => (
                                    <List.Item className="component-list-item">
                                        <Card className="card-list">
                                            <Row style={{width: '100%'}} className="">
                                                <Col span={4} className="center ">
                                                    <Text>{item.nombre}</Text>
                                                </Col>
                                                <Col span={4} className="center ">
                                                	<Text>{item.categoria_id.nombre}</Text>
                                                </Col>
                                                <Col span={4} className="center ">
                                                	<Text>{item.info_contacto}</Text>
                                                </Col>
                                                <Col span={4} className="center ">
                                                	<Text className="text-link">{item.catalogo}</Text>
                                                </Col>
                                                <Col span={4} className="center ">
                                                	<Text>{item.descripcion}</Text>
                                                </Col>
                                                <Col span={4} className="center">
                                                    <Button className="btn btn-edit" onClick={(e) => { 
                                                        this.setState({modalProveedoresVisible: true, modalTitle: 'Editar', proveedor_id: item._id}) }}>
                                                        <IconEdit/>
                                                    </Button>
                                                    <Popconfirm
                                                        title="¿Deseas Eliminar?"
                                                        placement="topRight"
                                                        onConfirm={e => {
                                                            this.deleteProveedor(item._id)
                                                        }}
                                                        onCancel={e => e.stopPropagation()}
                                                        okText="Si"
                                                        cancelText="No"
                                                        onClick={e => e.stopPropagation()}
                                                    >
                                                        <Button className="btn btn-delete"
                                                                onClick={e => e.stopPropagation()}>
                                                            <IconDelete/>
                                                        </Button>
                                                    </Popconfirm>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </List.Item>
                                )}
                            />
						</Col>
                        <Row style={{ margin: "1em 2em", paddingBottom:"3em" }}>
                            <Pagination defaultCurrent={1} total={this.state.itemCount} onChange={(page)=>this.listProveedores(page)} />
                        </Row>
					</Row>
    		  
               <ModalProveedores
                    visible={this.state.modalProveedoresVisible}
                    modalTitle={this.state.modalTitle}
                    closeMethod={this.hideModal}
                    proveedor_id={this.state.proveedor_id}
                />
		 
         	</section>
             
		)	
	}
}
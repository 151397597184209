import React, { Component } from 'react'
import { Layout } from 'antd';
import { Route, Switch } from "react-router-dom";


//COMPOENETES
import Header from "../Components/Header/Header";
import SideBar from "../Components/Sidebar/Sidebar";
import Dashboard from "../Components/Colono/Dashboard";
import Mensajes from "../Components/Colono/Mensajes/Mensajes";
import Proveedores from "../Components/Colono/Proveedores/Proveedores";
import Archivos from "../Components/Colono/Documentos/Archivos";
import Mantenimiento from "../Components/Colono/Mantenimiento/Mantenimiento";
import OrdenesTrabajo from "../Components/Colono/OrdenesTrabajo/OrdenesTrabajo";
import { SalonUsosMultiples } from "../Components/Colono/SalonUsosMultiples/SalonUsosMultiples";


import Cookies from 'universal-cookie';
import axios from 'axios'
import {Modal} from 'antd';

import { SocketContext } from '../Hooks/Socket';


//css
import './../Styles/Global.css';

const { Content } = Layout;
/*axios.defaults.baseURL = "";*/



/**
 *
 * @export
 * @class AdminRoutes
 * @extends {Component}
 */
export default class ColonoRoutes extends Component {

    static contextType = SocketContext;

    state = {
        back: false,
        responsiveSidebar: false,
        showSearch: false,
        filterSearch: "",
        searching: false,
        title: "Dashboard"
    };



    componentDidMount(){
        const cookies = new Cookies();


        let token = null;
        let sessionStorageToken = sessionStorage.getItem("token")
        let cookieStorageToken = cookies.get('token')
        if (sessionStorageToken !== null && sessionStorageToken !== undefined)
            token = sessionStorageToken;

        if (cookieStorageToken !== null && cookieStorageToken !== undefined){
            token = cookieStorageToken;
            sessionStorage.setItem("token", cookieStorageToken)
        }

        this.context.emit("start",sessionStorage.getItem("token"))
        axios.defaults.headers.common['Authorization'] = token;

        axios.get('/logged')
            .then((data) => {
                this.props.setUser(data.data)
            })
            .catch(e => {

                Modal.error({
                    title: "Su sesión ha sido cerrada.",
                    content: "Debe iniciar sesión para continuar",
                    onOk: () => {
                        window.location.replace("/login")
                    }
                })
            })

    }



    updateTitle = (title) => {
        if (title !== undefined) {
            this.setState({title})
        }
    };
    
    /**
     * Actualiza la información del header
     * */
    updateNavigationInformation = (pageTitle, back = false, onBackPressed = () => { }) => {
        this.onBack = onBackPressed;
        this.setState({
            title: pageTitle,
            back: back
        })
    };

    onBack = () => { };

    render() {
        const { title, back } = this.state;
        const { updateNavigationInformation } = this;

        return (
            <Layout className="main-layout">

                <Header
                    title={title}
                    back={back}
                    onBack={this.onBack}
                    setUser={this.props.setUser}
                />
                
                <Layout className="route-layout">
                    <SideBar
                        responsiveSidebar={this.state.responsiveSidebar}
                        hideSidebar={() => this.setState({ responsiveSidebar: false })}
                    />
                    <Layout style={{ maxHeight: "150vh", background: "#f4f8fb", overflow: "auto" }}>
                        <Content>
                            <Switch>
                                <Route exact path="/colono/dashboard"
                                    render={(props) => <Dashboard
                                        {...props}
                                        updateNavigationInformation={updateNavigationInformation}
                                    />
                                    }
                                />

                                <Route exact path="/colono/mensajes"
                                    render={(props) => <Mensajes
                                        {...props}
                                        updateNavigationInformation={updateNavigationInformation}

                                    />
                                    }
                                />

                                <Route exact path="/colono/ordenesTrabajo"
                                    render={(props) => <OrdenesTrabajo
                                        {...props}
                                        updateNavigationInformation={updateNavigationInformation}

                                    />
                                    }
                                />

                                <Route exact path="/colono/salonUsosMultiples"
                                    render={(props) => <SalonUsosMultiples
                                        {...props}
                                        updateNavigationInformation={updateNavigationInformation}

                                    />
                                    }
                                />

                                <Route exact path="/colono/mantenimiento"
                                    render={(props) => <Mantenimiento
                                        {...props}
                                        updateNavigationInformation={updateNavigationInformation}

                                    />
                                    }
                                />
                                    {/*id  de la casa que esta relacionada con una carpeta*/}
                                <Route exact path="/colono/archivos/:id"
                                    render={(props) => <Archivos
                                        {...props}
                                        updateNavigationInformation={updateNavigationInformation}

                                    />
                                    }
                                />


                                <Route exact path="/colono/proveedores"
                                    render={(props) => <Proveedores
                                        {...props}
                                        updateNavigationInformation={updateNavigationInformation}
                                    />
                                    }
                                />
                            </Switch>
                        </Content>
                    </Layout>
                </Layout>
            </Layout>
        )
    }
}


import React, { Component } from 'react';
import { Button, Col, Row, List, Input, Card, Typography, Pagination, Spin, message, Tag } from 'antd'



import { VerticalAlignBottomOutlined } from '@ant-design/icons';

import './../../../Styles/mantenimiento.css';

import Ticket from './TicketMantenimiento'



//Modales
import ModalMantenimiento from './ModalMantenimiento';

import axios from 'axios';
import { Switch } from 'react-router-dom';
import moment from 'moment';

const { TextArea } = Input;

const { Title, Text } = Typography;

var meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];


export default class Mantenimiento extends Component {


    constructor(props) {
        super(props);

        this.state = {
            loading: true,

            visible: false,
            accionModal: "crear",

            dataList: [],

            page: 1,
            itemCount: 1,
            pageCount: 1,

            det_monto: "",
            det_mes: "",

            det_folio: "",
            det_concepto: "",
            det_estatus: "",
            det_arrayPendientes: []

        }
    }

    /***
     * @memberof Mantenimiento
     *
     * @function componentDidMount
     */
    componentDidMount() {
        this.props.updateNavigationInformation('Mantenimiento');

        this.listPagos(1);
    }


    /**
    * @memberof Mantenimiento

    * @method   modalPagar
    * @description Abrir modal tipo pago.
    *
    **/
    modalPagar = () => {
        this.setState({
            visible: true,
            accionModal: 'Pagar',
            //accionModal: 'Ticket',
        })
    }


    /**
   * @memberof Mantenimiento

   * @method   modalPagar
   * @description Abrir modal tipo pago.
   *
   **/
    modalTicket = async (e) => {
        var data = this.state.dataList;

        for (let index = 0; index < data.length; index++) {
            const element = data[index];

            var status = null;
            switch (element.estatus) {
                case 1:
                    status = "Pago Necesario"
                    break;

                case 2:
                    status = "Procesando Pago"
                    break;

                case 3:
                    status = "Pagado"
                    break;
                case 4:
                    status = "Error"
                    break;

                default:
                    break;
            }


            if (element._id == e) {
                this.setState({
                    det_folio: element._id,
                    det_concepto: meses[new Date(element.fecha).getMonth()],
                    det_mes: element.fecha.substring(0, 10),
                    det_estatus: status,
                    det_monto: element.monto.toMoney()
                })
                break;
            }
        }


        setTimeout(() => {
            document.getElementById('btn-ticket').click();
        }, 100);


    }



    /**
    * @memberof Mantenimiento
    *
    * @method   hideModal
    * @description  Cambia state para cerrar cualquier modal
    *
    **/
    hideModal = () => {

        this.setState({
            visible: false,
        });

        this.listPagos(1);
    }



    /**
    * @memberof Mantenimiento
    *
    * @method   listMantenimientos
    * @description Busca la informacion del usuario colono y la lista de 
    *
    **/
    listPagos = async (page) => {
        this.setState({ loading: true });
        let userId = sessionStorage.getItem('user');

        axios.get('/mantenimiento/paginado', {
            params: {
                page: page,
                userId: userId,
                extra: true
            }
        }).then(response => {

            this.setState({
                dataList: response.data.data.itemsList,
                page: response.data.data.currentPage,
                itemCount: response.data.data.itemCount,
                pageCount: response.data.data.pageCount,

                det_monto: response.data.extras.montoPendiente,
                det_mes: response.data.extras.pagosPendientes,
                det_arrayPendientes: response.data.extras.arrayPendientes,

                loading: false

            });


            if (response.data.extras.usuario.casa_id == undefined) {
                message.error('No tiene ninguna casa asignada el usuario ' + response.data.extras.usuario.nombre + " " + response.data.extras.usuario.apellidos);
            }


        }).catch(error => {
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }


    render() {

        let { page, pageCount, itemCount, det_arrayPendientes } = this.state;

        return (
            <section className="section">

                <Spin spinning={this.state.loading}>

                    <Row className="section-header">
                        <Col sm={24} md={12} lg={8} className="cnt-card-manto">
                            <Card className="card-manto">
                                <Row>
                                    <Row width={100} align="center">
                                        <Title level={1}>Monto por Pagar</Title>
                                    </Row>

                                    <Col xs={24} >
                                        <Title level={3}>$ {(this.state.det_monto).toMoney()}</Title>
                                    </Col>

                                    <Col xs={24} >
                                        <Title level={3} style={{ color: "#737588", /*textAlign: 'end'*/ }}>{this.state.det_mes}</Title>
                                        <p
                                            style={{
                                                color: 'red'
                                                // textAlign: 'end'
                                            }}
                                        >{(Array.isArray(det_arrayPendientes) && det_arrayPendientes.length > 0) ? '(' + (det_arrayPendientes.map((colono, index) => (moment(colono.fecha).format('YYYY-MMMM') + ((index !== det_arrayPendientes.length - 1) ? ' / ' : '')))) + ')' : null}</p>
                                    </Col>
                                    <Col xs={24} >
                                        <Tag
                                            style={{ float: 'right', fontSize: 16, fontWeight: 'bold', padding: '5px 20px' }}
                                            size="large"
                                            color={(Array.isArray(det_arrayPendientes) && det_arrayPendientes.length > 0) ? "yellow" : "green"}>{(Array.isArray(det_arrayPendientes) && det_arrayPendientes.length > 0) ? "Pago Pendiente" : "Pagado"}</Tag>
                                        <br />
                                    </Col>
                                    <Col span={24} style={{ marginTop: '0.5em' }}>


                                    </Col>

                                </Row>
                            </Card>
                        </Col>
                    </Row>

                    <Row className="section-header">
                        <Title level={3} className="page-sub-title" >Pagos de Mantenimiento</Title>
                    </Row>

                    <Row className="section-content mantenimiento">
                        <Col span={24}>
                            {/* <Row style={{ margin: "1em 2em", paddingBottom:"3em" }} className="title-list-manto ">
                                <Col xs={12} xl={6} xxl={6} span={5} className="center ">
                                    <Text type="secondary" className="list-title "> Folio</Text>
                                </Col>
                                <Col xs={12} xl={6} xxl={6} span={5} className="center ">
                                    <Text type="secondary" className="list-title" > Mes</Text>
                                </Col>
                                <Col xs={10} xl={5} xxl={6} span={5} className="center ">
                                    <Text type="secondary" className="list-title ">Estatus</Text>
                                </Col>
                                <Col xs={10} xl={5} xxl={5} span={4} className="center ">
                                    <Text type="secondary" className="list-title ">Fecha</Text>
                                </Col>
                                <Col xs={4} xl={2} xxl={1} span={2} className="center ">
                                    <Text type="secondary" className="list-title ">Acciones</Text>
                                </Col>
                            </Row> */}

                            <List
                                itemLayout="horizontal"
                                dataSource={this.state.dataList}
                                className=""
                                pagination={false}
                                style={{ maxHeight: "45vh", overflow: "auto", overflow: 'visible' }}
                                header={<Row className="component-list-item" style={{ width: '100%', margin: "5px 25px 10px 30px" }}>
                                    <Col xs={12} xl={6} xxl={6} span={5} style={{ color: 'rgb(123 123 123)', fontSize: 14, textAlign: 'center' }}>
                                        Número de Orden
                                    </Col>
                                    <Col xs={12} xl={6} xxl={6} span={5} style={{ color: 'rgb(123 123 123)', fontSize: 14, textAlign: 'center' }}>
                                        Mes
                                    </Col>
                                    <Col xs={10} xl={5} xxl={6} span={5} style={{ color: 'rgb(123 123 123)', fontSize: 14, textAlign: 'center' }}>
                                        Estatus
                                    </Col>
                                    <Col xs={10} xl={4} xxl={4} span={4} style={{ color: 'rgb(123 123 123)', fontSize: 14, textAlign: 'center' }}>
                                        Fecha
                                    </Col>
                                    <Col xs={4} xl={2} xxl={2} span={2} style={{ color: 'rgb(123 123 123)', fontSize: 14, textAlign: 'center' }}>
                                    </Col>
                                </Row>}
                                renderItem={({ _id, mes, estatus, fecha }, index) => {
                                    var spanEstatus = null;
                                    switch (estatus) {
                                        case 1:
                                            spanEstatus = <span className="spn-estatus warning"> Pago Necesario </span>
                                            break;

                                        case 2:
                                            spanEstatus = <span className="spn-estatus process"> Procesando Pago </span>
                                            break;

                                        case 3:
                                            spanEstatus = <span className="spn-estatus success"> Pagado </span>
                                            break;
                                        case 4:
                                            spanEstatus = <span className="spn-estatus error" > Error </span>
                                            break;

                                        default:
                                            break;


                                    }
                                    return <List.Item className="component-list-item">
                                        <Card className="card-list">
                                            <Row style={{ width: '100%' }}>
                                                <Col xs={12} xl={6} xxl={6} span={5} style={{ color: 'rgb(123 123 123)', fontSize: 14 }}>
                                                    {_id.toUpperCase()}
                                                </Col>
                                                <Col xs={12} xl={6} xxl={6} span={5}>
                                                    {meses[new Date(fecha).getMonth()]}
                                                </Col>
                                                <Col xs={10} xl={5} xxl={6} span={5} >
                                                    {spanEstatus}
                                                </Col>
                                                <Col xs={10} xl={4} xxl={4} span={4}>
                                                    {moment(fecha).format('MM/YYYY')}
                                                </Col>
                                                <Col xs={4} xl={2} xxl={2} span={2}>
                                                    {
                                                        (estatus == 3) ?
                                                            <Button className="btn btn-menu btn-download"
                                                                title="Descargar ticket"
                                                                onClick={() => this.modalTicket(_id)}>
                                                                <VerticalAlignBottomOutlined />
                                                            </Button>
                                                            :
                                                        null
                                                    }
                                                </Col>
                                            </Row>
                                        </Card>
                                    </List.Item>
                                }}
                            />
                        </Col>

                        <Col style={{ margin: "1em 2em", paddingBottom: "3em" }}>
                            <Pagination
                                current={page}
                                total={this.state.itemCount}
                                showSizeChanger={false}
                                onChange={(page) => this.listPagos(page)}
                            />
                        </Col>
                    </Row>


                </Spin>

                <ModalMantenimiento
                    visible={this.state.visible}
                    accionModal={this.state.accionModal}
                    det_monto={this.state.det_monto}
                    det_arrayPendientes={this.state.det_arrayPendientes}

                    closeMethod={this.hideModal}
                    accept={() => {
                        this.hideModal();
                    }}
                />

                <Row className="nView">
                    <Button style={{ border: "none" }} title="Reporte">
                        <Ticket
                            numConsulta={0}

                            det_folio={this.state.det_folio}
                            det_concepto={this.state.det_concepto}
                            det_mes={this.state.det_mes}
                            det_estatus={this.state.det_estatus}
                            det_monto={this.state.det_monto}

                            trigger={() =>
                                <Button
                                    type="link" size="small"
                                    style={{ position: 'relative', top: '0.4em', right: '0.5em' }}
                                >
                                    Ticket
                                </Button>}
                        />
                    </Button>

                </Row>

            </section>
        );
    }
}


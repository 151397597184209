import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Card, Badge, Button, Col, Row, Calendar, Typography, Image, message, Empty, Dropdown, Menu, Tabs, Spin, Carousel, Statistic, Tag } from 'antd'
import axios from 'axios';
//compoenentes
import {
    IconDbOrdenesEstatus, IconDbOrdenesFecha, IconDbOrdenesMantenimiento, IconDbOrdenesWork, IconDbDocumentosFolder, IconDbDocumentosFolderIcon,
    IconDbDocumentosClock, IconMenuDots, IconArrowToRight, IconPDF
} from '../Widgets/Iconos'
import Logged from '../../Hooks/Logged';

import { LeftOutlined, RightOutlined } from '@ant-design/icons';


//css
import './../../Styles/dashboard.css'

const { Title } = Typography;
const moment = require('moment');
const { SubMenu } = Menu;

const { TabPane } = Tabs;
export default class Dashboard extends Component {

    constructor(props) {
        super(props)
        this.state = {
            ordenes: [],
            eventos: [],
            documents: [],
            eventosCalendario: [],

            carousel_meses: null,
            carousel_cantidad: null,
            costos_mantenimiento: [],
            costos_mantenimiento_actual: 0

        }
    }



    /***
     *
     * @methodOf Dashboard
     * @function componentDidMount
     */
    componentDidMount() {
        this.props.updateNavigationInformation('Dashboard');
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.listOrdenes();
        this.listEventos();
        this.listDocuments();
        this.getCostosMantenimiento()

        this.setState({
            carousel_meses: this.carousel_meses,
            carousel_cantidad: this.carousel_cantidad
        });
    }

    /***
    *
    * @methodOf Dashboard
    * @function componentDidMount
    */
    componentDidUopdate() {
    }


    /**
    * @memberof Dashboard
    *
    * @method   listOrdenes
    * @description  Cambia state para cerrar cualquier modal
    *
    **/
    listOrdenes = () => {
        this.setState({ loading: true })
        axios.get('/ordenes_trabajo/list', {
            params: {
                page: 1,
                limit: 3,
            }
        }).then(response => {
            this.setState({
                ordenes: response.data.data.itemsList,
            })
        }).catch(error => {
            console.log(error)
            message.error('Error al cargar las ordenes')
        }).finally(() => { this.setState({ loading: false }) })
    }

    /**
    * @memberof Dashboard
    *
    * @method   listEventos
    * @description  Muestra los ultimos 3 eventos proximos hechos por el usuario
    *
    **/
    listEventos = (mes, año) => {
        this.setState({ loading_2: true })
        axios.get('/eventos/list', {
            params: {
                limit: 30,
                mes_seleccionado: mes !== undefined ? mes : moment().month(),
                año_seleccionado: año !== undefined ? año : moment().year()
            }
        }).then(response => {
            this.setState({
                eventos: response.data.data.eventos.itemsList,
                eventosCalendario: this.getDatesFormat(response.data.data.eventosMensuales)
            })
        }).catch(error => {
            console.log(error)
            message.error('Error al cargar los eventos')
        }).finally(() => { this.setState({ loading_2: false }) })
    }


    /**
   * @memberof Dashboard
   *
   * @method   changeDate
   * @description  Al cambiar la fecha seleccionada en el calendario, manda una peticion para traer los eventos
   * que conicidan con el mes y año seleecionado
   * @param e {Date} : objeto Date que manda el Calendario de antd, al cambiar de fecha seleccionada
   *
   **/
    changeDate = (e) => {
        this.listEventos(moment(e).month(), moment(e).year())
    }

    /**
    * @memberof Dashboard
    *
    * @method   listDocuments
    * @description  Si es usuario Admin Muestra los ultimos 3 folder modificados y si es Colono muestra sus ultimos 3 archivos
    *
    **/
    listDocuments = () => {
        let user = this.context
        let url = '/carpetas/get'
        if (user.tipo === 1) url = '/carpetas/list'
        this.setState({ loading_3: true })
        axios.get(url, {
            params: {
                limit: 3,
                casa_id: user.casa_id?._id,
            }
        }).then(response => {
            let data = {};
            if (url === "/carpetas/list") data = response.data.data?.itemsList
            else data = response.data.data?.archivos
            this.setState({
                documents: data ? data : [],
                url: url,
            })
        }).catch(error => {
            console.log(error)
            message.error('Error al cargar los eventos')
        }).finally(() => { this.setState({ loading_3: false }) })
    }

    /**
    * @memberof Dashboard
    *
    * @method   spanEstatus
    * @description retorna el span dependiendod el estatus
    *
    **/
    spanEstatus = (estatus) => {
        switch (estatus) {
            case 1:
                return <span title="Estatus" className="spn-estatus warning"> En Revisión  </span>
                break;
            case 2:
                return <span title="Estatus" className="spn-estatus process"> En Proceso </span>
                break;
            case 3:
                return <span title="Estatus" className="spn-estatus success"> Terminada </span>
                break;
            case 4:
                return <span title="Estatus" className="spn-estatus error"> Denegada </span>
                break;
            case 5:
                return <span title="Estatus" className="spn-estatus signature"> Por Firmar </span>
                break;
        }
    }

    /**
    * @memberof Dashboard
    *
    * @method   badgeEstatus
    * @description retorna el tipo de Badge correspondiente al estatus del evento
    **/
    badgeEstatus = (estatus) => {
        if (estatus === 1)
            return <Badge status="warning" text="En revision" />
        if (estatus === 2)
            return <Badge status="success" text="Aprobado y Agendado" />
        if (estatus === 3)
            return <Badge status="error" text="Denegado" />
    }

    /**
    * @memberof Dashboard
    *
    * @method   renderCards
    * @description renderiza las cards de los eventos a mostar
    * @param eventos [{*}] : Array de Objetos con la infomacion de los eventos
    * @param user {*} : Objeto user que se manda desde el this.context 
    * 
    **/
    renderCards = (eventos, user) => {
        if (eventos.length === 0) {
            return <Col span={24} className="center">
                <Empty description="No tienes ningún evento creado " />
            </Col>
        } else {
            return eventos.map((eve, index) => {
                if (index <= 2) return <Card className={`card-evento ${eve.estatus === 1 ? 'warning' : eve.estatus === 2 ? 'success' : 'error'}`}>
                    <Row>
                        <Col span={22}>
                            <Title level={2}>{eve.nombre}</Title>
                            <p><IconDbDocumentosClock /> {`${moment(eve.fecha_inicio).format('l LT')} -  ${moment(eve.fecha_final).format('l LT')}`}</p>
                            <p> {this.badgeEstatus(eve.estatus)} </p>
                        </Col>
                        <Col span={2} className="center ">
                            <Dropdown
                                trigger={['click']}
                                placement="bottomLeft"
                                overlay={
                                    <Menu
                                        onClick={(item) => { this.onClickUpdate(item, eve._id) }}
                                    >
                                    </Menu>
                                }
                            >
                                <Button className="btn-opcion-menuDot right">
                                    <IconMenuDots />
                                </Button>
                            </Dropdown>
                        </Col>
                    </Row>
                </Card>
            })

        }
    }

    /**
    * @memberof Dashboard
    *
    * @method   renderDocuments
    * @description renderiza las cards de los eventos a mostar
    * @param eventos [{*}] : Array de Objetos con la infomacion de los eventos
    * @param user {*} : Objeto user que se manda desde el this.context 
    * 
    **/
    renderDocuments = (Documents, user) => {
        if (Documents.length === 0) {
            return <Col span={24} className="center">
                <Empty description="No tienes ningún archivo o carpeta creada " />
            </Col>
        } else if (this.state.url === '/carpetas/list') {
            return Documents.map((Doc) => {
                return <Col xs={24} xl={8}>
                    <Link to={'/colono/archivos/' + Doc.casa_id}>
                        <Card className="card-docto"  >
                            <div>
                                <Button className="btn-opcion-folder">
                                    <IconDbDocumentosFolder />
                                </Button>
                                <Button className="btn-dots right">
                                    <IconMenuDots />
                                </Button>
                            </div>
                            <Title level={2}> {Doc.nombre} </Title>
                            <p><IconDbDocumentosFolderIcon />{Doc.archivos.length} Archivos</p>
                            <p><IconDbDocumentosClock /> Última actualización: {moment(Doc.updatedAt).format('l LT')}</p>
                        </Card>
                    </Link>
                </Col>
            })

        } else {
            return Documents.map((Doc) => {
                return <Col xs={24} xl={8}>
                    <Card className="card-docto"  >
                        <div>
                            <Button className="btn-opcion-folder">
                                <IconPDF />
                            </Button>
                        </div>
                        <Title level={2}> {Doc.nombre} </Title>
                        <p><IconDbDocumentosClock /> Creado en: {moment(Doc.created_At).format('l LT')}</p>
                    </Card>
                </Col>
            })
        }
    }


    /**
    *
    * @memberof Calendario
    * @description Establecemos el formato de fecha para que el metodo de dateCellRender lo pueda leer,
    *  retorna un objeto con campos fechas, y dentro de estos un array con los eventos que hay es esa fecha
    * @param fechas [{*}] : Array de Objetos con la informacion de cada Evento del mes
    *
    * @return fechasCalendar { 
    *      fecha_1: [{evento_1},...],
    *      fecha_2: [{evento_n}]  
    *  }
    */
    getDatesFormat = (fechas) => {
        let fechasCalendar = {};
        if (fechas) {
            for (const fecha of fechas) {
                let fechaIndex = moment(fecha.fecha_inicio).format('YYYY-MM-DD');
                if (!fechasCalendar[fechaIndex]) {
                    fechasCalendar[fechaIndex] = []
                }
                fechasCalendar[fechaIndex].push(fecha)
            }
        }
        return fechasCalendar;

    }


    /**
     *
     * @memberof Calendario
     * @description Renderiza los badges dentro del las celdas del calendario, 
     * este metodo se llama automaticamente por cada dia del mes por el componente de antd 
     * 
     */
    dateCellRender = (value) => {
        let cellDayValue = value.format('YYYY-MM-DD');
        if (this.state.eventosCalendario[cellDayValue]) {
            return <Badge className="badge-calendario" status='success' />
        }
    }


    /**
     *
     *
     * @param {*} estatus
     * @memberof Dashboard
     * 
     * @description Obtenemos el objeto de fechas agrupado por mantenimiento
     */
    getCostosMantenimiento = () => {
        axios.get('/mantenimiento/dashboard')
            .then(response => {

                console.log(response)

                let month = moment().month() + 1
                let year = moment().year()
                let costos_mantenimiento = []

                //Creamos los meses para el mantenimiento
                for (let index = month; index != 0; index--) {
                    let monto = 0
                    let estatus = 0

                    if (response.data.data[year + '-' + index]) {
                        monto = response.data.data[year + '-' + index].monto
                        estatus = response.data.data[year + '-' + index].estatus
                    }

                    const date = moment(year + "-" + index, 'YYYY-MM').format("MM YYYY");
                    const start = moment('2021-09', 'YYYY-MM').format("MM YYYY");

                    if (date > start) {
                        var _mes = moment(index, "M").format("MMMM");
                        var _fecha = moment(year + "/" + index, "YYYY/M").format("MMMM YYYY");

                        costos_mantenimiento.push({
                            mes: _mes, //moment(index, "M").format("MMMM"),
                            fecha: _fecha, //moment(year + "/" + index, "YYYY/M").format("MMMM YYYY "),
                            monto,
                            estatus
                        })
                    }
                }


                //Los declaramos
                this.setState({
                    costos_mantenimiento,
                    costos_mantenimiento_actual: costos_mantenimiento[0]
                })
            })
            .catch(error => {
                console.log('error', error)
            })
            .finally(() => this.setState({ loading_3: false }))
    }


    /**
     *
     *
     * @param {*} estatus
     * @memberof Dashboard
     * 
     * @description Generamos el tag para mantenimiento
     */
    renderTag = (estatus) => {
        switch (estatus) {
            case 1:
                return <Tag className="estatus-calendar" color="red">Pago Necesario</Tag>
            // status = ""
            // break;
            case 2:
                return <Tag className="estatus-calendar" color="yellow">Procesando Pago</Tag>
            // status = "Procesando Pago"
            // break;
            case 3:
                return <Tag className="estatus-calendar" color="green">Pagado</Tag>
            // status = "Pagado"
            // break;
            case 4:
                return <Tag className="estatus-calendar" color="red">Error</Tag>
            // status = "Error"
            // break;
            default:
                break;
        }
    }




    capitalizarPrimeraLetra(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }



    render() {
        let user = this.context
        const { loading, loading_2, loading_3, costos_mantenimiento, costos_mantenimiento_actual } = this.state

        return (
            <section className="dashboard">
                <Spin spinning={loading && loading_2 && loading_3}>
                    <Row>
                        <Col xs={24} xl={16}>
                            <Row style={{ width: "100%" }} >
                                <Col span={24} style={{ padding: "10px" }}>
                                    <Card className="card-bienvenido" style={{ backgroundImage: 'url("/images/bgBienvenido.png")' }} >
                                        <Row>
                                            <Col xs={24} lg={12} xl={12} style={{ float: "left" }}>
                                                <Title level={1} className="main-dashboard-title">Bienvenido {user.nombre}</Title>
                                                <p>Gracias por usar el Portal de Parque Esmeralda</p>
                                            </Col>

                                            <Col xs={24} lg={14} xl={12} style={{ padding: "10px 10px 0px 0px", float: "right" }}>
                                                <Image className="imgBienvenido" width="90%" src={"/images/img_bienvenido.svg"} alt="imgBienvenido" preview={false} />
                                            </Col>
                                        </Row>


                                    </Card>
                                </Col>
                            </Row>
                            <Row style={{ width: "100%" }}  >
                                <Col span={24} className="col-ver">
                                    <Title level={1} >Ordenes de Trabajo</Title>
                                    <Link to={"/colono/ordenesTrabajo"}>
                                        <Button className="btn-DbVerMas right">
                                            Ver todo
                                            <IconArrowToRight />
                                        </Button>
                                    </Link>
                                </Col>
                                <Row className="cnt-cardsTrabajo">
                                    {
                                        this.state.ordenes.length > 0 ?
                                            this.state.ordenes.map(prop => {
                                                const { concepto, estatus, createdAt, categoria } = prop
                                                var claseCard = "";
                                                switch (estatus) {
                                                    case 1:
                                                        claseCard = "card-warning"
                                                        break;
                                                    case 2:
                                                        claseCard = "card-process"
                                                        break;
                                                    case 3:
                                                        claseCard = "card-success"
                                                        break;
                                                    case 4:
                                                        claseCard = "card-error"
                                                        break;
                                                    case 5:
                                                        claseCard = "card-signature"
                                                        break;
                                                }


                                                return <Col xs={24} xl={8}>
                                                    <Card className={"card-orden " + claseCard} >
                                                        <div>
                                                            <Button className="btn-opcion-orden">
                                                                <IconDbOrdenesMantenimiento />
                                                            </Button>

                                                            <Button className="btn-opcion-menuDot right">
                                                                <IconMenuDots />
                                                            </Button>
                                                        </div>

                                                        <Title level={2}> {concepto} </Title>
                                                        <p><IconDbOrdenesEstatus />{this.spanEstatus(estatus)}</p>
                                                        <p><IconDbOrdenesWork /> {categoria?.nombre}</p>
                                                        <p><IconDbOrdenesFecha /> {moment(createdAt).format('DD-MM-YYYY')}</p>
                                                    </Card>
                                                </Col>
                                            })
                                            : <Col span={24} className="center">
                                                <Empty description="No hay ningúna orden de Trabajo" />
                                            </Col>
                                    }

                                </Row>

                            </Row>
                            <Row style={{ width: "100%" }} >
                                <Col span={24} className="col-ver">
                                    <Title level={1} >Documentos de Mi Casa</Title>
                                    {/* Se borrro porque se rompe +user.casa._id */}
                                    <Link to={`${user.tipo === 1 ? '/admin/documentos' : '/colono/archivos/' + user.casa_id?._id}`}>
                                        <Button className="btn-DbVerMas right">
                                            Ver todo
                                            <IconArrowToRight />
                                        </Button>
                                    </Link>
                                </Col>

                                <Row className="cnt-cardsDocumentos">
                                    {this.renderDocuments(this.state.documents, user)}
                                </Row>

                            </Row>
                        </Col>
                        <Col xs={24} xl={8} className="pd-10">

                            {(costos_mantenimiento.length > 0) ?
                                <Col className="cardCalendario">
                                    <Title level={1} className="cardTitle">Mantenimiento

                                        <Link to={"/colono/mantenimiento"} style={{ position: 'absolute', right: '10px', fontSize: '12px', paddingTop: '3px' }}>Ver todos <RightOutlined /></Link>
                                    </Title>
                                    <Col>

                                        {(costos_mantenimiento.length === 1) ?
                                            <div className="site-calendar-demo-card">
                                                {costos_mantenimiento.map(e =>
                                                    <div className="carousel-mes-seleccion">
                                                        <span className="span-mes-select">{this.capitalizarPrimeraLetra(e.mes)}</span>
                                                    </div>)}
                                                <Row style={{ width: '100%', marginTop: '1em' }}>

                                                </Row>
                                                <Row style={{ width: '100%', marginTop: '1em' }}>
                                                    <Col span={12} style={{ paddingLeft: '20px' }}>
                                                        <Statistic className="monto-pagar"
                                                            value={costos_mantenimiento_actual?.monto}
                                                            precision={2} title="Monto a Pagar" suffix="MXN" ></Statistic>
                                                    </Col>
                                                    <Col span={12} style={{ textAlign: "center", marginTop: "0.5em" }}>
                                                        {this.renderTag(costos_mantenimiento_actual?.estatus)}
                                                    </Col>
                                                </Row>
                                            </div>

                                            :
                                            <div className="site-calendar-demo-card">
                                                <Carousel
                                                    asNavFor={this.state.carousel_cantidad}
                                                    ref={slider => (this.carousel_meses = slider)}
                                                    slidesToShow={3}
                                                    centerMode={true}
                                                    swipeToSlide={true}
                                                    focusOnSelect={true}
                                                    dots={false}
                                                    className="carousel-mes"

                                                >
                                                    {costos_mantenimiento.map(e => <div className="carousel-mes-seleccion">
                                                        <span>{this.capitalizarPrimeraLetra(e.mes)}</span>
                                                    </div>)}
                                                </Carousel>
                                                <Row style={{ width: '100%', marginTop: '1em' }}>
                                                    <Col span={1}>
                                                        <Button type="link" size="small" icon={<LeftOutlined />} onClick={() => this.state.carousel_cantidad.prev()} />
                                                    </Col>
                                                    <Col span={22}>
                                                        <Carousel
                                                            asNavFor={this.state.carousel_meses}
                                                            ref={slider => (this.carousel_cantidad = slider)}
                                                            dots={false}
                                                            className="carousel-fecha"
                                                            afterChange={value => this.setState(state => {
                                                                state.costos_mantenimiento_actual = state.costos_mantenimiento[value]
                                                                return state
                                                            })}
                                                        >
                                                            {costos_mantenimiento.map(e => <div className="carousel-fecha-item">{e.fecha}</div>)}
                                                        </Carousel>
                                                    </Col>
                                                    <Col span={1}>
                                                        <Button type="link" size="small" icon={<RightOutlined />} onClick={() => this.state.carousel_cantidad.next()} />
                                                    </Col>
                                                </Row>
                                                <Row style={{ width: '100%', marginTop: '1em' }}>
                                                    <Col span={12} style={{ paddingLeft: '20px' }}>
                                                        <Statistic className="monto-pagar" value={costos_mantenimiento_actual?.monto} precision={2} title="Monto a Pagar" suffix="MXN" ></Statistic>
                                                    </Col>
                                                    <Col span={12} style={{ textAlign: "center", marginTop: "0.5em" }}>
                                                        {this.renderTag(costos_mantenimiento_actual?.estatus)}
                                                    </Col>
                                                </Row>

                                            </div>
                                        }
                                    </Col>
                                </Col>
                                : null
                            }


                            <Col className="cardCalendario">
                                <Title level={1} className="cardTitle">Calendario</Title>
                                <Col>
                                    <div className="site-calendar-demo-card">
                                        <Calendar
                                            fullscreen={false}
                                            dateCellRender={this.dateCellRender}
                                            onPanelChange={(value) => {
                                                this.setState({
                                                    mes_seleccionado: moment(value).month(),
                                                    año_seleccionado: moment(value).year(),
                                                })
                                                this.changeDate(value)
                                            }}
                                        />
                                    </div>
                                </Col>
                            </Col>
                            <Col>
                                <Col span={24} className="col-ver">
                                    <Title level={1} >Mis Eventos</Title>
                                    <Link to={"/colono/salonUsosMultiples"}>
                                        <Button className="btn-DbVerMas right">
                                            Ver todo
                                            <IconArrowToRight />
                                        </Button>
                                    </Link>
                                </Col>
                                {this.renderCards(this.state.eventos, user)}
                            </Col>
                        </Col>
                    </Row>
                </Spin>
            </section>
        )
    }
}

Dashboard.contextType = Logged;
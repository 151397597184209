import React, { Component } from 'react';
import { Button, Col, Row, List, Input, Typography, Badge, message, Dropdown, Menu, Card, Modal } from 'antd'
import axios from 'axios';
import { FaEnvelope } from 'react-icons/fa'
//componentes
import { IconPlus, IconMenu, IconMenuDots, IconCloseModal, IconArrowSelect } from '../../Widgets/Iconos'
import Logged from '../../../Hooks/Logged';
//Modales
import ModalOrdenTrabajo from './ModalOrdenTrabajo';
import DrawerChatOrdenTrabajo from './DrawerChatOrdenTrabajo';
import ModalFirmaOrden from './ModalFirmaOrden';
//css
import './../../../Styles/ordenesTrabajo.css';

const { TextArea } = Input;
const { SubMenu } = Menu;
const { Title, Text } = Typography;
const moment = require('moment');

var userAdmin = false;

export default class OrdenesTrabajo extends Component {
    static contextType = Logged;
    constructor(props) {
        super(props)
        this.state = {
            ordenes: [],
            loading: false,
            orden_id: undefined,

            /*Paginador*/
            page: 1,
            limit: 10,
            pageCount: 0,
            itemCount: 1,
            chatVisible: false,
            modalFirmaVisible: false,
            colonoInformation: {},
            verFirma: false,
            ModalVisible: false,
        }
    }



    /***
     * @memberof OrdenesTrabajo
     *
     * @function componentDidMount
     */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.props.updateNavigationInformation('Ordenes de Trabajo');
        this.listOrdenes(1)
    }


    /**
    * @memberof OrdenesTrabajo
    * @method   modalCrear
    * @description Abrir modal tipo creación para nuevo registro.
    *
    **/
    modalCrear = () => {
        this.setState({
            visible: true,
            accionModal: 'Crear',

        })
    }


    /**
    * @memberof OrdenesTrabajo
    *
    * @method   hideModal
    * @description  Cambia state para cerrar cualquier modal
    *
    **/
    hideModal = () => {
        this.setState({
            visible: false,
            chatVisible: false,
            orden_id: undefined,
            chatOrderId: undefined,
            modalFirmaVisible: false,
            orden_id_to_sgn: undefined,
            colonoInformation: {},
            verFirma: false,
        });
        this.listOrdenes(1)
    }

    /**
    * @memberof OrdenesTrabajo
    *
    * @method   listOrdenes
    * @description  Cambia state para cerrar cualquier modal
    *
    **/
    listOrdenes = (page) => {
        this.setState({ loading: true })
        axios.get('/ordenes_trabajo/list', {
            params: {
                page: page,
                limit: 10,
            }
        }).then(response => {
            this.setState({
                ordenes: response.data.data.itemsList,
                pageCount: response.data.data.pageCount,
                itemCount: response.data.data.itemCount,
            })
        }).catch(error => {
            console.log(error)
            message.error('Error al cargar las ordenes')
        }).finally(() => { this.setState({ loading: false }) })
    }

    /**
    * @memberof OrdenesTrabajo
    *
    * @method   spanEstatus
    * @description retorna el span dependiendod el estatus
    *
    **/
    spanEstatus = (estatus) => {
        switch (estatus) {
            case 1:
                return <span className="spn-estatus warning"> En Revisión  </span>
                break;
            case 2:
                return <span className="spn-estatus process"> En Proceso </span>
                break;
            case 3:
                return <span className="spn-estatus success"> Terminada </span>
                break;
            case 4:
                return <span className="spn-estatus error"> Denegada </span>
                break;
            case 5:
                return <span className="spn-estatus signature"> Por Firmar </span>
                break;
        }
    }

    /**
    * @memberof OrdenesTrabajo
    *
    * @method   onClick
    * @description actualiza el estatus de una orden
    *
    **/
    onClick = (item, _id) => {
        if (parseFloat(item.key) <= 5) {
            this.setState({ loading: true })
            axios.post('/ordenes_trabajo/update', {
                _id: _id,
                estatus: parseFloat(item.key),
            }).then(response => {
                message.success('Orden Actualizada')
                this.listOrdenes(1)
            }).catch(error => {
                message.error('No se Actualizo la orden')
                this.setState({ loading: false })
            })
        }
    }


    render() {
        let user = this.context

        return (
            <section className="section">
                <Row className="section-header">
                    <Col span={20}>
                        <Title level={3} className="page-sub-title" >Mis Ordenes de Trabajo</Title>
                    </Col>
                    <Col span={4}>
                        <Button className="btn-plus right" title="Agregar" onClick={this.modalCrear}>
                            <IconPlus />
                        </Button>
                    </Col>
                </Row>
                <Row className="row-list section-content">
                    <Col span={24}>
                        <List
                            itemLayout="horizontal"
                            dataSource={this.state.ordenes}
                            loading={this.state.loading}
                            renderItem={({ descripcion, mensajes, concepto, estatus, createdAt, _id, casa, colono, firma_file }, index) => (<div style={{ position:'relative' }}>
                                
                                {(mensajes > 0) ? <Badge size={25} count={mensajes}  className="badge-messages">
                                    <FaEnvelope size={20} />
                                </Badge> : null}

                                <List.Item className="component-list-item">

                                    <Card className="card-list" style={{ position: 'relative' }}>

                                        <Row style={{ width: '100%' }} className="">
                                            <Col xs={12} md={4} className="center">
                                                <Text>{concepto}</Text>
                                            </Col>
                                            <Col xs={6} md={0} className="center">
                                                <Text>{descripcion}</Text>
                                            </Col>
                                            <Col xs={3} md={0} className="center">
                                                <Text>{(casa?.nombre != undefined) ? casa.nombre : <strong style={{ fontWeight: "bold" }}>N/A</strong>}</Text>
                                            </Col>
                                            <Col xs={11} md={5} className="center" >
                                                {this.spanEstatus(estatus)}
                                            </Col>
                                            <Col xs={4} md={0} className="center">
                                                <Text>{moment(createdAt).format('DD-MM-YYYY')}</Text>
                                            </Col>
                                            <Col xs={1} md={2} className="center">
                                                <Dropdown
                                                    trigger={['click']}
                                                    placement="bottomLeft"
                                                    overlay={
                                                        <Menu
                                                            onClick={(item) => { this.onClick(item, _id) }}
                                                        >
                                                            <Menu.Item onClick={() => this.setState({ visible: true, accionModal: 'Ver', orden_id: _id })}> Ver </Menu.Item>
                                                            <Menu.Item onClick={() => this.setState({
                                                                chatVisible: true,
                                                                chatOrderId: _id,
                                                                colonoInformation: {
                                                                    colono: colono,
                                                                    casa: casa,
                                                                }
                                                            })}> Enviar Mensaje </Menu.Item>
                                                            {user.tipo === 2 && estatus === 5 ? <Menu.Item onClick={() => this.setState({
                                                                modalFirmaVisible: true,
                                                                orden_id_to_sgn: _id,
                                                            })}> Firmar </Menu.Item> : null}
                                                            {estatus === 3 ? <Menu.Item onClick={() => this.setState({
                                                                ModalVisible: true,
                                                                urlImage: `${axios.defaults.baseURL}/upload/${firma_file}`
                                                            })}> Ver Firma </Menu.Item> : null}
                                                            {user.tipo === 1 ? <SubMenu key="sub1" title="Actualizar">
                                                                <Menu.Item key={1}>En Revision</Menu.Item>
                                                                <Menu.Item key={2}>En Proceso</Menu.Item>
                                                                <Menu.Item key={5}>Por Firmar</Menu.Item>
                                                                <Menu.Item key={3}>Terminada</Menu.Item>
                                                                <Menu.Item key={4}>Denegada</Menu.Item>
                                                            </SubMenu> : null}
                                                        </Menu>
                                                    }
                                                >
                                                    <Button className="btn-dots" onClick={e => e.stopPropagation()}>
                                                        <IconMenuDots />
                                                    </Button>
                                                </Dropdown>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            </div>
                            )}
                        />
                    </Col>
                </Row>

                {userAdmin = (user.tipo === 1)}
                <ModalOrdenTrabajo
                    tipo={user.tipo}
                    userAdmin={userAdmin}
                    visible={this.state.visible}
                    accionModal={this.state.accionModal}
                    orden_id={this.state.orden_id}
                    closeMethod={this.hideModal}
                    accept={() => {
                        this.hideModal();
                    }}
                />
                <DrawerChatOrdenTrabajo
                    id={this.state.chatOrderId}
                    colonoInformation={this.state.colonoInformation}
                    visible={this.state.chatVisible}
                    onClose={this.hideModal}
                    user={user}
                />
                <ModalFirmaOrden
                    visible={this.state.modalFirmaVisible}
                    closeMethod={this.hideModal}
                    orden_id={this.state.orden_id_to_sgn}
                />
                <Modal
                    visible={this.state.ModalVisible}
                    onCancel={() => this.setState({ ModalVisible: false })}
                    title={null}
                    footer={null}
                    className="modal-form preview-image"
                    closable={false}
                >
                    <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={() => this.setState({ ModalVisible: false })}>
                        <IconCloseModal />
                    </Button>
                    <Title level={3} className="modal-title">Firma Orden de Trabajo</Title>
                    <Row align="center">
                        <Col span={22} className="modal-canvas-signature">
                            <img
                                style={{
                                    // marginTop: '2.5em',
                                    width: '100%',
                                    borderRadius: '5px'
                                }}
                                src={this.state.urlImage}
                                alt="imagen del producto"
                            />
                        </Col>
                    </Row>
                </Modal>

            </section>
        );
    }
}

OrdenesTrabajo.contextType = Logged;


import React, { Component, useState, useEffect, useRef } from "react";
import { Row, Col, Drawer, Typography, Upload, Input, Select, message, Progress, Spin, Comment, Avatar, Tooltip, Card, Button } from 'antd';
//componentes
import { IconSend, IconImage, IconAttach, } from '../../Widgets/Iconos';
import TextArea from "antd/lib/input/TextArea";

//Context
import Logged from '../../../Hooks/Logged';
import { SocketContext } from '../../../Hooks/Socket';

import SocketIOFileClient from 'socketio-file-upload';
import ifIsImage from 'if-is-image';

//css

import { CloseOutlined } from '@ant-design/icons'
import { FaFilePdf, FaImage, FaFileExcel, FaFilePowerpoint, FaFileAlt, FaTimes } from 'react-icons/fa'


import './../../../Styles/mensajes.css';
import './../../../Styles/drawer-chat.css'
import { logDOM } from "@testing-library/dom";

const axios = require('axios');
const moment = require('moment');

const { Title } = Typography;
const { Option } = Select;



function renameFile(originalFile, newName) {
    return new File([originalFile], newName, {
        type: originalFile.type,
        lastModified: originalFile.lastModified,
    });
}


/**
 *
 *
 * @export
 * @class ModalOrdenTrabajo
 * @extends {Component}
 * @description Modal para agregar una obra en InmuebleAdmin
 */
class ChatOrdenTrabajo extends Component {

    constructor(props) {
        super(props)

        this.state = {
            mensajes: {
                list: [],
                page: 1,
                limit: 21
            },

            loading: false,
            filesArray: [],
            filesDictionary: {},
            update: false
        }
    }

    loading = false

    scrollToBottomOption = true

    componentDidMount() {
        this.props.socket.removeListener('successful');
        this.props.socket.on('successful', this.IO_start)
        this.props.socket.emit("start", sessionStorage.getItem("token"))


        this.uploader = new SocketIOFileClient(this.props.socket);
        this.uploader.addEventListener("start", this.startUpload);

        this.uploader.addEventListener("progress", this.progressUpload);

        this.uploader.addEventListener("complete", this.completeUpload);
    }


    /**
     *
     * @memberof ChatOrdenTrabajo
     * 
     * @function IO_start Iniciamos el chat para hablar con el colono
     * @description 
     *  
     */
    IO_start = () => {
        //console.log('IO_start');
        const { page, search, limit } = this.state.mensajes;
        this.props.socket.removeListener('get_messages',);
        this.props.socket.removeListener('mensaje_nuevo');
        this.props.socket.on('get_messages', this.IO_get_messages)
        this.props.socket.on('mensaje_nuevo', this.IO_mensaje_nuevo)
        this.props.socket.emit('get_messages', { page, search, limit, order_id: this.props.id })
    }

    IO_get_messages = ({ conectado, mensajes }) => this.setState(state => {
        let messagesList = []
        let content = []
        let tempOwner = null

        for (let index = 0; index < mensajes.list.length; index++) {
            let mensaje = mensajes.list[index];
            let { owner } = mensaje;

            if (tempOwner == null) {
                tempOwner = owner
                mensaje.primero = true
            }

            if (tempOwner != owner) {
                tempOwner = owner
                content = content.reverse()
                messagesList.push([...content])
                content = [mensaje]
            } else
                content.push(mensaje)

            if ((mensajes.list.length - 1) == index && tempOwner == owner) {
                content = content.reverse()
                messagesList.push([...content])
            }
        }

        state.mensajes.list = messagesList.reverse();
        state.mensajes.page = state.mensajes.page;
        state.mensajes.limit = state.mensajes.limit;
        setTimeout(this.scrollToBottom, 250)
        return state;
    })

    progressUpload = (event) => {
        let index = this.state.filesDictionary[event.file.name];
        console.log('event.bytesLoaded', event.bytesLoaded);
        console.log('event.file.size', event.file.size);
        this.state.filesArray[index - 1].progress = (parseInt(event.bytesLoaded) * 100) / parseInt(event.file.size);
        this.state.filesArray[index - 1].status = "progress";
        this.setState({ update: !this.state.update })
    }



    completeUpload = (event) => {
        console.log('complete', event);
        let index = this.state.filesDictionary[event.file.name];
        this.state.filesArray[index - 1].progress = 100;
        this.state.filesArray[index - 1].status = "complete";
        this.state.filesArray[index - 1].fileSend = event.file;
        this.setState({ update: !this.state.update })
    }

    startUpload = (event) => {
        console.log('start', event);

        let name = "";
        let fileSplit = event.file.name.split("_");
        for (let index = 0; index < (fileSplit.length - 1); index++) {
            name += ((index == 0) ? "" : '_') + fileSplit[index];
        }
        let extension = fileSplit[fileSplit.length - 1].split('.')[1];


        let index = this.state.filesArray.push({
            file: event.file,
            progress: 0,
            status: 'start',
            name,
            extension
        });

        this.state.filesDictionary[event.file.name] = index;
        this.setState({ update: !this.state.update })
    }

    uploadFile = ({ file }) => {
        let fileSplit = file.name.split(".");
        let rename = "";
        for (let index = 0; index < (fileSplit.length - 1); index++)
            rename += fileSplit[index]
        let fileName = rename + "_" + Math.random().toString(36).substring(2, 10) + "." + fileSplit[fileSplit.length - 1];
        this.uploader.submitFiles([renameFile(file, fileName)])
    }





    scrollToBottom = () => {
        const messages_body = document.getElementById("order-messages-body");
        if (messages_body !== null && messages_body !== undefined && messages_body)
            messages_body.scrollTop = messages_body.scrollHeight;
    }


    /**
     *
     * @methodOf Mensajes
     * @function IO_mensaje_nuevo
     * 
     * @description Cuando llegue un mensaje nuevo, se agrega a la lista de mensajes
     */
    IO_mensaje_nuevo = ({ mensaje, user_id }) => {
        const { page, search, limit } = this.state.mensajes;
        this.props.socket.emit('get_messages', { page, search, limit, order_id: this.props.id })
    }

 


    /**
     *
     * @methodOf Mensajes
     * @function onKeyDownPress
     * 
     * @description Se ejecuta al dar enter, enviamos el mensaje y limpiamos el input
     * 
     * */
    onPress = (event) => {

        console.log('event', event);
        // return;

        if (((event.target.value && event.target.value !== null && event.target.value !== "") || this.state.filesArray.length > 0)) {
            this.props.socket.emit('new_message', {
                message: event.target.value,
                files: this.state.filesArray.map(event => event.fileSend.name),
                order_id: this.props.id
            })
            this.setState({ filesArray: [], filesDictionary: {} })
            this.inputReference.setState({ value: '' })
        }

    }


    onKeyDownPress = (event) => {
        console.log('event.key', event.key);
        if (event.key === 'Enter' && ((event.target.value && event.target.value != null && event.target.value != "") || this.state.filesArray.length > 0)) {

            // console.log('event',event);
            // return;
            this.props.socket.emit('new_message', {
                message: event.target.value,
                user_id: this.state.user_selected,
                order_id: this.props.id,
                files: this.state.filesArray.map(event => event.fileSend.name)

            })
            this.setState({ filesArray: [], filesDictionary: {} })
            this.inputReference.setState({ value: '' })
        }

    }
    inputReference = React.createRef()

    render() {

        let { visible = false, onClose = () => { }, user } = this.props;


        console.log('this.props', this.props.colonoInformation);

        return <section style={{ height: '100%' }}>
            <Row style={{ height: '100%' }}>
                <Col span={24} style={{ height: '100%' }}>
                    <Row className="cnt-Mensajes">
                        <Col span={24}>
                            <Row className="cnt-Mensajes-header">
                                <Col xs={4} lg={4} xl={4} xxl={4}>
                                    <Avatar
                                        src={"/images/portadaPE.jpg"}
                                        size="large"
                                    />
                                </Col>
                                <Col xs={17} lg={20} xl={19} xxl={20}>
                                    <Title level={2}>
                                        {(this.props.colonoInformation && this.props.colonoInformation.colono)?this.props.colonoInformation.colono.nombre: 'Colono'}                                
                                        <small style={{ position: 'absolute', left: 10, bottom: 0, fontWeight: '400'}}>{(this.props.colonoInformation && this.props.colonoInformation.casa)?this.props.colonoInformation.casa.nombre: 'Casa'}</small>
                                    </Title>
                                </Col>
                                <Col xs={1} lg={20} xl={19} xxl={20}>
                                    <Button type="danger" shape="circle" icon={<CloseOutlined /> } onClick={this.props.onClose} style={{ position: "relative", top: 10 }}></Button>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24}>
                            <div className="cnt-Mensajes-body" id="order-messages-body">
                                <Spin spinning={this.state.loading}>
                                    {this.state.mensajes.list.map((e, indexMessagesGroup) => {
                                        return <Row className="card-msj" key={'indexMessagesGroup-' + indexMessagesGroup}>
                                            <Card className="cnt-msj">
                                                <Row>
                                                    {e.map((comment, indexCommnet) => {
                                                        const { owner, remitente_id, mensaje, fecha, primero, archivos } = comment;

                                                        if (remitente_id != undefined && remitente_id.nombre != undefined && remitente_id.apellidos != undefined) {
                                                            return <Comment
                                                                style={{ width: '100%' }}
                                                                // id={((primero == true) ? "primero-elemento" : undefined)}
                                                                className={(owner == true) ? "owner" : null}
                                                                // actions={actions}
                                                                author={remitente_id.nombre + " " + remitente_id.apellidos}
                                                                avatar={
                                                                    <Avatar
                                                                        size="large"
                                                                        //src={ remitente_id.avatar }
                                                                        alt={remitente_id.nombre + " " + remitente_id.apellidos}
                                                                        className={(remitente_id.tipo === 1) ? "avatarPE" : "avatarGlobal"}
                                                                        src={(remitente_id.tipo === 1) ? "/images/portadaPE.jpg" : ""}
                                                                        style={{ paddingTop: "5px" }}
                                                                    >
                                                                        {remitente_id.nombre}
                                                                    </Avatar>
                                                                }
                                                                content={<div>
                                                                    {mensaje} <br></br>
                                                                    {archivos.map(filename => {
                                                                        let name = "";
                                                                        let fileSplit = filename.split("_");
                                                                        for (let index = 0; index < (fileSplit.length - 1); index++) {
                                                                            name += ((index == 0) ? "" : '_') + fileSplit[index];
                                                                        }
                                                                        let extension = fileSplit[fileSplit.length - 1].split('.')[1];


                                                                        let extensionImage = null;
                                                                        if (ifIsImage(filename)) {
                                                                            extensionImage = <FaImage style={{ marginRight: '0.5em' }} />
                                                                        }
                                                                        else if (extension == "pdf" || extension == "PDF") {
                                                                            extensionImage = <FaFilePdf style={{ marginRight: '0.5em' }} />
                                                                        }
                                                                        else {
                                                                            extensionImage = <FaFileAlt style={{ marginRight: '0.5em' }} />
                                                                        }


                                                                        if (ifIsImage(`${name}.${extension}`))
                                                                            return <a className="archivito">
                                                                                <img src={axios.defaults.baseURL + '/upload/' + filename} style={{ maxWidth: '200px', margin: '1em' }} />
                                                                            </a>
                                                                        else
                                                                            return <>
                                                                                <br />
                                                                                <a target="_blank" download={true} href={axios.defaults.baseURL + '/upload/' + filename} className="archivito">
                                                                                    {extensionImage}{`${name}.${extension}`}
                                                                                </a>
                                                                            </>
                                                                    })}
                                                                </div>}
                                                                datetime={
                                                                    <Tooltip title={moment(fecha).format('YYYY-MM-DD HH:mm:ss')}>
                                                                        <span>{moment(fecha).fromNow()}</span>
                                                                    </Tooltip>
                                                                }
                                                            />

                                                        } else {
                                                            return <Comment
                                                                style={{ width: '100%' }}
                                                                // id={((primero == true) ? "primero-elemento" : undefined)}
                                                                className={(owner == true) ? "owner" : null}
                                                                // actions={actions}
                                                                author={(remitente_id != undefined) ? remitente_id.nombre : "User"}
                                                                avatar={
                                                                    <Avatar
                                                                        className="avatarGlobal"
                                                                        style={{ paddingTop: "5px" }}
                                                                        size="large"

                                                                    >
                                                                        {(remitente_id != undefined) ? remitente_id.nombre : "User"}
                                                                    </Avatar>
                                                                }
                                                                content={<div>
                                                                    {mensaje} <br></br>
                                                                    {archivos.map(filename => {
                                                                        let name = "";
                                                                        let fileSplit = filename.split("_");
                                                                        for (let index = 0; index < (fileSplit.length - 1); index++) {
                                                                            name += ((index == 0) ? "" : '_') + fileSplit[index];
                                                                        }
                                                                        let extension = fileSplit[fileSplit.length - 1].split('.')[1];


                                                                        let extensionImage = null;
                                                                        if (ifIsImage(filename)) {
                                                                            extensionImage = <FaImage style={{ marginRight: '0.5em' }} />
                                                                        }
                                                                        else if (extension == "pdf" || extension == "PDF") {
                                                                            extensionImage = <FaFilePdf style={{ marginRight: '0.5em' }} />
                                                                        }
                                                                        else {
                                                                            extensionImage = <FaFileAlt style={{ marginRight: '0.5em' }} />
                                                                        }


                                                                        if (ifIsImage(`${name}.${extension}`))
                                                                            return <a className="archivito">
                                                                                <img src={axios.defaults.baseURL + '/upload/' + filename} style={{ maxWidth: '200px', margin: '1em' }} />
                                                                            </a>
                                                                        else
                                                                            return <>
                                                                                <br />
                                                                                <a target="_blank" download={true} href={axios.defaults.baseURL + '/upload/' + filename} className="archivito">
                                                                                    {extensionImage}{`${name}.${extension}`}
                                                                                </a>
                                                                            </>
                                                                    })}
                                                                </div>}
                                                                datetime={
                                                                    <Tooltip title={moment(fecha).format('YYYY-MM-DD HH:mm:ss')}>
                                                                        <span>{moment(fecha).fromNow()}</span>
                                                                    </Tooltip>
                                                                }
                                                            />
                                                        }
                                                    })}
                                                </Row>
                                            </Card>
                                        </Row>
                                    })}
                                </Spin>
                            </div>
                        </Col>
                        <Col span={24}>

                            <div className={this.state.filesArray.length > 0 ? "filesList" : null}>

                                {this.state.filesArray.map(file => {

                                    let extensionImage = null;
                                    if (ifIsImage(file.file.name)) {
                                        extensionImage = <FaImage />
                                    }
                                    else if (file.extension == "pdf" || file.extension == "PDF") {
                                        extensionImage = <FaFilePdf />
                                    }
                                    else {
                                        extensionImage = <FaFileAlt />
                                    }

                                    let name = (file.name.length > 10) ? (file.name.slice(0, 10) + '... ') : file.name;
                                    return <div className="file-container">
                                        <div className="file">
                                            {(file.status != "complete") ? <Progress percent={parseInt(file.progress)} size="small" style={{
                                                position: 'absolute',
                                                top: '36%',
                                                left: '5px',
                                                width: 'calc(100% - 10px)',
                                            }} /> : extensionImage}
                                            <Button onClick={() => this.deleteFile(file.file.name)} className="buttom-close"><FaTimes /></Button>
                                        </div>
                                        <span style={{
                                            display: 'block',
                                            margin: '0 auto',
                                            textAlign: ' center',
                                            left: 0,
                                            color: '#7b7373',
                                            fontSize: 12

                                        }}>{name + '.' + file.extension}</span>
                                    </div>
                                })}
                            </div>

                            <div className="cnt-Mensajes-opciones">
                                <Avatar
                                    style={{ paddingTop: "5px" }}
                                    className={(user.tipo === 1) ? "" : "avatarGlobal"}
                                    src={(user.tipo === 1) ? "/images/portadaPE.jpg" : ""}
                                >
                                    {user.nombre}
                                </Avatar>
                                <Input
                                    ref={ref => this.inputReference = ref}
                                    onKeyDown={this.onKeyDownPress}
                                    placeholder="Escribe tu mensaje aqui..." />

                                <div className="cnt-msj-opciones">

                                    <Button title="Adjuntar archivo">
                                        <Upload
                                            multiple={true}
                                            showUploadList={false}
                                            customRequest={this.uploadFile}

                                        >
                                            <IconAttach />
                                        </Upload>
                                    </Button>
                                    <Button title="Enviar" onClick={() => this.onPress({ target: { value: this.inputRef.state.value } })}>
                                        <IconSend />
                                    </Button>
                                </div>
                            </div>
                        </Col>

                    </Row>
                </Col>
            </Row>


        </section>

    }
}

export default function (props) {
    let { visible = false, onClose = () => { } } = props;

    let user = React.useContext(Logged)
    let socket = React.useContext(SocketContext)

    return <Drawer
        className="drawer-chat"
        title=""
        placement="right"
        closable={false}
        onClose={onClose}
        visible={visible}
        destroyOnClose={true}
    >
        <ChatOrdenTrabajo {...{
            ...props,
            user,
            socket
        }} />
    </Drawer>

}
import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Input, Select, message, Spin, Upload, Empty } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
//componentes
import { IconCloseModal, IconArrowSelect } from '../../Widgets/Iconos';
import MyUpload from '../../Widgets/Upload';
import TextArea from "antd/lib/input/TextArea";
//css
import '../../../Styles/modales.css'


const axios = require('axios');
const { Title } = Typography;
const { Option } = Select;

/**
 *
 *
 * @export
 * @class ModalOrdenTrabajo
 * @extends {Component}
 * @description Modal para agregar una obra en InmuebleAdmin
 */

export default class ModalOrdenTrabajo extends Component {

    formModalOrdenTrabajo = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            categorias: [],
            usuarios_casas: [],
            categoria: undefined,
            fileList: undefined,
            orden: {}
        }
    }


    /***
     * @memberof ModalOrdenTrabajo
     *
     * @function componentDidMount
     */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.loadCategorias();
        this.renderUsuariosCasas()
    }


    /***
    * @memberof ModalOrdenTrabajo
    *
    * @function componentDidUpdate
    */
    componentDidUpdate(prevProps) {

        if (this.props.orden_id !== prevProps.orden_id && this.props.orden_id !== undefined) {
            this.getOrden(this.props.orden_id)
        }
    }


    /**
    * @memberof ModalOrdenTrabajo
    *
    * @method   hideModal
    * @description  Cierra el modal por medio del metodo closeMethod() que es pasado por props
    *
    **/
    hideModal = () => {
        this.setState({ categoria: undefined })
        this.props.closeMethod();
    }

    /**
     * @memberof ModalOrdenTrabajo
     *
     * @method   onFinish
     * @description  Se ejecuta al darle click al botón de guardar
     *
     **/
    onFinish = (values) => {
        this.addOrden(values);
    }


    /**
    * @memberof ModalOrdenTrabajo
    *
    * @method   loadCategorias
    * @description  Metodo que reciebe un array de elementos para devolver un array de opciones para un select,
    * ademas de agrear al arreglo el elemento a editar
    *
    **/
    loadCategorias = (search) => {
        axios.get('/categorias/list', {
            params: {
                search: search,
                limit: 20,
            }
        }).then(success => {
            this.setState({ categorias: success.data.data.itemsList })
        }).catch(error => {
            console.log(error)
            message.error('Error al cargar las categorias')
        })
    }


    /**
    * @memberof ModalOrdenTrabajo
    *
    * @method   addOrden
    * @description  Registra la orden en la BD
    *
    **/
    addOrden = (values) => {
        axios.post('/ordenes_trabajo/add', {
            concepto: values.concepto,
            categoria_id: values.categoria_id,
            descripcion: values.descripcion,
            casa_id: values.casa_id,
            archivos: (Array.isArray(values.archivos)) ? values.archivos.map(archivos =>
                archivos.response.filename
            ) : [],
        }).then(success => {
            message.success('Orden Enviada')
            this.hideModal();
        }).catch(error => {
            console.log(error)
            if(error.response.status === 403){
                message.error(error.response.data.message)
            }else
                message.error('Error al cargar las Orden')
        })
    }


    /**
    * @memberof ModalOrdenTrabajo
    *
    * @method   getOrden
    * @description  trae la infomracion de una orden
    *
    **/
    getOrden = (id) => {
        axios.get('/ordenes_trabajo/get', {
            params: {
                _id: id,
            }
        }).then(response => {
            this.setState(
                {
                    categoria: response.data.data.categoria_id,
                    orden: response.data.data
                });

            this.formModalOrdenTrabajo.current.setFieldsValue({
                concepto: response.data.data.concepto,
                categoria_id: response.data.data.categoria_id,

                descripcion: response.data.data.descripcion,
                categoria: response.data.data.categoria.nombre,
                
                casa_id: response.data.data.colono_id?.casa_id,
            })

        }).catch(error => {
            console.log(error)
            message.error('Error al cargar la Orden')
        })
    }

    /**
    * @memberof ModalOrdenTrabajo
    *
    * @method   selectOptions
    * @description  Metodo que reciebe un array de elementos para devolver un array de opciones para un select,
    * ademas de agrear al arreglo el elemento a editar
    *
    **/
    selectOptions = (array, addElemnt) => {

        let arr = [];
        if (array) {
            array.forEach(function ({ _id, nombre }, index) {
                arr.push(<Option value={_id}> {nombre}</Option>)
            })
        }

        if (array && addElemnt) {
            if (!array.some(element => element._id === addElemnt._id)) {
                arr.push(<Option value={addElemnt._id}> {addElemnt.nombre}</Option>)
            }
        }
        return arr
    }


    renderUsuariosCasas = (searchValue = "") => {
        axios.get('/casas/list', {
            params: {
                search: searchValue,
                noPaginado: true
            }
        }).then(response => {
            this.setState({ usuarios_casas: response.data.data })
        }).catch(error => {
            message.error('Error al cargar las Casas')
        })

    }



    /**
     * @method normFile
     * @description Segun la lista, declara el boton de aceptar como disponible segun si se está enviando un archivo.
     * @param e (string)
     * Recibe el nombre del archivo.
     */
    normFile = (e) => {
        const { file, fileList } = e;

        let upload = false;

        for (let x = 0; x < fileList.length; x++) {
            if (fileList[x].response) {
                fileList[x].name = fileList[x].response.filename
            }

            if (fileList[x].status !== "done" && fileList[x].response && !this.state.loading) {
                this.state.loading = true;
                this.setState({ update: !this.state.update })
                break;
            }
        }

        /**
         * Cuando se sube un archivo, se debe actualizar la lista de imagenes, cuando se selecciona eliminar, se debe actualizar la lista una vez que se elimina
         */
        if (file.status == "done") {
            this.setState({
                image: {
                    url: axios.defaults.baseURL + '/upload/' + e.file.response.filename,
                    name: e.file.response.filename
                }
            });

        }
        if (file.status == "removed" && file.response != undefined)
            this.removeFile((file.response.filename != undefined) ? file.response.filename : file.name);

        return e && e.fileList;
    };


    /**
     * @method removeFile
     * @description Elimina un archivo del WebService.
     *
     * @param images (string)
     * Recibe el nombre del archivo.
     */
    removeFile = (image) => {
        axios.post("/voucher/delete", {
            filename: image
        })
            .then(res => {
                console.log("Archivo removido con exito", res);
            })
            .catch(res => {
                console.log("Archivo no se pudo remover", res);
            })
    };


    render() {
        const { id, accionModal, visible, tipo, userAdmin } = this.props;
        const { normFile } = this
        let orden = this.state.orden;
        let contenido = null;


        if (accionModal == "Ver") {

            contenido = <Form
                layout="vertical"
                className="mdl-form frm-ordenesTrabajo"
                name="formulario-OT"
                onFinish={this.onFinish}
                ref={this.formModalOrdenTrabajo} >
                <Row align="center">
                    <Col xs={22} xl={{ span: 10, pull: 1 }} >
                        <Form.Item label="Concepto" name="concepto">
                            <Title level={5}>{orden?.concepto}</Title>
                        </Form.Item>
                    </Col>


                    <Col xs={22} xl={{ span: 10, push: 1 }} >
                        <Form.Item label="Categoria" name="categoria" >
                            <Title level={5}>{(orden?.categoria != undefined) ? orden.categoria.nombre : ""}</Title>
                        </Form.Item>
                    </Col>
                </Row>


                {(tipo != undefined && tipo == 1) ?
                    <Row align="center">
                        <Col xs={22} >
                            <Form.Item
                                name="casa_id" //-------------------------------------------------------casa_id
                                label="Casa"
                            >
                                <Title level={5}>{(orden?.casa != undefined) ? orden.casa.nombre : ""}</Title>
                            </Form.Item>
                        </Col>
                    </Row>
                    : null}


                <Row align="center">
                    <Col span={22} >
                        <Form.Item
                            label="Archivos" name="archivos" >
                            {(orden?.archivos != undefined) ? orden?.archivos.map(comprobante => <a title="Abrir archivo" style={{ cursor: "pointer", width: '100%', fontWeight: '600', display: 'block', color: "#40a9ff", marginBottom: "10px" }} href={axios.defaults.baseURL + '/voucher/' + comprobante} target="_blank" >{comprobante}</a>) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={"Sin archivos"} />}
                        </Form.Item>
                    </Col>
                </Row>
                <Row align="center">
                    <Col span={22} >
                        <Form.Item
                            label="Descripcion" name="descripcion" >
                            <Title level={5}>{orden?.descripcion}</Title>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

        }
        else {

            contenido = <Form
                layout="vertical"
                className="mdl-form frm-ordenesTrabajo"
                name="formulario-OT"
                onFinish={this.onFinish}
                ref={this.formModalOrdenTrabajo} >
                <Row align="center">

                    <Col xs={20} xl={{ span: 11, pull: 1 }} >
                        <Form.Item
                            name="concepto" //-------------------------------------------------------concepto
                            label="Concepto"
                            rules={[{
                                required: true,
                                message: "Por favor, ingrese concepto"
                            }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col xs={20} xl={{ span: 11, push: 1 }} >
                        <Form.Item
                            name="categoria_id" //-------------------------------------------------------categoria_id
                            label="Categoría"
                            rules={[{
                                required: true,
                                message: "Por favor, seleccione categoría"
                            }]}
                        >
                            <Select
                                showSearch
                                optionFilterProp="children"
                                filterOption={false}
                                onSearch={search => this.loadCategorias(search)}
                            >
                                {this.selectOptions(this.state.categorias, this.state.categoria)}
                            </Select>
                        </Form.Item>
                        <IconArrowSelect />
                    </Col>
                </Row>
                {(tipo != undefined && tipo == 1) ?
                    <Row >
                        <Col xs={20} xl={{ span: 11, push: 0 }} >
                            <Form.Item
                                name="casa_id" //-------------------------------------------------------casa_id
                                label="Casa"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione una casa"
                                }]}
                            >
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={false}
                                    onSearch={search => this.renderUsuariosCasas(search)}
                                >
                                    {
                                        this.state.usuarios_casas.map((casa, index) => {
                                            return <Option value={casa._id}> {casa.nombre}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                            <IconArrowSelect />
                        </Col>
                    </Row>

                    : null}
                <Row>
                    <Col>
                        <Form.Item
                            label="Archivos"
                            name="archivos"
                            valuePropName="fileList"
                            getValueFromEvent={normFile}
                        >

                            <Upload
                                className="avatar-uploader"
                                action={axios.defaults.baseURL + "/voucher/add"}
                                multiple={true}
                            >
                                <Button icon={<PlusOutlined />}>Subir</Button>
                            </Upload>
                        </Form.Item>
                    </Col>
                </Row>


                <Row >
                    <Col span={24} >
                        <Form.Item
                            name="descripcion"//-------------------------------------------------------descripcion
                            label="Descripción"
                            rules={[{
                                required: true,
                                message: "Por favor, ingrese descripción"
                            }]}
                        >
                            <TextArea />
                        </Form.Item>
                    </Col>
                </Row>


                {this.props.accionModal === 'Crear' ? <Row style={{ textAlign: "center" }}>
                    <Col span={24}>
                        <Form.Item >
                            <Button htmlType="submit" type="primary" className="btn-verde" >
                                Guardar
                        </Button>
                        </Form.Item>
                    </Col>
                </Row> : null}
            </Form>
        }


        return (
            <Modal
                visible={visible}
                onCancel={this.hideModal}
                title={null}
                footer={null}
                closable={false}
                className="modal-form"
                destroyOnClose={true}
                zIndex={1000}
                width={700}
            >
                <Row className="modal-header">
                    <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={this.hideModal}>
                        <IconCloseModal />
                    </Button>
                    <Title level={3} className="modal-title">{accionModal} Orden de Trabajo</Title>
                </Row>

                <Spin spinning={this.state.loading} >
                    {contenido}
                </Spin>
            </Modal>
        )
    }
}

import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Input, Select, message, Spin, Upload } from 'antd';
import TextArea from "antd/lib/input/TextArea";
//componentes
import { IconCloseModal, IconArrowSelect } from '../../Widgets/Iconos';
import  MyUpload   from '../../Widgets/Upload';
//css
import './../../../Styles/modales.css'
import './../../../Styles/documentos.css'

const axios = require('axios');
const { Title } = Typography;
const { Option } = Select;

/**
 *
 *
 * @export
 * @class ModalArchivo
 * @extends {Component}
 * @description Modal para agregar documentos
 */

export default class ModalArchivo extends Component {

    formModalArchivo = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
    }


    /**
    * @memberof ModalArchivo
    *
    * @method   hideModal
    * @description  Cierra el modal por medio del metodo closeMethod() que es pasado por props
    *
    **/
    hideModal = () => {
        this.props.closeMethod();
    }


    onFinish = (values) => {
        this.addArchivo(values)
    }

    /**
    * @memberof ModalArchivo
    *
    * @method   setFile
    * @description  Cuando se guarda un archivo en la BD por medio del componente Upload, la informacion del archivo es pasado
    * al state para manejar esa infomacion, ya que el formulario por alguna razon no detecta los valores ingresados
    *
    **/
    setFile = (file) => {
        this.setState({fileToUpload: file})
    }

    /**
    * @memberof ModalArchivo
    *
    * @method   addArchivo
    * @description  Guarda los datos del archivo en la BD
    *
    **/
    addArchivo = (values) => {
        axios.post('/carpetas/add/archivo',{
            casa_id: this.props.casa_id,
            nombre: values.nombre,
            descripcion: values.descripcion,
            filename: this.state.fileToUpload.filename,
            type: this.state.fileToUpload.type
        })
        .then(response => {
            this.hideModal(); 
            message.success('Archivo guardado')
        })
        .catch(error => message.error('No se guardo el Archivo'))

    }


    render() {
        const { id, accionModal, visible } = this.props;


        return (
            <Modal
                visible={visible}
                onCancel={this.hideModal}
                title={null}
                footer={null}
                closable={false}
                className="modal-form"
                destroyOnClose={true}
                zIndex={1000}
                width={500}
            >
                <Row className="modal-header">
                    <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={this.hideModal}>
                        <IconCloseModal />
                    </Button>
                    <Title level={3} className="modal-title">{this.props.accionModal} Archivo</Title>
                </Row>

                <Spin spinning={this.state.loading} >
                    <Form 
                        layout="vertical" 
                        className="mdl-form frm-documentos" 
                        name="formulario-Docto" 
                        onFinish={this.onFinish}
                        initialValues={this.props.file} 
                        ref={this.formModalArchivo} >
                        <Row align="center">

                            <Col xs={22} >
                                <Form.Item 
                                    label="Nombre" 
                                    name="nombre"//----------------------------------------------------Nombre
                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese nombre"
                                    }]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>

                            <Col span={22} >
                                <Form.Item 
                                    label="Descripción" 
                                    name="descripcion"//----------------------------------------------------descripcion
                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese descripción"
                                    }]}
                                >
                                    <TextArea />
                                </Form.Item>
                            </Col>

                            <Col span={22} >

                                <Form.Item 
                                    label="Archivo"
                                    valuePropName="fileList" 
                                    name='archivo'//----------------------------------------------------archivo
                                >
                                    <MyUpload setFile={this.setFile}/>
                                </Form.Item>
                            </Col>

                        </Row>

                        <Row style={{ textAlign: "center" }}>
                            <Col span={24}>
                                <Form.Item >
                                    <Button htmlType="submit" type="primary" className="btn-verde" >
                                        Guardar
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        )
    }
}

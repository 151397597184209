import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import axios from 'axios';

import { socket } from './Hooks/Socket';


axios.defaults.baseURL = process.env.REACT_APP_WEB_SERVICE 


axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

axios.defaults.headers.post["Content-Type"] = "application/json";

String.prototype.toMoney = function (toFixed = true, fractionDigits = 2) {
    if (!toFixed)
        return parseFloat(this).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    else
        return parseFloat(this).toFixed(fractionDigits).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

Number.prototype.toMoney = function (fractionDigits = 2) {
    return this.toFixed(fractionDigits).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

const Main = () => <React.StrictMode>
    <App socket={socket} />
</React.StrictMode>



ReactDOM.render(<Main />,document.getElementById('root'));

import React, { Component } from 'react';
import { Row, Col, Button, Radio, List, Card, Typography, Popconfirm, message, Pagination } from 'antd'
import { Link } from "react-router-dom";
import axios from 'axios';
//componentes
import { IconPlus, IconEdit, IconDelete } from '../../Widgets/Iconos.js'
//modal
import ModalCategorias from '../Modales/Proveedores/ModalCategorias';
//css
import './../../../Styles/Global.css';

const { Text } = Typography;

export default class Categorias extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            categorias: [],
            modalCategoriasVisible: false,
            modalTitle: 'Añadir',
            categoria_id: undefined,


            /*Paginador*/
            page: 1,
            limit: 10,
            pageCount: 0,
            itemCount: 1,
        }
    }

    
    /**
     * @methodOf Categorias
     * @function componentDidMount
     *
     */
    componentDidMount() {
        this.props.updateNavigationInformation('Categorias');
        this.listCategorias(1)
    }


    /**
     * @methodOf Categorias
     * @function hideModal
     *
     */
    hideModal = () => {
        this.setState({ modalCategoriasVisible: false, categoria_id: undefined })
        this.listCategorias(1)
    }


    /*
    *
    * @methodOf Categorias
    * @function listCategorias
    *
    * @description retorna la lista de categorias
    */
    listCategorias = (page) => {
        this.setState({ loading: true })
        axios.get('/categorias/list', {
            params: {
                page: page,
                limit: 10
            }
        }).then(response => {
            this.setState({
                categorias: response.data.data.itemsList,
                pageCount: response.data.data.paginator.pageCount,
                itemCount: response.data.data.paginator.itemCount,

            })
        }).catch(error => {
            console.log(error)
            message.error('Error al cargar los usuarios')
        }).finally(() => { this.setState({ loading: false }) })
    }


    /*
    * @methodOf Categorias
    * @function deleteCategoria
    *
    * @description Elimina una categoria
    **/
    deleteCategoria = (id) => {
        axios.post('/categorias/delete', {
            _id: id
        }).then(response => {
            message.success('La categoria se ha eliminado')
        }).catch(error => {
            console.log(error)
            message.error('No se ha eliminado la Categoria')
        }).finally(() => { this.listCategorias(1) })
    }

    render() {
        return (
            <section className="section">
                <Row className="section-header">
                    <Col span={12} className="">
                        <Radio.Group className="radio-grp-green" value="b">
                            <Radio.Button value="a">
                                <Link className="text-white" to={"/admin/proveedores"}>Proveedores</Link>
                            </Radio.Button>
                            <Radio.Button value="b">Categorias</Radio.Button>
                        </Radio.Group>
                    </Col>
                    <Col span={12} className=" flex-right">
                        <Button className="btn-plus right" onClick={() => { this.setState({ modalCategoriasVisible: true, modalTitle: 'Añadir' }) }}>
                            <IconPlus />
                        </Button>
                    </Col>
                </Row>
                <Row className="section-content row-list">
                    <Col span={24}>
                        <List
                            className="component-list"
                            itemLayout="horizontal"
                            loading={this.state.loading}
                            dataSource={this.state.categorias}
                            renderItem={item => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row style={{ width: '100%' }} className="">
                                            <Col span={20} className="flex-left">
                                                <Text>{item.nombre}</Text>
                                            </Col>
                                            <Col span={4} className="center">
                                                <Button className="btn btn-edit" onClick={(e) => { this.setState({ modalCategoriasVisible: true, categoria_id: item._id, modalTitle: 'Editar' }) }}>
                                                    <IconEdit />
                                                </Button>
                                                <Popconfirm
                                                    title="¿Deseas Eliminar?"
                                                    placement="topRight"
                                                    onConfirm={e => {
                                                        this.deleteCategoria(item._id)
                                                    }}
                                                    onCancel={e => e.stopPropagation()}
                                                    okText="Si"
                                                    cancelText="No"
                                                    onClick={e => e.stopPropagation()}
                                                >
                                                    <Button className="btn btn-delete"
                                                        onClick={e => e.stopPropagation()}>
                                                        <IconDelete />
                                                    </Button>
                                                </Popconfirm>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />
                    </Col>
                </Row>
                <Row style={{ margin: "1em 2em", paddingBottom:"3em" }}>
                    <Pagination defaultCurrent={1} total={this.state.itemCount} onChange={(page) => this.listCategorias(page)} />
                </Row>

                <ModalCategorias
                    visible={this.state.modalCategoriasVisible}
                    modalTitle={this.state.modalTitle}
                    closeMethod={this.hideModal}
                    categoria_id={this.state.categoria_id}
                />
            </section>
        )
    }
}
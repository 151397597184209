import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Button, Col, Row, List, Input, Card, Typography, message, Dropdown, Menu, Pagination, Popconfirm, Tooltip } from 'antd'
import axios from 'axios';
//componentes
import { IconPDF, IconFolder, IconEyeGreen, IconPlus, IconMenuDots } from '../../Widgets/Iconos'
//modales
import ModalCarpeta from '../Modales/Documentos/ModalCarpeta'
//css
import './../../../Styles/documentos.css';


const { TextArea } = Input;
const { Title, Text } = Typography;

export default class Documentos extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            carpetas: [],
            visible: false,
            carpeta_id: undefined,

            /*Paginador*/
            page: 1,
            limit: 10,
            pageCount: 0,
            itemCount: 1,
        }
    }

    /***
     * @memberof Documentos
     *
     * @function componentDidMount
     */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.props.updateNavigationInformation('Documentos');
        this.listCarpetas(1);
    }

 
    /**
   * @memberof Documentos

   * @method   modalPagar
   * @description Abrir modal.
   *
   **/
    modalAgregar = () => {
        this.setState({
            visible: true,
            accionModal: 'agregar',
        })
    }



    /**
    * @memberof ModalCarpeta
    *
    * @method   hideModal
    * @description  Cierra modal.
    **/
    hideModal = () => {
        this.setState({ visible: false, carpeta_id: undefined });
        this.listCarpetas(1)
    }


    /**
    * @memberof ModalCarpeta
    *
    * @method   listCarpetas
    * @description  Carga las carpetas registradas en la BD
    *
    **/
    listCarpetas = (page) => {
        this.setState({ loading: true })
        axios.get('/carpetas/list', {
            params: {
                page: page,
                limit: 50,
            }
        }).then(response => {
            this.setState({
                carpetas: response.data.data.itemsList,
                pageCount: response.data.data.paginator.pageCount,
                itemCount: response.data.data.paginator.itemCount,
            })
        }).catch(error => {
            console.log(error)
            message.error('No se cargaron los folders')
        }).finally(() => this.setState({ loading: false }))
    }


    /**
    * @memberof ModalCarpeta
    *
    * @method   deleteFolder
    * @description  Carga las carpetas registradas en la BD
    *
    **/
    deleteFolder = (id) => {
        axios.post('/carpetas/delete', {
            _id: id
        }).then(response => {
            this.listCarpetas(1)
            message.success('Folder Eliminado')
        }).catch(error => {
            console.log(error)
            message.error('No se elimino el Folder')
        })
    }



    render() {

        return (
            <section className="section sec-Documentos">
                <Row className="section-header">
                    <Col span={20} className="cnt-card-folders">
                        <Title level={3} className="page-sub-title" >Folders</Title>
                    </Col>

                    <Col span={4} className=" flex-right">
                        <Button className="btn-plus right" title="Agregar" onClick={() => this.setState({ visible: true, accionModal: 'Crear' })}>
                            <IconPlus />
                        </Button>
                    </Col>
                </Row>


                <Row className="section-content">
                    <Col span={24}>
                        <List
                            className="p-1 list-folder"
                            grid={{ gutter: 0, xs: 1, sm: 2, md: 3, lg: 3, xl: 3, xxl: 4 }}
                            dataSource={this.state.carpetas}
                            loading={this.state.loading}
                            renderItem={item => (
                                <List.Item className="list-item ">
                                    <Link to={`/colono/archivos/${item.casa_id}`}>
                                        <Card className="card-docto ">
                                            <Row>
                                                <Col span={7} className="icon-folder center ">
                                                    <IconFolder />
                                                </Col>
                                                <Col span={15} className="">
                                                    <Tooltip placement="topRight" title={item.nombre}>
                                                        <Title level={2}> {item.nombre} </Title>
                                                    </Tooltip>
                                                    <p> # {item.archivos.length} Archivos</p>
                                                </Col>
                                                <Col span={2} className="center ">
                                                    <Dropdown
                                                        trigger={['click']}
                                                        placement="bottomLeft"
                                                        overlay={
                                                            <Menu>
                                                                <Menu.Item style={{padding: '0'}}>
                                                                    <Text 
                                                                        style={{height: '30px', display: 'inline-block', lineHeight: '30px',padding: '0 10px'}}
                                                                        onClick={(e) => {
                                                                            e.stopPropagation()
                                                                            this.setState({ visible: true, accionModal: 'Editar', carpeta_id: item._id })
                                                                    }}> 
                                                                        Editar 
                                                                    </Text>
                                                                </Menu.Item>
                                                                <Menu.Item style={{padding: '0'}}>
                                                                    <Popconfirm
                                                                        title="¿Deseas Eliminar el folder?"
                                                                        placement="topRight"
                                                                        onConfirm={e => {
                                                                            e.stopPropagation()
                                                                            this.deleteFolder(item._id)
                                                                        }}
                                                                        onCancel={e =>{ e.stopPropagation()}}
                                                                        okText="Si"
                                                                        cancelText="No"
                                                                        onClick={e => e.stopPropagation()}
                                                                    >
                                                                        <Text style={{height: '30px', display: 'inline-block', lineHeight: '30px',padding: '0 10px'}}>Eliminar</Text>
                                                            </Popconfirm>
                                                                </Menu.Item>
                                                            </Menu>
                                                        }
                                                    >
                                                        <Button className="btn-dots">
                                                            <IconMenuDots />
                                                        </Button>
                                                    </Dropdown>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Link>
                                </List.Item>
                            )}
                        />
                    </Col>


                    <Row style={{ margin: "1em 2em", paddingBottom: "3em" }}>
                        <Pagination defaultCurrent={1} total={this.state.itemCount} onChange={(page) => this.listCarpetas(page)} />
                    </Row>
                </Row>

                <ModalCarpeta
                    visible={this.state.visible}
                    accionModal={this.state.accionModal}
                    closeMethod={this.hideModal}
                    carpeta_id={this.state.carpeta_id}
                    accept={() => {
                        this.hideModal();
                    }}
                />
            </section>
        );
    }
}

import React, { Component, useContext } from 'react';
import { Row, Col, Dropdown, Menu, Badge, Avatar, Typography, Image, List, Comment } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import Cookies from 'universal-cookie';
import PropTypes from 'prop-types';
import { Link, Redirect } from "react-router-dom";

import { IconHeaderMensajes, IconHeaderNotificaciones } from '../Widgets/Iconos';

import Logged from '../../Hooks/Logged';
import { SocketContext } from '../../Hooks/Socket';
import '../../Styles/header.css'
import { logDOM } from '@testing-library/react';


const axios = require('axios').default
const moment = require('moment')

const { Title, Text } = Typography;

class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: null,
            notificacionesCount: 0,
            mensajesCount: 0,



            notificaciones: [],


            notificaciones_sin_leer: 0
        }
    }

    componentDidMount() {
        this.props.socket.emit("header", sessionStorage.getItem("token"))
        this.props.socket.removeListener('start_notifications');
        this.props.socket.removeListener('header_message');
        this.props.socket.on('start_notifications', this.IO_start_notifications)

        this.props.socket.removeListener('header_message');
        this.props.socket.on('header_message', this.IO_header_message)


        this.props.socket.removeListener('notificaciones');
        this.props.socket.on('notificaciones', this.notificaciones)
        this.props.socket.emit("notificaciones")
    }

    /**
     *
     * @param {*} {count}
     * @memberof Header
     * @function notificaciones
     * 
     * @description agrega los mensajes no leidos a la seccion de notificaciones activas. 
     */
    notificaciones = ({ notificaciones, count }) => {
        //console.log('notificaciones', notificaciones);
        this.setState({
            notificaciones: notificaciones.data,
            count
        })
    };


    /**
     *
     * @param {*} {count}
     * @memberof Header
     * 
     * @description agrega los mensajes no leidos a la seccion de notificaciones activas. 
     */
    IO_start_notifications = ({ count }) => this.setState({ mensajesCount: count })


    /**
     *
     * @param {*} {count}
     * @memberof Header
     * 
     * @description Si llega un mensaje, llega la notificación
     */
    IO_header_message = (mensajes_sin_leer) => {
        //console.log('this', this.props);
        this.setState({ mensajesCount: mensajes_sin_leer })

    }


    IO_sin_leer = (notificaciones_sin_leer) => {
        //console.log('notificaciones_sin_leer',notificaciones_sin_leer);
        this.setState({ notificaciones_sin_leer })
    }

    /**
     *
     * @param {*} {count}
     * @memberof Header
     * 
     * @function LogOut 
     * @description Si llega un mensaje, llega la notificación
     */
    LogOut = () => {
        this.setState({ redirect: '/login' })
        const cookies = new Cookies();
        sessionStorage.removeItem('token')
        sessionStorage.removeItem('username')
        cookies.remove('token', { path: '/' })
        cookies.remove('username', { path: '/' })
    }


    /**
     *
     * @param {*} {count}
     * @memberof Header
     * 
     * @function setNotifications 
     * @description Si llega un mensaje, llega la notificación
     */
    setNotifications = (e) => {
        // console.log('TRUE ', e);
        if (e == true) {
            this.props.socket.emit("leidos", this.state.notificaciones.map(e => e._id))
        }
    }

    render() {
        const { user, title } = this.props;

        let { notificaciones, notificacionesCount, mensajes, mensajesCount, redirect } = this.state


        let usuario_logeado = {
            nombre: user.nombre ? user.nombre : '-',
            apellidos: user.apellidos ? user.apellidos : '-',
            casa: user.casa_id ? user.casa_id.nombre : '-',
        };



        //console.log('usuario_logeado', usuario_logeado);



        return (
            <Row className="header ">
                {redirect ? <Redirect to={'/login'} /> : null}
                <Col xs={24} lg={5} className="header-logo  center">
                    <Image className="logo" width="280" src={"/images/logo.png"} alt="logo" preview={false} />
                </Col>
                <Col xs={24} md={12} lg={11} className="header-title">
                    <Title className=" page-title" level={2}>{title}</Title>
                </Col>
                <Col xs={24} md={12} lg={8} className="header-options ">
                    <Row className="" style={{ width: '100%' }}>
                        <Col xs={9} sm={10} md={12} lg={10} className="col-icons">
                            <div className="cnt-headerIcon ">
                                <Link to="/colono/mensajes" >
                                    <Badge count={this.state.mensajesCount}>
                                        <IconHeaderMensajes className="icon-mensajes" />
                                    </Badge>
                                </Link>
                            </div>
                            {(user && user.tipo == 1) ?
                                <Dropdown
                                    onVisibleChange={this.setNotifications}
                                    className="notifications"
                                    overlay={
                                        <List
                                            itemLayout="horizontal"
                                            dataSource={notificaciones}
                                            renderItem={item => (
                                                <Link to={(item.coleccion == "ordenes_trabajo") ? "/colono/ordenesTrabajo" : "/colono/salonUsosMultiples"}>
                                                    <Comment
                                                        style={{ borderBottom: '1px solid #dad9d9' }}
                                                        author={(item && item.usuario_id)?(item?.usuario_id?.nombre + " " + item?.usuario_id?.apellidos):<strong>Colono</strong>}
                                                        content={item?.detalle}
                                                        datetime={moment(item?.createdAt).fromNow()}
                                                    />
                                                </Link>
                                            )}
                                        />

                                    } trigger={['click']}>
                                    <div className="cnt-headerIcon ">
                                        <Badge count={this.state.count}>
                                            <IconHeaderNotificaciones className="icon-Notification" />
                                        </Badge>
                                    </div>
                                </Dropdown>
                                : null}

                        </Col>
                        <Col xs={15} sm={{ span: 10, offset: 4 }} md={{ span: 12, offset: 0 }} lg={12} className="col-icons">
                            <Dropdown

                                trigger={['click']}
                                overlay={
                                    <Menu>
                                        <Menu.Item key="0" onClick={e => this.LogOut()}> Cerrar Sesión </Menu.Item>
                                    </Menu>
                                }
                            >
                                <div className="header-divDrop ">
                                    {/* <Avatar
                                        className="avatarGlobal"
                                        style={{ minWidth: '40px' }}
                                        name={[usuario_logeado.nombre, usuario_logeado.apellido]}
                                        //image={user.avatar}
                                        size={"large"}
                                    >{usuario_logeado.nombre.charAt(0).toUpperCase()} </Avatar>
                                    */}

                                    <Avatar
                                        className="avatarGlobal"
                                        style={{ minWidth: '40px' }}
                                        //name={[usuario_logeado.nombre, usuario_logeado.apellido]}
                                        //image={user.avatar}
                                        size={"large"}
                                        title={usuario_logeado.nombre.toUpperCase()}
                                    >{usuario_logeado.nombre.toUpperCase()} </Avatar>

                                    <div className=" user-info ">
                                        {/* <Text strong className="">{usuario_logeado.nombre}</Text> */}
                                        <Text strong className="">{usuario_logeado.casa}</Text>
                                    </div>
                                    <a className="ant-dropdown-link header-user-drop " onClick={e => e.preventDefault()}>
                                        <DownOutlined />
                                    </a>
                                </div>
                            </Dropdown>
                        </Col>
                    </Row>


                </Col>
            </Row>
        );

    }
}


Header.propTypes = {
    title: PropTypes.string,
    back: PropTypes.bool,
    onBack: PropTypes.func
};


Header.defaultProps = {
    back: false,
    title: '*',
};

export default (props) => <Header {...props} user={useContext(Logged)} socket={useContext(SocketContext)} /> 
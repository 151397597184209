import React, {Component} from "react";
import {Button, Col, Form, Input, message, Modal, Row, Select, Spin, Typography} from 'antd';
import './../../../../Styles/modales.css'

import {IconCloseModal} from '../../../Widgets/Iconos';

import {DeleteOutlined, LoadingOutlined, PlusOutlined} from '@ant-design/icons'

// LoadingOutlined

const moment = require('moment');
const axios = require("axios").default;


const {Option} = Select;

const {Title,} = Typography;



export default  class ModalCategorias extends Component {

    formModaluser = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            loadingImage: false,
            form: {},

            proyectos: []

        }
    }


    /**
     * @methodOf ModalCategorias
     *
     * @function componentDidUpdate
     * @description Se ejecuta cuando se inicia el component, declara en el header el session storage
     *
     * */
    componentDidMount(){
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
    }


    /**
    * @memberof ModalCategorias
    *
    * @method   componentDidUpdate
    * @description  Si se va Editar un usuario va y busca la informacion de este
    *
    **/
    componentDidUpdate(prevProps) {
        if(this.props.categoria_id !== prevProps.categoria_id && this.props.categoria_id !== undefined){
            this.getCategoria(this.props.categoria_id)
        }
    }


    /**
    * @memberof ModalCategorias
    *
    * @method   onFinish
    * @description  Metodo que se ejecuta al guardar el formulario
    *
    **/
    onFinish = (values) => {
        if (this.props.modalTitle === 'Editar') {
            this.updateCategoria(values);
        } else {
            this.addCategorias(values);
        }
    }


    /**
    * @memberof ModalCategorias
    *
    * @method   addCategorias
    * @description  Guarda el usuario en la BD
    *
    **/
    addCategorias = (values) => {
        axios.post('/categorias/add',{
            nombre: values.nombre,
        }).then(response => {
            this.props.closeMethod()
            message.success('La categoria se agrego correctamente')
        }).catch(error => {
            console.log(error)
            message.error('No se pudo agregar la categoria')
        })
    }


    /**
    * @memberof ModalCategorias
    *
    * @method   getCategoria
    * @description  Trae la infomracion de una categoria
    *
    **/
    getCategoria = (id) => {
        axios.get('/categorias/get',{
            params:{_id: id}
        }).then(response => {
            this.formModaluser.current.setFieldsValue({
                nombre: response.data.data.nombre,
            })
        }).catch(error => {
            console.log(error)
            message.error('No se pudo cargar el usuario')
        })
    }


    /**
    * @memberof ModalCategorias
    *
    * @method   updateCategoria
    * @description  actualiza la infomracion de una categoria
    *
    **/
    updateCategoria = (values) => {
        axios.post('/categorias/update',{
            _id: this.props.categoria_id,
            nombre: values.nombre,
        }).then(response => {
            this.props.closeMethod()
            message.success('La categoria se actualizo correctamente')
        }).catch(error => {
            console.log(error)
            message.error('No se actualizo la categoria')
        })
    }


    render() {
        return (
            <Modal
                visible={this.props.visible}
                onCancel={this.props.closeMethod}
                title={null}
                footer={null}
                closable={false}
                className="modal-form"
                destroyOnClose={true}
                zIndex={1000}
                width={500}
            >
                <div className="modal-header">
                    <Button type="ghost" title="Cerrar" className="btn-close-modal" onClick={this.props.closeMethod}>
                        <IconCloseModal/>
                    </Button>
                    <Title level={3} className="modal-title">{this.props.modalTitle} categoria</Title>
                </div>
                <Spin spinning={this.state.loading}>

                    <Form
                        layout="vertical"
                        className="mdl-form frm-documentos"
                        name="formulario-tajo"
                        onFinish={this.onFinish}
                        ref={this.formModaluser}
                    >

                        <Row align="center">
                            <Col xs={20}>
                                <Form.Item
                                    label="Nombre"
                                    name="nombre" //<--------------------------------------- nombre
                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese el nombre"
                                    }]}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row style={{textAlign: "center"}}>
                            <Col span={24}>
                                <Form.Item>
                                    <Button htmlType="submit" type="primary" className="btn-green">
                                        Guardar
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        )
    }
}

import React, { Component, useRef, useEffect } from "react";
import { Row, Col, Button, Modal, Typography, Form, Input, Select, message, Spin, Upload, Empty, Radio } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import SignaturePad from 'react-signature-pad-wrapper'

//componentes
import { IconCloseModal, IconArrowSelect } from '../../Widgets/Iconos';
import MyUpload from '../../Widgets/Upload';
import TextArea from "antd/lib/input/TextArea";
//css
import './../../../Styles/ordenesTrabajo.css';
import '../../../Styles/modales.css'


const axios = require('axios');
const { Title } = Typography;
const { Option } = Select;

/**
 *
 *
 * @export
 * @class ModalOrdenTrabajo
 * @extends {Component}
 * @description Modal para agregar una obra en InmuebleAdmin
 */

export default class ModalOrdenTrabajo extends Component {

	formModalOrdenTrabajo = React.createRef();
	canvas =  React.createRef();
	signaturePad = React.createRef();

	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			fileList: undefined,
			view: 'input',
			canvasWidth: 600,
		}
	}



	/***
	 * @memberof ModalOrdenTrabajo
	 *
	 * @function componentDidMount
	 */
	componentDidMount() {
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
		window.addEventListener("resize", this.handleResize);
		if(this.state.canvasWidth >= window.innerWidth){
			this.setState({canvasWidth: window.innerWidth-50})
		}
	}

 	handleResize = (e) => {
  		const ctx = this.canvas?.current?.getContext("2d")
  		if(window.innerWidth <= 650){
  			this.setState({canvasWidth: window.innerWidth-50})
  		}else if(window.innerWidth > 650){
  			this.setState({canvasWidth: 600})
  		}
 	};



 	componentWillUnmount() {
  		window.addEventListener("resize", this.handleResize);
 	} 


	/**
	* @memberof ModalOrdenTrabajo
	*
	* @method   hideModal
	* @description  Cierra el modal por medio del metodo closeMethod() que es pasado por props
	*
	**/
	hideModal = () => {
		this.props.closeMethod();
	}

	/**
	 * @memberof ModalOrdenTrabajo
	 *
	 * @method   onFinish
	 * @description  Se ejecuta al darle click al botón de guardar
	 *
	 **/
	onFinish = async () => {
		this.setState({loading: true})
		const { view } = this.state
		if(view === 'input' && this.canvas.current)
			this.saveCanvas(this.canvas.current)
		else if (view === 'draw' && this.signaturePad){
			if(!this.signaturePad.isEmpty()) this.saveCanvas(this.signaturePad._canvas)
			else {
				message.error('Falta firma')
				this.setState({loading: false})
			}
		}
		
	}

	/**
	 * @memberof ModalOrdenTrabajo
	 *
	 * @method   saveCanvas
	 * @description Guarda la imagen geneada por el canvas en el server, y actualiza el estatus de la orden
	 * 
	 * @param: canvas - ref del canvas
	 **/
	saveCanvas = async (canvas) => {
		let imageBlob = await new Promise(resolve => canvas.toBlob(resolve,'image/png'));
		let formData = new FormData();

	  	formData.append("file", imageBlob, 'signature.png');
	  	formData.append("orden_id", this.props.orden_id);

	  		axios.post('/ordenes_trabajo/signature',formData,{ 
				headers: { 
					Authorization: sessionStorage.getItem('token'),
					'Content-Type': 'multipart/form-data', 
				} 
			})
	  		.then(response => {
	  			message.success('Orden firmada con exito')
	  			this.hideModal();
	  		}).catch(error =>{
	  			console.log(error)
	  			message.error('No se pudo subir el archivo')
	  		}).finally(()=>{this.setState({loading: false})})
	}


	render() {

		return (
			<Modal
				visible={this.props.visible}
				onCancel={this.hideModal}
				title={null}
				footer={null}
				closable={false}
				className="modal-form"
				destroyOnClose={true}
				zIndex={1000}
				width={700}
			>
				<Row className="modal-header">
					<Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={this.hideModal}>
						<IconCloseModal />
					</Button>
					<Title level={3} className="modal-title">Firmar Orden de Trabajo</Title>
				</Row>
				<Row>
					<Col span={24} className="center mb-2 mt-1">
						<Radio.Group
							className="radio-grp-green sm"
							options={[
								{label: 'Nombre', value: 'input' },
								{label: 'Firma', value: 'draw' }
							]}
							defaultValue={'input'}
							optionType="button"
							onChange={({target})=>{this.setState({view: target.value})}}
						/>
					</Col>
				</Row>

				<Spin spinning={this.state.loading} >
					<Form
						layout="vertical"
						className="mdl-form frm-ordenesTrabajo"
						name="formulario-OT"
						onFinish={this.onFinish}
						ref={this.formModalOrdenTrabajo} >

						{this.state.view === 'input' ? <Row align="center">
							<Col xs={24} md={12} className="mb-2">
								<Form.Item
									name="signature" //-------------------------------------------------------signature
									label="Firma"
									rules={[{
										required: true,
										message: "Por favor, ingrese su nombre",
									}]}
								>
									<Input 
										placeholder="Escribe tu nombre"
										onChange={elm=>{
										const ctx = this.canvas.current.getContext("2d")
										ctx.clearRect(0, 0, this.state.canvasWidth, 400);
										ctx.font = "40px SafiarSgn"
										ctx.fillStyle = "black"
										ctx.textAlign = "center"; 
										ctx.fillText(elm.target.value,this.state.canvasWidth/2,70)
									}} />
								</Form.Item>
							</Col>
							<Col span={20} className="center">
								<div id="colC">	
									<canvas ref={this.canvas} width={this.state.canvasWidth} heigth={400} className="modal-canvas-signature"/>
								</div>
							</Col>
						</Row>
						:
						<Row align="center">
							<Col xs={24} md={22} className="center" className="modal-canvas-signature">
								<SignaturePad ref={ref => this.signaturePad = ref}/>
							</Col>
							<Col span={22}>
								<Button className="btn-gris" onClick={() => {  this.signaturePad.clear();  }}>
						            Clear
						        </Button>
						        <Button className="btn-gris" onClick={() => { 
						        	let data = this.signaturePad.toData();

									if (data) {
										data.pop(); // remove the last dot or line
										this.signaturePad.fromData(data);
								}}}>
						            Deshacer
						        </Button>
							</Col>
						</Row>}



						<Row style={{ textAlign: "center" }}>
							<Col span={24}>
								<Form.Item >
									<Button htmlType="submit" type="primary" className="btn-verde" >
										Guardar
									</Button>
								</Form.Item>
							</Col>
						</Row>
					</Form>
				</Spin>
			</Modal>
		)
	}
}



import React, { Component } from "react";
import { Button, Col, Form, Input, message, Modal, Row, Select, Spin, Typography } from 'antd';
import './../../../../Styles/modales.css'

import { IconCloseModal, IconArrowSelect } from '../../../Widgets/Iconos';

import { DeleteOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons'

// LoadingOutlined

const moment = require('moment');
const axios = require("axios").default;


const { Option } = Select;

const { Title, } = Typography;


export default class ModalUsuarios extends Component {

    formModaluser = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            form: {},
            casas: [],
            casa: {},

        }
    }


    /**
     * @methodOf ModalUsuarios
     *
     * @function componentDidUpdate
     * @description Se ejecuta cuando se inicia el component, declara en el header el session storage
     *
     * */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.loadCasas();
    }


    /**
    * @memberof ModalUsuarios
    *
    * @method   componentDidUpdate
    * @description  Si se va Editar un usuario va y busca la informacion de este
    *
    **/
    componentDidUpdate(prevProps) {
        if (this.props.user_id !== prevProps.user_id && this.props.user_id !== undefined) {
            this.getUsuario(this.props.user_id)
        }

        if (this.props.visible !== prevProps.visible && this.props.visible === false) {
            this.loadCasas();
            this.setState({ casa: undefined })
        }
    }


    /**
    * @memberof ModalUsuarios
    *
    * @method   onFinish
    * @description  Metodo que se ejecuta al guardar el formulario
    *
    **/
    onFinish = (values) => {
        if (this.props.modalTitle === 'Editar') {
            this.updateUsuario(values);
        } else {
            this.addUsuario(values);
        }
    }


    /**
    * @memberof ModalUsuarios
    *
    * @method   addUsuario
    * @description  Guarda el usuario en la BD
    *
    **/
    addUsuario = (values) => {
        axios.post('/usuarios/add', {
            nombre: values.nombre,
            apellidos: values.apellidos,
            email: values.email,
            casa_id: values.casa_id,
            password: values.password,
            tipo: this.props.tipoUsuario,
        }).then(response => {
            this.props.closeMethod()
            message.success('El usuario de agrego correctamente')
        }).catch(error => {
            console.log(error)
            message.error('No se pudo agregar el usuario')
        })
    }


    /**
    * @memberof ModalUsuarios
    *
    * @method   getUsuario
    * @description  Trae la informacion de usuario
    *
    **/
    getUsuario = (id) => {
        axios.get('/usuarios/get', {
            params: { _id: id }
        }).then(response => {
            this.setState({
                casa: {
                    nombre: response.data.data.casa_id?.nombre,
                    _id: response.data.data.casa_id?._id,
                }
            })
            this.formModaluser.current.setFieldsValue({
                nombre: response.data.data.nombre,
                apellidos: response.data.data.apellidos,
                email: response.data.data.email,
                casa_id: response.data.data.casa_id?._id,
            })
        }).catch(error => {
            console.log(error)
            message.error('No se pudo cargar el usuario')
        })
    }


    /**
    * @memberof ModalUsuarios
    *
    * @method   updateUsuario
    * @description  Actualiza la informacion de usuario
    *
    **/
    updateUsuario = (values) => {
        axios.post('/usuarios/update', {
            _id: this.props.user_id,
            nombre: values.nombre,
            apellidos: values.apellidos,
            email: values.email,
            casa_id: values.casa_id,
        }).then(response => {
            this.props.closeMethod()
            message.success('El usuario se actualizo correctamente')
        }).catch(error => {
            console.log(error)
            message.error('No se pudo agregar el usuario')
        })
    }


    /**
   * @memberof ModalUsuarios
   *
   * @method   updateUsuario
   * @description  Actualiza la informacion de usuario
   *
   **/
    loadCasas = (search) => {
        axios.get('/casas/list', {
            params: {
                disponibles: true,
                search: search,
            }
        }).then(response => {
            this.setState({ casas: response.data.data.itemsList })

        }).catch(error => {
            console.log(error)
            message.error('No se cargaron las casas')
        })
    }


    /**
    * @memberof ModalUsuarios
    *
    * @method   selectOptions
    * @description  Metodo que reciebe un array de elementos para devolver un array de opciones para un select,
    * ademas de agrear al arreglo el elemento a editar
    *
    **/
    selectOptions = (array, addElemnt) => {

        let arr = [];
        if (array) {
            array.forEach(function ({ _id, nombre }, index) {
                arr.push(<Option value={_id}> {nombre}</Option>)
            })
        }

        if (array && addElemnt) {
            if (!array.some(element => element._id === addElemnt._id)) {
                arr.push(<Option value={addElemnt._id}> {addElemnt.nombre}</Option>)
            }
        }
        return arr
    }


    render() {
        return (
            <Modal
                visible={this.props.visible}
                onCancel={this.props.closeMethod}
                title={null}
                footer={null}
                closable={false}
                className="modal-form"
                destroyOnClose={true}
                zIndex={1000}
                width={500}
            >
                <div className="modal-header">
                    <Button type="ghost" title="Cerrar" className="btn-close-modal" onClick={this.props.closeMethod}>
                        <IconCloseModal />
                    </Button>
                    <Title level={3} className="modal-title">{this.props.modalTitle} Usuario</Title>
                </div>
                <Spin spinning={this.state.loading}>

                    <Form
                        layout="vertical"
                        className="mdl-form frm-documentos"
                        name="formulario-tajo"
                        onFinish={this.onFinish}
                        ref={this.formModaluser}
                    >

                        <Row align="center">
                            <Col xs={20}>
                                <Form.Item
                                    label="Nombre"
                                    name="nombre" //<--------------------------------------- nombre
                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese el nombre"
                                    }]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row align="center">
                            <Col xs={20}>
                                <Form.Item
                                    label="Apellidos"
                                    name="apellidos" //<--------------------------------------- Apellidos
                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese los Apellidos"
                                    }]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        {
                            this.props.tipoUsuario !== 1 ? <Row align="center">
                                <Col xs={20}>
                                    <Form.Item
                                        label="Casa"
                                        name="casa_id" //<--------------------------------------- casa_id
                                        rules={[{
                                            required: true,
                                            message: "Por favor, ingrese la casa"
                                        }]}
                                    >
                                        <Select
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={false}
                                            onSearch={search => this.loadCasas(search)}
                                        >

                                            {this.selectOptions(this.state.casas, this.state.casa)}
                                        </Select>
                                    </Form.Item>
                                    <IconArrowSelect />
                                </Col>
                            </Row> : null
                        }
                        <Row align="center">
                            <Col xs={20}>
                                <Form.Item
                                    label="Email"
                                    name="email" //<--------------------------------------- email
                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese el Email",
                                        type: "email"
                                    }]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>

                        {this.props.modalTitle !== 'Editar' ? <Row align="center">
                            <Col xs={20}>
                                <Form.Item
                                    label="Password"
                                    name="password"
                                    rules={[{
                                        required: true,
                                        message: 'Por favor, ingrese una contraseña (min. 4 caracteres)',
                                        min: 4
                                    }]}
                                >
                                    <Input.Password />
                                </Form.Item>
                            </Col>
                        </Row> : ''}

                        <Row style={{ textAlign: "center" }}>
                            <Col span={24}>
                                <Form.Item>
                                    <Button htmlType="submit" type="primary" className="btn-green">
                                        Guardar
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        )
    }
}

import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Select, message, InputNumber, Upload } from 'antd';
import { IconCloseModal } from '../../Widgets/Iconos';
import './../../../Styles/modales.css'

const axios = require('axios');
const { Title } = Typography;

class ModalAbonoCobros extends Component {

    formModalMantenimiento = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            loading: false,

        }
    }


    componentDidMount() {

        // if (this.props.cobro_mantenimiento_id)
        //     this.getAbono()

    }


    // getAbono = () => axios.get('/mantenimiento/get', {
    //     params: {
    //         _id: this.props.cobro_mantenimiento_id,
    //     }
    // })
    //     .then(({ data }) => {
    //         console.log('data', data)
    //         this.refForm.setFieldsValue({

    //         })
    //     })
    //     .catch(error => {
    //         message.error('No fue posible guardar')
    //     })


    saveAbono = (values) => {


        console.log('values, values', values)
        return axios.post('/abono/add', {
            pago_mantenimiento_id: this.props.cobro_mantenimiento_id,
            usuario_id: this.props.usuario_id,
            abono: values.abono,
            comprobantes: (Array.isArray(values.comprobantes)) ? values.comprobantes.map(({ name }) => name) : []
        })
            .then(({ data }) => {
                message.success('Abonado correctamente')
                this.props.onClose()
            })
            .catch(error => {
                message.error('No fue posible abonar.')
            })
    }



    /**
     *
     * @memberof ModalTransaccion
     *
     * @method normFile
     * @description Segun la lista de transacciones, declara el boton de aceptar como disponible segun si se está enviando un archivo.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    normFile = (e) => {

        console.log('e', e)

        const { file, fileList } = e;

        for (let x = 0; x < fileList.length; x++) {
            if (fileList[x].response) {
                fileList[x].name = fileList[x].response.filename

            }

            if (fileList[x].status !== "done" && fileList[x].response && !this.state.loading) {
                this.state.loading = true;
                // this.setState({ update: !this.state.update })
                break;
            }
        }

        if (file.status == "removed")
            this.removeFile((file?.response?.filename != undefined) ? file.response.filename : file.name);


        return e && e.fileList;
    };


    /**
     *
     * @memberof ModalCuenta
     *
     * @method removeFile
     * @description Elimina un archivo del WebService.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    removeFile = (image) => {
        axios.post("/voucher/delete", {
            filename: image
        })
            .then(res => {
                console.log("imagen removida con exito", res);
            })
            .catch(res => {
                console.log("imagen no se puedo remover", res);
            })
    };



    render() {
        return (
            [
                <Title level={4}>Agregar Pago</Title>,
                <Form
                    layout="vertical"
                    className="mdl-form"
                    onFinish={this.saveAbono}
                    style={{ width: '100%' }}
                >
                    <Row gutter={[16, 16]}>
                        <Col span={24} style={{ marginBottom: '2em' }}>
                            <Form.Item
                                label="Cantidad"
                                name="abono"
                                className=""
                                rules={
                                    [
                                        {
                                            required: true,
                                            message: "Por favor, ingrese la cantidad a abonar."
                                        }
                                    ]
                                }

                            >
                                <InputNumber
                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="Comprobantes"
                                name="comprobantes"
                                getValueFromEvent={this.normFile}
                                valuePropName="fileList">
                                <Upload.Dragger
                                    multiple={true}
                                    className="avatar-uploader"
                                    action={axios.defaults.baseURL + '/file/upload?real_name=1'}
                                    style={{ marginLeft: "0px" }}
                                >
                                    <p className="ant-upload-text">Haga click aquí o arrastre los comprobantes a subir.</p>
                                </Upload.Dragger>
                            </Form.Item>

                        </Col>

                    </Row>
                    <Row style={{ textAlign: "center" }}>
                        <Col span={24}>
                            <Form.Item>
                                <Button htmlType="submit" type="primary" className="btn-verde"  >
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>

            ]


        )
    }
}



export default function (props) {


    const { onClose, visible } = props
    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={null}
        closable={false}
        className={"modal-form "}
        destroyOnClose={true}
        zIndex={1000}
        width={580}
        okText="Guardar"
        cancelText="Salir"
    >
        <Row className="modal-header">
            <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={onClose} id="btn-modalPaymentClose">
                <IconCloseModal />
            </Button>
        </Row>
        <ModalAbonoCobros {...props} />
    </Modal>
}
import React, { Component, useContext } from 'react';
import { Card, Avatar, Badge, Button, Col, Row, Comment, Input, Typography, Tooltip, Modal, Upload, Progress } from 'antd'


import { FaFilePdf, FaImage, FaFileExcel, FaFilePowerpoint, FaFileAlt, FaTimes } from 'react-icons/fa'




import SocketIOFileClient from 'socketio-file-upload';
import ifIsImage from 'if-is-image';

//componentes

import { IconAttach, IconImage, IconSend } from '../../Widgets/Iconos'
import Logged from '../../../Hooks/Logged';
import { SocketContext } from '../../../Hooks/Socket';
//css
import './../../../Styles/mensajes.css';
import axios from 'axios';


const moment = require("moment");
const { TextArea } = Input;
const { Title, Text } = Typography;


function renameFile(originalFile, newName) {
    return new File([originalFile], newName, {
        type: originalFile.type,
        lastModified: originalFile.lastModified,
    });
}


let rem_id = "", rem_casa = "";

/**
 *
 *
 * @class Mensajes
 * @extends {Component}
 * 
 * @description Componente de chat para hablar con parque esmeralda y vicerversa.
 */
class Mensajes extends Component {

    /**
     *
     *
     * @memberof Mensajes
     * 
     * @var state.data lista de mensajes agrupados por usuarios.
     * @var state.page pagina actual de los mensajes
     * @var state.limit Limite de elementos
     * @var state.usuarios Si es administrador, esta lista muestra los chats de los usuarios a los cuales se les puede enviar un mensaje
     * @var state.conectado Muestra si parque esmeralda está disponible o no
     * @var state.user_selected SI es adminstrador, es el usuario seleccionado.
     * @var state.loading Muestra el cargando.
     * 
     */
    state = {
        data: [],
        page: 1,
        limit: 25,
        search: null,

        usuarios: [],
        conectado: false,

        user_selected: null,
        loading: true,
        filesArray: [],
        filesDictionary: {},
        update: false
    };

    static contextType = SocketContext;

    loading = false

    scrollToBottomOption = true

    /***
     *
     * @methodOf Mensajes
     * @function componentDidMount
     * 
     * @description Iniciamos el chat, si se agrega al usuario a la lista de sockets exitomsanete, se encadena los emits iniciales
     */
    componentDidMount() {
        document.getElementById("messages-body").addEventListener('scroll', this.handleScroll, true);

        this.props.updateNavigationInformation('Mensajes');
        this.context.emit("start", sessionStorage.getItem("token"))
        this.context.removeListener('successful');
        this.context.on('successful', this.IO_start)

        // this.uploader = new SocketIOFileClient(this.context);

        this.uploader = new SocketIOFileClient(this.context);
        this.uploader.addEventListener("start", this.startUpload);

        this.uploader.addEventListener("progress", this.progressUpload);

        this.uploader.addEventListener("complete", this.completeUpload);
    }


    progressUpload = (event) => {
        let index = this.state.filesDictionary[event.file.name];
        console.log('event.bytesLoaded', event.bytesLoaded);
        console.log('event.file.size', event.file.size);
        this.state.filesArray[index - 1].progress = (parseInt(event.bytesLoaded) * 100) / parseInt(event.file.size);
        this.state.filesArray[index - 1].status = "progress";
        this.setState({ update: !this.state.update })
    }



    completeUpload = (event) => {
        console.log('complete', event);
        let index = this.state.filesDictionary[event.file.name];
        this.state.filesArray[index - 1].progress = 100;
        this.state.filesArray[index - 1].status = "complete";
        this.state.filesArray[index - 1].fileSend = event.file;
        this.setState({ update: !this.state.update })
    }

    startUpload = (event) => {
        console.log('start', event);

        let name = "";
        let fileSplit = event.file.name.split("_");
        for (let index = 0; index < (fileSplit.length - 1); index++) {
            name += ((index == 0) ? "" : '_') + fileSplit[index];
        }
        let extension = fileSplit[fileSplit.length - 1].split('.')[1];


        let index = this.state.filesArray.push({
            file: event.file,
            progress: 0,
            status: 'start',
            name,
            extension
        });

        this.state.filesDictionary[event.file.name] = index;
        this.setState({ update: !this.state.update })
    }

    uploadFile = ({ file }) => {
        let fileSplit = file.name.split(".");
        let rename = "";
        for (let index = 0; index < (fileSplit.length - 1); index++)
            rename += fileSplit[index]
        let fileName = rename + "_" + Math.random().toString(36).substring(2, 10) + "." + fileSplit[fileSplit.length - 1];
        this.uploader.submitFiles([renameFile(file, fileName)])
    }


    /***
     *
     * @methodOf Mensajes
     * @function componentDidMount
     * 
     * @description Removemos el event listener del messages
     */
    componentWillUnmount() {
        document.getElementById("messages-body").removeEventListener('scroll', this.handleScroll);
    }

    /***
     *
     * @methodOf Mensajes
     * @function IO_start
     * 
     * @description Inicio del socket, obtebemos los mensajes y si es admin obtebemos los usuarios
     */
    IO_start = () => {
        const { page, search, limit } = this.state;

        //Eliminamos estos metodos para volverlos a declarar
        this.context.removeListener('get_messages');
        this.context.removeListener('mensaje_nuevo');

        this.context.on('get_messages', this.IO_get_messages)
        this.context.on('mensaje_nuevo', this.IO_mensaje_nuevo)

        //Si es administrador, obtenemos los usuarios, caso contrario es un colono por lo que obteemos los mensajes normales
        if (this.props.user?.tipo == 1) {
            this.context.removeListener('get_users');
            this.context.on('get_users', this.IO_get_users)
            this.context.emit('get_users')
        } else {
            this.loading = true;
            this.context.emit('get_messages', { page, search, limit })
        }
    }

    /***
     *
     * @methodOf Mensajes
     * @function IO_get_users
     * 
     * @description Obtenemos los usuarios para ponerlos en las tarjetas. Solo funciona si eres Admin 
     */
    IO_get_users = (usuarios) => {
        //console.log('usuarios', usuarios);
        const { page, search, limit } = this.state;
        let m = usuarios[0]?._id;

        //console.log('m',m);

        this.setState({ user_selected: m })
        this.context.emit('get_messages', { page, search, limit, user_selected: m })
        this.setState({
            usuarios: usuarios.map(e => {
                e.count = 0;
                return e;
            })
        })
    }

    /**
     *
     * @methodOf Mensajes
     * @function IO_mensaje_nuevo
     * 
     * @description Cuando llegue un mensaje nuevo, se agrega a la lista de mensajes
     */
    IO_mensaje_nuevo = ({ mensaje, user_id }) => {
        //  console.log('MENSAJE NUEVO');
        //  console.log('mensaje',mensaje);
        //  console.log('user_id',user_id);
        let data = this.state.data;

        console.log(data);


        //Obtenemos el ultimo grupo de mensajes. Esto es importante porque dependiendo del usuario que envió el mensaje
        //Es si el nuevo mensaje se agregará al grupo o se creará un grupo nuevo para ese mensaje.
        let latestGroupOfMessagesIndex = data.length - 1;
        let latestGroupOfMessages = data[latestGroupOfMessagesIndex]

        // SI es usuario normal, y el usuario seleccionado es el mismo de lque envió el mensaje, 
        if (this.props.user.tipo == 2 || this.state.user_selected == user_id) {

            //Intenamos agregar el mensaje al grupo de mensajes correspondiente.
            if (latestGroupOfMessages) {

                //Obtebemos el index del último mensaje0
                let latestMessageIndex = latestGroupOfMessages.length - 1

                ///Y obtenemos el utlimo mensaje
                let latestMessage = latestGroupOfMessages[latestMessageIndex]

                // Si hay un ultimo mensaje y si el tipo del ultimo mensaje se agrega, caso contrario, se crea un nuevo grupo
                if (latestMessage) {
                    if (latestMessage.owner == mensaje.owner)
                        data[latestGroupOfMessagesIndex].push(mensaje)
                    else
                        data.push([mensaje])
                }
            } else //Data mensaje, 
                data = [[mensaje]]
            this.setState({ data: [...data] })
            this.scrollToBottom()
        }


        ///Si es usuario admin y el mensaje que llegó no es del selecccionado, se agrega al contador del usuario correspondiente.
        if (this.props.user.tipo == 1 && this.state.user_selected != user_id) {
            let usuarios = this.state.usuarios;
            let findUser = usuarios.findIndex(e => user_id?.toString() == user_id)
            if (findUser !== -1) {
                usuarios[findUser].count = parseInt(usuarios[findUser].count) + 1
                this.setState({ usuarios })
            }
        }
    }




    /**
     *
     * @methodOf Mensajes
     * @function IO_get_messages
     * 
     * @description Obtenemos los mensajes completos 
     */
    IO_get_messages = ({ conectado, mensajes }) => this.setState(state => {
        let messagesList = []
        let content = []
        let tempOwner = null;


        // console.log('mensajes',mensajes);
        for (let index = 0; index < mensajes.list.length; index++) {
            let mensaje = mensajes.list[index];
            let { owner } = mensaje;

            if (tempOwner == null) {
                tempOwner = owner
                mensaje.primero = true
            }

            if (tempOwner != owner) {
                tempOwner = owner
                messagesList.push([...content.reverse()])
                content = [mensaje]
            } else {
                content.push(mensaje)
            }


            if ((mensajes.list.length - 1) == index && tempOwner == owner) {
                messagesList.push([...content.reverse()])
            }
        }
        state.data = [...messagesList.reverse()]

        state.page = mensajes.page;
        state.limit = mensajes.limit;
        this.loading = false

        //Si es scrooll top, scrolleamos hacia arriba.
        if (this.scrollToBottomOption === true) {
            setTimeout(this.scrollToBottom, 250)
        }
        // 

        return state;
    })



    /**
     *
     * @methodOf Mensajes
     * @function onKeyDownPress
     * 
     * @description Se ejecuta al dar enter, enviamos el mensaje y limpiamos el input
     * 
     * */
    onKeyDownPress = (event) => {


        console.log('event.key', event.key);
        if (event.key === 'Enter' && ((event.target.value && event.target.value != null && event.target.value != "") || this.state.filesArray.length > 0)) {

            //console.log('this.state.user_selected', this.state.user_selected);
            this.context.emit('new_message', {
                message: event.target.value,
                user_id: this.state.user_selected,
                files: this.state.filesArray.map(event => event.fileSend.name)
            })
            this.inputRef.setState({ value: '' })
            this.setState({ filesArray: [], filesDictionary: {} })
        }

    }




    /**
     *
     * @methodOf Mensajes
     * @function onKeyDownPress
     * 
     * @description Se ejecuta al dar enter, enviamos el mensaje y limpiamos el input
     * 
     * */
    onPress = (event) => {

        this.context.emit('new_message', {
            message: event.target.value,
            user_id: this.state.user_selected,
            files: this.state.filesArray.map(event => event.fileSend.name)

        })
        this.setState({ filesArray: [], filesDictionary: {} })
        this.inputRef.setState({ value: '' })

    }


    /**
     *
     * @methodOf Mensajes
     * @function setUserConversation
     * 
     * @description Ponemos el usuario en el chat para hablar con el 
     * 
     * */
    setUserConversation = (user_id) => {
        console.log('user_id', user_id);

        const { page, search, limit } = this.state;
        this.setState({ user_selected: user_id })

        let usuarios = this.state.usuarios;
        let findUser = usuarios.findIndex(e => user_id?.toString() == user_id)
        if (findUser !== -1) {
            usuarios[findUser].count = 0
            this.setState({ usuarios })
        }

        this.context.emit('get_messages', { page, search, limit, user_selected: user_id })
    }


    /**
     *
     * @methodOf Mensajes
     * @function scrollToBottom
     * 
     * @description Scrolleamos el chat al final
     * 
     * */
    scrollToBottom = () => {
        this.scrollToBottomOption = true;
        const messages_body = document.getElementById("messages-body");
        if (messages_body !== null && messages_body !== undefined && messages_body !== null)
            messages_body.scrollTop = messages_body.scrollHeight;
    }


    /**
     *
     * @methodOf Mensajes
     * @function handleScroll
     * 
     * @description Se ejecuta cuando se scrollea el chat, cargamos mas mensajes antiguos.
     * 
     * */
    handleScroll = (event) => {
        let { scrollTop } = document.getElementById("messages-body");
        let { page, limit, search } = this.state;
        if (scrollTop == 0 && this.loading == false) {

            this.loading = true;
            this.scrollToBottomOption = false;
            page = page + 1
            this.context.emit('get_messages', { page, search, limit, user_selected: this.state.user_selected })
            //console.log('scrollTop',scrollTop);

        }
    }


    deleteFile = (file) => {
        let index = this.state.filesDictionary[file];
        console.log('files', file);
        this.state.filesArray.splice(index, 1);
        this.context.emit("delete_file", file)
        this.setState({ update: !this.state.update })
    }


    messagesContentReference = React.createRef();

    render() {

        let { user } = this.props;
 

        return (
            <section className="sec-mensajes p-1">


                <Row className="">
                    <Col xs={24} sm={{ span: user.tipo === 2 ? 0 : 24 }} xl={8} xxl={6} className="">
                        {(this.props.user.tipo == 1) ?
                            this.state.usuarios.map(({ _id, nombre, apellidos, mensaje, count }) => {
                                if (apellidos == undefined) { apellidos = "" }

                                return <div
                                    className={"cnt-card-mensaje " + ((this.state.user_selected == _id) ? " selected" : null)}

                                    onClick={e => this.setUserConversation(_id)}
                                >
                                    <Card
                                        className="card-msg "
                                        style={{ width: '100%' }}
                                        hoverable
                                        title={<Comment
                                            author={<span style={{ fontSize: '18px', color: '#191D38', position: 'relative', top: 12 }}>{nombre + "  " + apellidos}</span>}
                                            avatar={
                                                <Avatar
                                                    alt={nombre + "  " + apellidos}
                                                    className="avatarGlobal"
                                                >
                                                    {nombre}
                                                </Avatar>
                                            }
                                        />}
                                    >
                                        <Avatar size="small"
                                            style={{ backgroundColor: "#ff4d4f", position: "absolute", right: -5, top: -5 }}>{count}</Avatar>
                                        <p className="abstract-msg ">{(mensaje !== null && mensaje !== undefined) ? mensaje.mensaje : ""}</p>
                                    </Card>
                                </div>
                            })
                            :
                            <div className="cnt-card-mensaje selected">
                                <Card
                                    className="card-msg "
                                    style={{ width: '100%' }} title={
                                        <div>
                                            <Avatar
                                                style={{ backgroundColor: "#03E033", width: "55px", height: "55px", display: 'inline-block' }}
                                                src={"/images/portadaPE.jpg"}
                                                size="large" />
                                            <Title style={{ display: 'inline-block' }} level={5} className="m-0">Parque Esmeralda</Title>
                                        </div>
                                    }>
                                    <p>Habla con Parque Esmeralda</p>
                                </Card>
                            </div>}

                    </Col>

                    <Col xs={24} xl={16} xxl={18} className="">
                        <div className="cnt-Mensajes">
                            <Row className="cnt-Mensajes-header">
                                <Col>
                                    <Avatar
                                        src={"/images/portadaPE.jpg"}
                                        size="large" />
                                </Col>
                                <Col>
                                    <Title level={2}>Parque Esmeralda</Title>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <div className="cnt-Mensajes-body "
                                        ref={messageContentReference => { this.messageContentReference = messageContentReference; }}
                                        id="messages-body">

                                        {this.state.data.map(e => {
                                            return <Row className="card-msj">
                                                <Card className="cnt-msj ">
                                                    <Row>
                                                        {e.map(comment => {

                                                            const { owner, remitente_id, mensaje, fecha, primero, archivos, casa } = comment;
                                           
                                                            let casaNombre = "";
                                                            if (rem_id == "" || rem_id != this.state.user_selected) {
                                                                rem_id = remitente_id?._id

                                                                if (casa != undefined) {
                                                                    casaNombre = casa.nombre;
                                                                    rem_casa = casa.nombre;
                                                                }
                                                            }

                                                            if (rem_id == user._id) {
                                                                casaNombre = "";
                                                                rem_casa = "";
                                                            }

                                                       

                                                            if (remitente_id != undefined && remitente_id.nombre != undefined && remitente_id.apellidos != undefined) {

                                                                let autor = remitente_id.nombre + " " + remitente_id.apellidos;

                                                                if (remitente_id._id != user._id && user.tipo == 1 && rem_casa !="" ) {
                                                                    autor = <div>
                                                                    
                                                                        <h1>{autor}</h1>
                                                                        <h2>{rem_casa}</h2>

                                                                    </div>
                                                                    //autor = autor + " - " + rem_casa 
                                                                }

                                                                

                                                                return <Comment
                                                                    style={{ width: '100%' }}


                                                                    // id={((primero == true) ? "primero-elemento" : undefined)}
                                                                    className={(owner == true) ? "owner" : null}
                                                                    // actions={actions}

                                                                    actions={<a> {casaNombre} </a>}
                                                                    author={autor}
                                                                    avatar={
                                                                        <div>

                                                                            <Avatar
                                                                                size="large"
                                                                                //src={ remitente_id.avatar }
                                                                                alt={remitente_id.nombre + " " + remitente_id.apellidos}
                                                                                className={(remitente_id.tipo === 1) ? "avatarPE" : "avatarGlobal"}
                                                                                src={(remitente_id.tipo === 1) ? "/images/portadaPE.jpg" : ""}
                                                                                style={{ paddingTop: "5px" }}
                                                                            >
                                                                                {remitente_id.nombre}
                                                                            </Avatar>
                                                                        </div>
                                                                    }
                                                                    content={<div>
                                                                        {mensaje} <br></br>
                                                                        {archivos.map(filename => {
                                                                            let name = "";
                                                                            let fileSplit = filename.split("_");
                                                                            for (let index = 0; index < (fileSplit.length - 1); index++) {
                                                                                name += ((index == 0) ? "" : '_') + fileSplit[index];
                                                                            }
                                                                            let extension = fileSplit[fileSplit.length - 1].split('.')[1];


                                                                            let extensionImage = null;
                                                                            if (ifIsImage(filename)) {
                                                                                extensionImage = <FaImage style={{ marginRight: '0.5em' }} />
                                                                            }
                                                                            else if (extension == "pdf" || extension == "PDF") {
                                                                                extensionImage = <FaFilePdf style={{ marginRight: '0.5em' }} />
                                                                            }
                                                                            else {
                                                                                extensionImage = <FaFileAlt style={{ marginRight: '0.5em' }} />
                                                                            }


                                                                            if (ifIsImage(`${name}.${extension}`))
                                                                                return <a className="archivito">
                                                                                    <img src={axios.defaults.baseURL + '/upload/' + filename} style={{ maxWidth: '200px', margin: '1em' }} />
                                                                                </a>
                                                                            else
                                                                                return <>
                                                                                    <br />
                                                                                    <a target="_blank" download={true} href={axios.defaults.baseURL + '/upload/' + filename} className="archivito">
                                                                                        {extensionImage}{`${name}.${extension}`}
                                                                                    </a>
                                                                                </>
                                                                        })}
                                                                    </div>}
                                                                    datetime={
                                                                        <Tooltip title={moment(fecha).format('YYYY-MM-DD HH:mm:ss')}>
                                                                            <span>{moment(fecha).fromNow()}</span>
                                                                        </Tooltip>
                                                                    }
                                                                />

                                                            } else {

                                                                var autor = (remitente_id != undefined) ? remitente_id.nombre : "User";

                                                                if (remitente_id?._id != user?._id && user?.tipo == 1 && rem_casa !="" ) {
                                                                    //autor = autor + " - " + rem_casa 

                                                                    autor = <div>
                                                                        <h1>{autor}</h1>
                                                                        <h2>{rem_casa}</h2>
                                                                    </div>
                                                                }


                                                                return <Comment
                                                                    style={{ width: '100%' }}
                                                                    // id={((primero == true) ? "primero-elemento" : undefined)}
                                                                    actions={<a> {casaNombre} </a>}
                                                                    className={(owner == true) ? "owner" : null}
                                                                    author={autor}

                                                                    avatar={
                                                                        <Avatar
                                                                            className="avatarGlobal"
                                                                            style={{ paddingTop: "5px" }}
                                                                            size="large"

                                                                        >
                                                                            {(remitente_id != undefined) ? remitente_id.nombre : "User"}
                                                                        </Avatar>
                                                                    }
                                                                    content={<div>
                                                                        {mensaje} <br></br>
                                                                        {archivos.map(filename => {
                                                                            let name = "";
                                                                            let fileSplit = filename.split("_");
                                                                            for (let index = 0; index < (fileSplit.length - 1); index++) {
                                                                                name += ((index == 0) ? "" : '_') + fileSplit[index];
                                                                            }
                                                                            let extension = fileSplit[fileSplit.length - 1].split('.')[1];


                                                                            let extensionImage = null;
                                                                            if (ifIsImage(filename)) {
                                                                                extensionImage = <FaImage style={{ marginRight: '0.5em' }} />
                                                                            }
                                                                            else if (extension == "pdf" || extension == "PDF") {
                                                                                extensionImage = <FaFilePdf style={{ marginRight: '0.5em' }} />
                                                                            }
                                                                            else {
                                                                                extensionImage = <FaFileAlt style={{ marginRight: '0.5em' }} />
                                                                            }


                                                                            if (ifIsImage(`${name}.${extension}`))
                                                                                return <a className="archivito">
                                                                                    <img src={axios.defaults.baseURL + '/upload/' + filename} style={{ maxWidth: '200px', margin: '1em' }} />
                                                                                </a>
                                                                            else
                                                                                return <>
                                                                                    <br />
                                                                                    <a target="_blank" download={true} href={axios.defaults.baseURL + '/upload/' + filename} className="archivito">
                                                                                        {extensionImage}{`${name}.${extension}`}
                                                                                    </a>
                                                                                </>
                                                                        })}
                                                                    </div>}
                                                                    datetime={
                                                                        <Tooltip title={moment(fecha).format('YYYY-MM-DD HH:mm:ss')}>
                                                                            <span>{moment(fecha).fromNow()}</span>
                                                                        </Tooltip>
                                                                    }
                                                                />
                                                            }


                                                        })}
                                                    </Row>
                                                </Card>
                                            </Row>
                                        })}
                                    </div>
                                </Col>
                            </Row>
                            <Row>

                                <Col span={24}>
                                    <div className={this.state.filesArray.length > 0 ? "filesList" : null}>

                                        {this.state.filesArray.map(file => {

                                            let extensionImage = null;
                                            if (ifIsImage(file.file.name)) {
                                                extensionImage = <FaImage />
                                            }
                                            else if (file.extension == "pdf" || file.extension == "PDF") {
                                                extensionImage = <FaFilePdf />
                                            }
                                            else {
                                                extensionImage = <FaFileAlt />
                                            }

                                            let name = (file.name.length > 10) ? (file.name.slice(0, 10) + '... ') : file.name;
                                            return <div className="file-container">
                                                <div className="file">
                                                    {(file.status != "complete") ? <Progress percent={parseInt(file.progress)} size="small" style={{
                                                        position: 'absolute',
                                                        top: '36%',
                                                        left: '5px',
                                                        width: 'calc(100% - 10px)',
                                                    }} /> : extensionImage}
                                                    <Button onClick={() => this.deleteFile(file.file.name)} className="buttom-close"><FaTimes /></Button>
                                                </div>
                                                <span style={{
                                                    display: 'block',
                                                    margin: '0 auto',
                                                    textAlign: ' center',
                                                    left: 0,
                                                    color: '#7b7373',
                                                    fontSize: 12

                                                }}>{name + '.' + file.extension}</span>
                                            </div>
                                        })}
                                    </div>
                                    <div className="cnt-Mensajes-opciones">
                                        <Avatar
                                            style={{ paddingTop: "5px" }}
                                            className={(user.tipo === 1) ? "" : "avatarGlobal"}
                                            src={(user.tipo === 1) ? "/images/portadaPE.jpg" : ""}
                                        >
                                            {user.nombre}
                                        </Avatar>
                                        <Input
                                            ref={ref => this.inputRef = ref}
                                            onKeyDown={this.onKeyDownPress}
                                            placeholder="Escribe tu mensaje aqui..." />

                                        <div className="cnt-msj-opciones">

                                            <Button title="Adjuntar archivo">
                                                <Upload
                                                    multiple={true}
                                                    showUploadList={false}
                                                    customRequest={this.uploadFile}

                                                >
                                                    <IconAttach />
                                                </Upload>
                                            </Button>
                                            <Button title="Enviar" onClick={() => this.onPress({ target: { value: this.inputRef.state.value } })}>
                                                <IconSend />
                                            </Button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>


            </section>
        );
    }
}

export default function (props) { return <Mensajes {...props} user={useContext(Logged)} /> }

//(props => )

import React, { Component, useState } from 'react';
import { BrowserRouter, Redirect, Route } from 'react-router-dom';
import { Layout, ConfigProvider } from 'antd';
import './Styles/Global.css';
 import './../node_modules/antd/dist/antd.css'

import locale from 'antd/lib/locale/es_ES';

import PublicRoutes from "./Routes/PublicRoutes";
import AuthVerify from "./Routes/Middleware/AuthVerify";
import AdminRoutes from "./Routes/AdminRoutes";
import ColonoRoutes from "./Routes/ColonoRoutes";
import axios from 'axios'

import Logged from './Hooks/Logged'

import { SocketContext, socket } from './Hooks/Socket';


class App extends Component {


    constructor(props) {
        super(props);
        this.state = {
            user: {},
        }
    }


    /**
    * @memberof App
    * @method   setUser
    * @description Pone en el state el usuario logeado, para usuarlo en el contexto
    *
    **/
    setUser = (user) => {
        this.setState({ user })
    };


    render() {
        const { setUser, } = this;
        const { user } = this.state;

        let props = { setUser, user, }

        return (
            <BrowserRouter>
                <ConfigProvider locale={"es"}>
                    <SocketContext.Provider value={this.props.socket}>
                        <Logged.Provider value={user}>
                            <Layout className="layout">
                                <Route path='/admin'>
                                    <AdminRoutes {...props} />
                                </Route>
                                <Route path='/colono'>
                                    <ColonoRoutes {...props} />
                                </Route>
                                <PublicRoutes {...props} />
                            </Layout>
                        </Logged.Provider>
                    </SocketContext.Provider>
                </ConfigProvider>
            </BrowserRouter>
        );
    }
}

export default (App);

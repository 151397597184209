import React, { Component } from "react";
import { Upload, Button, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { FaPlus, FaPlusSquare } from "react-icons/fa";
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import axios from 'axios';

class MyUpload extends  Component {


	constructor(props){
		super(props)
		this.state = {
			fileList: [],
		}
	}

	componentDidUpdate(prevProps){
		if(this.props.fileList !== undefined && this.props.fileList !== prevProps.fileList){
			this.setState({fileList: [{
				name:this.props.fileList,
				url:`${axios.defaults.baseURL}/upload/${this.props.fileList}`
			}]})
		}
	}

	/**
    * @memberof Upload
    *
    * @method   handleChange
    * @description  limita el numero de archivos a subir a uno
    *
    **/
	handleChange = info => {
		if(info.fileList.length === 0){
			this.setState({fileList: info.fileList})
		}
		if(info.fileList.length === 1){
			this.setState({fileList: info.fileList})
		}
		if(info.fileList.length > 1){
			info.fileList.splice(1,1);
			this.setState({fileList: info.fileList})
		}
	}


 	/**
    * @memberof Upload
    *
    * @method   uploadFile
    * @description  Guarda el archivo en la BD y regresa la infomacion que retorna el servidor 
    * para ser utilizada despues en el modalArchivo
    *
    **/
  	uploadFile = (file) => {
  		if(this.state.fileList.length === 0){
	  		let formData = new FormData();
	  		formData.append("file", file);
	  		axios.post('/file/upload',formData,{ 
				headers: { 
					Authorization: sessionStorage.getItem('token'),
					'Content-Type': 'multipart/form-data', 
				} 
			})
	  		.then(response => {
	  			let fileList = [{
	  				name: response.data.name,
	  				filename: response.data.filename,
	  				type: response.data.type,
	  				status: 'done',
	  			}]
	  			this.setState({ loading: false, fileList: fileList,  })
	  			this.props.setFile(fileList[0])
	  		}).catch(error =>{
	  			console.log(error)
	  			file.status = 'error'
	  			this.props.setFile(file)
	  			message.error('No se pudo subir el archivo')
	  		})
  		}else message.error('No se puede subir mas de un archivo')
  	} 

	render() {
		const props = {
	  		action: this.uploadFile,
	  		onChange: this.handleChange,
			multiple: false,
			accept: 'image/*, .pdf'
		};
		return (
			<Upload {...props} fileList={this.state.fileList}  className="avatar-uploader">
				<Button icon={ <PlusOutlined /> }>Upload</Button>
	  		</Upload>
		);
	}
}


export default MyUpload 
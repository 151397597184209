import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Select, message, List, Tag, Upload } from 'antd';

// 
import { IconCloseModal, IconArrowSelect } from '../../Widgets/Iconos';

import { Paypear } from '../../Widgets/paypear';

import './../../../Styles/modales.css'

import TextArea from "antd/lib/input/TextArea";

const axios = require('axios');
const moment = require('moment');

const { Title, Text } = Typography;

const { Option } = Select;


class ModalComprobantes extends Component {

    formModalMantenimiento = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            abono: null,
        }
    };

    componentDidMount() {

        this.getAbono()

    }

    getAbono = () => axios.get('/abono/get', {
        params: {
            abono_id: this.props._id,
        }
    })
        .then(({ data }) => {
            console.log('data', data.data)
            this.setState({ abono: data.data })
        })
        .catch(error => {
            message.error('No fue posible guardar')
        })

    /**
    * @memberof OrdenesTrabajo
    *
    * @method   spanEstatus
    * @description retorna el span dependiendod el estatus
    *
    **/
    spanEstatus = (estatus) => {
        switch (estatus) {
            case 1:
                return <Tag color="#fadb14">Pago Necesario </Tag>
                break;
            case 2:
                return <Tag color="#722ed1">Procesando Pago </Tag>
                break;
            case 3:
                return <Tag color="#52c41a">Pagado </Tag>
                break;
            case 4:
                return <Tag color="#f5222d">Moratorio </Tag>
                break;
        }
    }


    render() {
        const { id, accionModal, visible } = this.props;
        return (
            <Row>
                <Col span={24} >
                    <Title level={3} style={{ textAlign: "center", marginTop: "-50px" }}>Lista de Pagos Realizados</Title>
                </Col>
                <Col span={24} >
                    <List
                        itemLayout="horizontal"
                        dataSource={(Array.isArray(this.state.abono?.abonados)) ? this.state.abono?.abonados : []}
                        header={
                            <Row style={{ width: '100%' }}>
                                <Col xs={24} md={8} className="center">
                                    <Text>Mes Pagado</Text>
                                </Col>
                                <Col xs={24} md={8} className="center">
                                    <Text>Estatus del Mes</Text>
                                </Col>
                                <Col xs={24} md={8} className="center">
                                    <strong>Cantidad Pagada</strong>
                                </Col>
                            </Row>
                        }
                        renderItem={({ cantidad, pago_mantenimiento }) => (

                            <List.Item
                            // actions={[<a key="list-loadmore-edit">edit</a>, <a key="list-loadmore-more">more</a>]}
                            >
                                <Row style={{ width: '100%', marginTop: '2em' }}>
                                    {/* asfda */}

                                    <Col xs={24} md={8} className="center">
                                        <Text>{moment(pago_mantenimiento.fecha).format('MMMM-YYYY')}</Text>
                                    </Col>
                                    <Col xs={24} md={8} className="center">
                                        {this.spanEstatus(pago_mantenimiento.estatus)}
                                    </Col>
                                    <Col xs={24} md={8} className="center">
                                        <strong>$ {cantidad.toMoney()} MXN</strong>
                                    </Col>
                                </Row>
                            </List.Item>
                        )}

                        footer={<Row style={{ width: '100%', marginTop: '2em' }}>
                            <Col xs={24} md={8} className="center">
                                <strong>Total</strong>
                            </Col>
                            <Col xs={24} md={8}>
                            </Col>
                            <Col xs={24} md={8} className="center">
                                <strong>$ {this.state.abono?.abono.toMoney()} MXN</strong>
                            </Col>
                        </Row>}
                    />

                </Col>
            </Row>
        )
    }
}



export default function (props) {


    const { onClose, visible } = props
    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={null}
        closable={false}
        className={"modal-form "}
        destroyOnClose={true}
        zIndex={1000}
        width={800}
        okText="Guardar"
        cancelText="Salir"

    >
        <Row className="modal-header">
            <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={onClose} id="btn-modalPaymentClose">
                <IconCloseModal />
            </Button>
        </Row>
        <ModalComprobantes {...props} />
    </Modal>
}
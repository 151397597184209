import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Button, Col, Row, List, Input, Card, Typography, Form, DatePicker, InputNumber, Modal, message, Spin, Pagination, Checkbox, Tooltip, Dropdown, Menu, Badge, Tabs } from 'antd'



import { ExclamationCircleOutlined, DollarTwoTone } from '@ant-design/icons';


import { FaDollarSign } from 'react-icons/fa'

import Ticket from '../../Colono/Mantenimiento/TicketMantenimiento'

import axios from 'axios';
//componentes
import { IconDownload, IconAttach, IconImage, IconSend, IconPlus, IconMenu, IconMenuDots } from '../../Widgets/Iconos'
//cssimport './../../../Styles/mantenimiento.css';
import ModalListaMeses from './ModalListaMeses.js';

import ModalListaAbonos from './ModalListaAbonos';
import ModalAbono from './ModalAbono';
import moment from 'moment';
import 'moment/locale/es'

const { Title, Text } = Typography;
const { TabPane } = Tabs;

const { SubMenu } = Menu;

moment.locale('es')
var meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
export default class CobroMantenimiento extends Component {

	formCuota = React.createRef();

	constructor(props) {
		super(props)
		this.state = {
			cuotas: [],
			colono_id: this.props.match.params.id,
			casa_id: "",
			colono: {
				casa_id: {
					cuota_mto: 0
				}
			},
			mes_resgistrados: [],

			/*Paginador*/
			page: 1,
			limit: 10,
			pageCount: 0,
			itemCount: 1,



			abonos: {
				data: [],

				page: 1,
				limit: 10,

				pages: 0,
				total: 1,

			}
		}
	}


	/***
	 * @memberof Mantenimiento
	 *
	 * @function componentDidMount
	 */
	componentDidMount() {
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
		this.props.updateNavigationInformation('Mantenimiento');
		this.listMantenimientos();
		this.getAbonos()
	}


	/**
	* @memberof Mantenimiento
	*
	* @method   listMantenimientos
	* @description Busca la informacion del usuario colono y la lista de 
	*
	**/
	listMantenimientos = (page) => {
		this.setState({ loading: true })
		axios.get('/mantenimiento/list-user', {
			params: {
				_id: this.props.match.params.id,
				page: page
			}
		}).then(response => {
			if (response.data.data.user.casa_id == undefined) {
				message.error('No tiene ninguna casa asignada el usuario ' + response.data.data.user.nombre + " " + response.data.data.user.apellidos);
			}
			else {
				this.setState({
					casa_id: response.data.data.user.casa_id
				})
			}

			this.setState({
				colono: response.data.data.user,
				cuotas: response.data.data.pago_mantenimiento.itemsList,
				mes_resgistrados: response.data.data.cuotas_registradas,
				page: response.data.data.pago_mantenimiento.paginator.currentPage,
				itemCount: response.data.data.pago_mantenimiento.paginator.itemCount,
			})
			this.formCuota.current.setFieldsValue({
				cuota_mto: response.data.data.user.casa_id.cuota_mto,
				fecha: null,
			})
		}).catch(error => {
			console.log(error)
		}).finally(() => this.setState({ loading: false }))
	}



	getAbonos = ({
		// data = ,
		page,
		limit,
		// pages,
		// total
	} = this.state.abonos) => {
		this.setState({ loading: true })
		axios.get('/abonos', {
			params: {
				colono_id: this.props.match.params.id,

				page,
				limit,
			}
		})
			.then(({ data }) => {
				this.setState(state => {
					state.abonos.data = data.data.data
					state.abonos.limit = data.data.limit
					state.abonos.page = data.data.page
					state.abonos.total = data.data.total
					return state;
				})
				// console.log('response ', response)

			})
			.catch(error => {
				console.log(error)
			})
			.finally(() => this.setState({ loading: false }))
	}


	/**
   * @memberof Mantenimiento
   *
   * @method   onFinish
   * @description Busca la informacion del usuario colono y la lista de 
   *
   **/
	onFinish = (values) => {
		if (values.cuota_mto > 0) {
			Modal.confirm({
				title: (values.colonos === true) ? ('¿Quieres cargar esta cuota a todos los colonos?') : ('¿Quiere cargar una nueva cuota a: ' + this.state.colono.casa_id.nombre + '?'),
				icon: <ExclamationCircleOutlined />,
				content: '',
				onOk: () => this.addCuota(values),
			});
		}
		else {
			message.error("El monto a generar debe ser mayor a 0")
		}
	}


	/**
	* @memberof Mantenimiento
	*
	* @method   addCuota
	* @description Busca la informacion del usuario colono y la lista de 
	*
	**/
	addCuota = (values) => {
		axios.post('/mantenimiento/add', {
			colono_id: this.state.colono_id,
			fecha: values.fecha,
			monto: values.cuota_mto,
			colonos: values.colonos,

		}).then(response => {
			message.success('Cuota agregada con exito')
			this.listMantenimientos(this.state.page)
		}).catch(error => {
			console.log(error.response)

			if (error?.response?.status === 403)

				message.error(error.response.data.message)

			else {
				message.error('No se agrego la Cuota')
			}
		})
	}

	/**
	* @memberof OrdenesTrabajo
	*
	* @method   spanEstatus
	* @description retorna el span dependiendod el estatus
	*
	**/
	spanEstatus = (estatus) => {
		switch (estatus) {
			case 1:
				return <span className="spn-estatus warning"> Pago Necesario </span>
				break;
			case 2:
				return <span className="spn-estatus process"> Procesando Pago </span>
				break;
			case 3:
				return <span className="spn-estatus success"> Pagado </span>
				break;
			case 4:
				return <span className="spn-estatus error"> Moratorio </span>
				break;
		}
	}

	disabledDate = (current) => current < moment('2021-10-01', 'YYYY-MM-DD');

	updateStatus = (_id, estatus) => {
		if (estatus === 3)
			return this.setState({ visibleAbono: true, cobro_mantenimiento_id: _id })

		return axios.post('/mantenimiento/update', { _id, estatus })
			.then(response => {
				message.success('Estatus actualizado')
				this.listMantenimientos(this.state.page)
			})
			.catch(error => {
				message.error("No fue posible actualizar.")

			})
	}

	deletePago = (_id) => {
		Modal.confirm({
			title: "¿Quieres eliminar este pago?",
			icon: <ExclamationCircleOutlined />,
			onOk: () => axios.post('/mantenimiento/delete', { _id })
				.then(response => {
					message.success('Eliminado')
					this.listMantenimientos(this.state.page)
				})
				.catch(error => {
					message.error("No fue posible actualizar.")

				})
		})
	}

	/**
	 * @memberof Mantenimiento

	* @method   modalPagar
	* @description Abrir modal tipo pago.
	*
	**/
	modalTicket = async (element) => {

		this.setState({
			det_folio: element._id,
			det_concepto: meses[new Date(element.fecha).getMonth()],
			det_mes: element.fecha.substring(0, 10),
			det_estatus: element.estatus,
			det_monto: element.monto.toMoney()
		})

		setTimeout(() => {
			document.getElementById('btn-ticket').click();
		}, 100);
	}

	render() {

		return (
			<section className="section ">
				<Spin spinning={this.state.loading}>

					<Row style={{ padding: "2em 2em 1em 2em" }} width="100%" className="sec-mantenimiento">
						<Col xs={24} md={12} lg={8} className="cnt-card-manto">
							<Card className="card-manto">
								<Title level={1}>Generar Cobro</Title>
								<Form
									layout="vertical"
									className="mdl-form"
									ref={this.formCuota}
									onFinish={this.onFinish}
								>
									<Form.Item
										label="Mes"
										name="fecha"
										className=""
										rules={[{
											required: true,
											message: "Por favor, seleccione mes"
										}]}
									>
										<DatePicker picker="month"
											format="MM-YYYY"
											disabledDate={this.disabledDate}
											onPanelChange={this.onChange} />
									</Form.Item>

									<Form.Item
										label="Monto"
										name="cuota_mto"
										className=""
										rules={[{
											required: true,
											message: "Por favor, ingrese monto"
										}]}
										extra={
											<Tooltip title="Se agregará el cobro del mes seleccionado a todos los colonos que no lo tengan. No se agregará ningún cobro si ya tiene uno en el mes en cuestión.">
												<Form.Item
													name="colonos"
													className=""
													valuePropName="checked"

												>
													<Checkbox>Aplicar este mismo cobro a todos los colonos</Checkbox>
												</Form.Item>
											</Tooltip>
										}
									>
										<InputNumber min={0} />
									</Form.Item>
									<Row style={{ textAlign: "center" }}>
										<Col span={24}>
											<Form.Item>
												<Button htmlType="submit" type="primary" className="btn-verde" disabled={(this.state.casa_id == "")} >
													Guardar
												</Button>
											</Form.Item>
										</Col>
									</Row>
								</Form>
							</Card>
						</Col>
					</Row>


					<Tabs renderTabBar={(props, DefaultTabBar) => (
						<Row bottomOffset={80} className="section-header">
							<DefaultTabBar {...props} />
						</Row>
					)}>
						<TabPane tab={<Title level={5} className="page-sub-title" >Pagos de Mantenimiento</Title>} key="1">
							<Row className="section-content mantenimiento">
								<Col span={24}>
									<List
										itemLayout="horizontal"
										dataSource={this.state.cuotas}

										header={
											<Row style={{ width: '100%' }} className="">

												<Col xs={24} md={8} lg={3} className="center" >
													ID
												</Col>

												<Col xs={24} md={8} lg={4} className="center">
													Monto
												</Col>

												<Col xs={24} md={8} lg={4} className="center">
													Pagado
												</Col>

												<Col xs={24} md={8} lg={3} className="center">
													Restante
												</Col>

												<Col xs={24} md={8} lg={4} className="center">
													Estatus
												</Col>
												<Col xs={24} md={8} lg={4} className="center">
													Mes Correspondiente
												</Col>
												<Col span={2} className="center">
												</Col>
											</Row>
										}

										renderItem={(element, index) => {
											const { _id, estatus, fecha, monto, monto_pagado } = element
											return (
												<List.Item className="component-list-item ">
													<Card className="card-list">
														<Row style={{ width: '100%' }} className="">

															<Col xs={24} md={8} lg={3} className="center" >
																<Text>{_id}</Text>
															</Col>

															<Col xs={24} md={8} lg={4} className="center">
																<Text>$ {monto.toMoney(2)} MXN</Text>
															</Col>

															<Col xs={24} md={8} lg={4} className="center">
																<Text>$ {monto_pagado.toMoney(2)} MXN</Text>
															</Col>

															<Col xs={24} md={8} lg={3} className="center">
																<Text>$ {(monto - monto_pagado).toMoney(2)} MXN</Text>
															</Col>

															<Col xs={24} md={8} lg={4} className="center">
																{this.spanEstatus(estatus)}
															</Col>
															<Col xs={24} md={8} lg={4} className="center">
																<Text>{moment(fecha).format('MMMM-YYYY')}</Text>
															</Col>
															<Col span={2} className="center">
																<Dropdown overlay={<Menu>
																	<Menu.Item onClick={() => this.deletePago(_id)}>
																		Eliminar
																	</Menu.Item>
																	<SubMenu key="SubMenu" title="Estatus">
																		<Menu.Item key={1} onClick={() => this.updateStatus(_id, 1)}><Badge color="#fadb14" />Pago Necesario</Menu.Item>
																		<Menu.Item key={2} onClick={() => this.updateStatus(_id, 2)}><Badge color="#722ed1" />Procesando Pago</Menu.Item>
																		<Menu.Item key={3} onClick={() => this.updateStatus(_id, 3)}><Badge color="#52c41a" />Pagado</Menu.Item>
																		<Menu.Item key={4} onClick={() => this.updateStatus(_id, 4)}><Badge color="red" />Moratorio</Menu.Item>
																	</SubMenu>
																	{
																		(estatus == 3) ?
																			<Menu.Item onClick={() => this.modalTicket(element)}>
																				Ticket de Pago
																			</Menu.Item>
																			:
																			null
																	}
																	<Menu.Item onClick={() => this.setState({ cobro_mantenimiento_id: _id, visibleAbono: true })}>
																		Abonar
																	</Menu.Item>
																	<Menu.Item onClick={() => this.setState({ cobro_mantenimiento_id: _id, visibleListaAbonos: true })}>
																		Lista de Abonos Realizados
																	</Menu.Item>

																</Menu>} placement="bottomLeft" arrow>
																	<Button className="center btn btn-menu">
																		<IconMenu />
																	</Button>
																</Dropdown>
															</Col>


														</Row>
													</Card>
												</List.Item>
											)
										}}
									/>
								</Col>
							</Row>
							<Row style={{ margin: "1em 2em", paddingBottom: "3em" }}>
								<Pagination defaultCurrent={1} total={this.state.itemCount} onChange={(page) => this.listMantenimientos(page)} />
							</Row>
						</TabPane>
						<TabPane tab={<Title level={5} className="page-sub-title" >Abonos</Title>} key="2">
							<Row className="section-content mantenimiento">
								<Col span={24}>
									<List
										itemLayout="horizontal"
										dataSource={this.state.abonos.data}

										pagination={{
											onChange: (page, limit) => this.getAbonos({ page, limit }),
											showSizeChanger: true,
											pageSize: this.state.abonos.limit,
											total: this.state.abonos.total,
											current: this.state.abonos.page,
											hideOnSinglePage: false,
											showQuickJumper: false,
											className: "pagination-list"
										}}

										header={
											<Row style={{ width: '100%' }} className="">
												<Col xs={24} md={4} className="center">
													Creador por
												</Col>
												<Col xs={24} md={4} className="center">
													Residente
												</Col>
												<Col xs={24} md={4} className="center">
													Casa
												</Col>
												<Col xs={24} md={6} className="center">
													Fecha
												</Col>
												<Col xs={24} md={5} className="center">
													Abonado
												</Col>
											</Row>
										}

										renderItem={(element, index) => {
											const { _id, admin_id, usuario_id, abono, createdAt } = element
											return (
												<List.Item className="component-list-item ">
													<Card className="card-list">
														<Row style={{ width: '100%' }} className="">
															<Col xs={24} md={4} className="center">
																<Text>{admin_id.nombre} {admin_id.apellidos}</Text>
															</Col>
															<Col xs={24} md={4} className="center">
																<Text>{usuario_id.nombre} {usuario_id.apellidos}</Text>
															</Col>
															<Col xs={24} md={4} className="center">
																<Text>{usuario_id?.casa_id.nombre}</Text>
															</Col>
															<Col xs={24} md={6} className="center">
																<Text>{moment(createdAt).format('MMMM Do YYYY, h:mm:ss a')}</Text>
															</Col>
															<Col xs={24} md={5} className="center">
																<strong>$ {abono.toMoney()} MXN</strong>
															</Col>
															<Col span={1} className="center">
																<Button className="center btn btn-menu" onClick={() => {
																	this.setState({
																		visibleModalMeses: true,
																		abono_id: _id
																	})
																}}>
																	<IconMenu />
																</Button>
															</Col>
														</Row>
													</Card>
												</List.Item>
											)
										}}
									/>
								</Col>
							</Row>
						</TabPane>
					</Tabs>
				</Spin>


				<Row className="nView">
					<Button style={{ border: "none" }} title="Reporte">
						<Ticket
							numConsulta={0}

							det_folio={this.state.det_folio}
							det_concepto={this.state.det_concepto}
							det_mes={this.state.det_mes}
							det_estatus={this.state.det_estatus}
							det_monto={this.state.det_monto}

							trigger={() =>
								<Button
									type="link" size="small"
									style={{ position: 'relative', top: '0.4em', right: '0.5em' }}
								>
									Ticket
								</Button>}
						/>
					</Button>

				</Row>
				<Button
					type="primary"
					shape="circle"
					si
					icon={<FaDollarSign style={{ position: "relative", top: 3, fontSize: 35 }} />}
					style={{
						position: "fixed",
						right: '2em',
						bottom: '2em',
						zIndex: 100,

						background: "linear-gradient(126.63deg, #74BCFF 11.98%, #000AFF 83.35%)",
						width: 70,
						height: 70
					}}
					onClick={() => this.setState({ visibleAbono: true })}
				/>

				{/* 616dbbfc3402ea689a5cefcc */}
				<ModalAbono
					visible={this.state.visibleAbono}
					cobro_mantenimiento_id={this.state.cobro_mantenimiento_id}
					usuario_id={this.props.match.params.id}
					onClose={() => {
						this.setState({ visibleAbono: false, cobro_mantenimiento_id: undefined })
						this.listMantenimientos()
					}}
				/>


				<ModalListaMeses
					visible={this.state.visibleModalMeses}
					onClose={() => {
						this.setState({ visibleModalMeses: false, abono_id: undefined })
						this.listMantenimientos()
					}}
					_id={this.state.abono_id}
				/>

				<ModalListaAbonos 
					visible={this.state.visibleListaAbonos}
					onClose={() => {
						this.setState({ visibleListaAbonos: false, cobro_mantenimiento_id: undefined })
						this.listMantenimientos()
					}}
					_id={this.state.cobro_mantenimiento_id}
				/>

			</section>
		);
	}
}




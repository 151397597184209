import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Row, Col, Button, Radio, List, Card, Typography, Popconfirm, message, Pagination } from 'antd'
import axios from 'axios';
//componentes
import { IconPlus, IconEdit, IconDelete, IconEyeGreen } from '../../Widgets/Iconos.js'

//css
import './../../../Styles/Global.css';

const { Text } = Typography;

export default class Mantenimiento extends Component {

	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			modalUsuarioVisible: false,
			usuarios: [],

			/*Paginador*/
			page: 1,
			limit: 10,
			pageCount: 0,
			itemCount: 1,
		}
	}


	/**
	 * @methodOf Mantenimiento
	 * @function componentDidMount
	 *
	 */
	componentDidMount() {
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
		this.props.updateNavigationInformation('Mantenimiento');
		this.listUsuarios(1)
	}


	/**
	 * @methodOf Mantenimiento
	 * @function hideModal
	 *
	 */
	hideModal = () => {
		this.setState({ modalUsuarioVisible: false, user_id: undefined })
		this.listUsuarios(1)
	}


	/**
	 * @methodOf Mantenimiento
	 * @function listUsuarios
	 * @description Trae el listado de Usuarios
	 *
	 */
	listUsuarios = (page) => {
		this.setState({ loading: true })
		axios.get('/usuarios/list', {
			params: {
				page: page,
				limit: 10,
				tipo: 2,
			}
		}).then(response => {
			this.setState({
				usuarios: response.data.data.itemsList,
				pageCount: response.data.data.paginator.pageCount,
				itemCount: response.data.data.paginator.itemCount,

			})
		}).catch(error => {
			console.log(error)
			message.error('Error al cargar los usuarios')
		}).finally(() => { this.setState({ loading: false }) })
	}

	render() {
		return (
			<section className="section">
				
				<Row  className="section-header">
					<Col span={12} className="">
						<Radio.Group
							className="radio-grp-green"
							options={[
								{ label: 'Colonos', value: 2 },
							]}
							defaultValue={2}
							optionType="button"
						/>
					</Col>
				</Row>

				<Row className="section-content mantenimiento">
					<Col span={24}>
						<List
							itemLayout="horizontal"
							dataSource={this.state.usuarios}
							loading={this.state.loading}
							renderItem={user => (
								<List.Item className="component-list-item">
									<Card className="card-list">
										<Row style={{ width: '100%' }} className="">
											<Col xs={12} md={7} className="center ">
												<Text>{user.nombre}</Text>
											</Col>
											<Col xs={7} md={0} className="center ">
												<Text>{user.email}</Text>
											</Col>
											<Col xs={10} md={6} className="center ">
												<Text>{user.casa_id ? user.casa_id?.nombre : 'N/A'}</Text>
											</Col>
											<Col xs={2} md={4} className="center">
												<Link to={'/admin/mantenimiento/' + user._id}>
													<Button className="btn btn-view">
														<IconEyeGreen />
													</Button>
												</Link>
											</Col>
										</Row>
									</Card>
								</List.Item>
							)}
						/>
					</Col>
				</Row>
				<Row style={{ margin: "1em 2em", paddingBottom:"3em" }}>
					<Pagination defaultCurrent={1} total={this.state.itemCount} onChange={(page) => this.listUsuarios(page)} />
				</Row>
			</section>
		)
	}
}
import React, { Component, useContext } from 'react';
import { Button, Col, Row, List, Input, Card, Typography, message, Dropdown, Menu, Pagination, Popconfirm, Modal, Spin } from 'antd'
import axios from 'axios';
//componentes
import { IconPDF, IconFolder, IconEyeGreen, IconPlus, IconMenuDots, IconDelete, IconEdit } from '../../Widgets/Iconos'
import Logged from '../../../Hooks/Logged';
//modales
import ModalArchivo from './ModalArchivo'
//css
import './../../../Styles/documentos.css';

const { TextArea } = Input;
const moment = require('moment');
const { Title, Text } = Typography;

class Archivos extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            visible: false,
            casa_id: this.props.match.params.id,
            carpeta: {
                nombre: '-',
                casa_id: {
                    nombre: '-'
                },
                archivos: [],
            },
            file: undefined,
            status: undefined,

            /*Paginador*/
            page: 1,
            limit: 10,
            pageCount: 0,
            itemCount: 1,
        }
    }

    /***
     * @memberof Archivos
     *
     * @function componentDidMount
     */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.props.updateNavigationInformation('Archivos');
        this.getFolder()
    }





    /**
   * @memberof Archivos

   * @method   modalPagar
   * @description Abrir modal.
   *
   **/
    modalAgregar = () => {
        this.setState({
            visible: true,
            accionModal: 'Agregar',
        })
    }



    /**
    * @memberof Archivos
    *
    * @method   hideModal
    * @description  Cierra modal.
    **/
    hideModal = () => {
        this.setState({ visible: false, file: undefined });
        this.getFolder();

    }

    /**
    * @memberof Archivos
    *
    * @method   getFolder
    * @description Carga la infomracion de la carpeta
    *
    **/
    getFolder = () => {
        this.setState({ loading: true })
        axios.get('/carpetas/get', {
            params: {
                casa_id: this.props.match.params.id
            }
        }).then(response => {
            if (response.data.data !== null) {
                this.setState({ carpeta: response.data.data })
            } else
                this.setState({ status: 'Sin Carpeta' })
        }).catch(error => {
            console.log(error)
            message.error('No se cargaron los archivos')
        }).finally(() => this.setState({ loading: false }))
    }

    /**
    * @memberof Archivos
    *
    * @method   viewFile
    * @description  Abre un modal  o una ventana para visualizar el archivo
    *
    **/
    viewFile = (typefile, filename) => {
        if (typefile.includes('image')) {
            this.setState({ viewFileVisible: true, fileImage: filename })
        } else {
            window.open(`${axios.defaults.baseURL}/upload/${filename}`, "_blank")
        }
    }

    /**
    * @memberof Archivos
    *
    * @method   deleteFile
    * @description  Elimina un archivo
    *
    **/
    deleteFile = (id) => {
        axios.post('/carpetas/delete/archivo', {
            casa_id: this.state.casa_id,
            file_id: id,
        }).then(response => {
            this.getFolder();
            message.success('Archivo Eliminado')
        }).catch(error => {
            console.log(error)
            message.error('No se elimino el Archivo')
        })
    }



    render() {



        if (this.state.status === 'Sin Carpeta') {
            return (
                <section className="sec-Documentos p-1">
                    <Row>
                        <Col span={20}>
                            <Title level={3} className="page-sub-title" >Folder</Title>
                        </Col>
                    </Row>
                    <div className=" p-1">
                        <Card className="card-info-folder" loading={this.state.loading}>
                            <Row className="center">
                                <Title level={3} className="card-title">No tienes una carpeta asignada</Title>
                            </Row>
                        </Card>
                    </div>
                </section>
            )
        }

        return (
            <section className="section sec-Documentos">
                <Row className="section-header">
                    <Col span={20}>
                        <Title level={3} className="page-sub-title" >Folder</Title>
                    </Col>
                </Row>

                <Spin spinning={this.state.loading}>
                    
                    <Row className="" >
                        <Col span={24} >
                            <Card className="card-info-folder" style={{ margin: "1em 2em", paddingBottom: "3em" }}>
                                <Row>
                                    <Col sm={24} md={5}>
                                        <IconFolder />
                                    </Col>
                                    <Col sm={24} md={19}>
                                        <Row width="100%" align="left">
                                            <Col sm={24} md={8} className="">
                                                <Title level={3} className="card-title">Nombre</Title>
                                                <Text className="card-text">{this.state.carpeta.nombre}</Text>
                                            </Col>
                                            <Col sm={24} md={8}>
                                                <Title level={3} className="card-title">Numero de Archivos</Title>
                                                <Text className="card-text"># {this.state.carpeta.archivos.length}</Text>
                                            </Col>
                                            <Col sm={24} md={8}>
                                                <Title level={3} className="card-title">Casa</Title>
                                                <Text className="card-text">{this.state.carpeta.casa_id?.nombre}</Text>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>


                    <Row className="section-header">
                        <Col span={20}>

                            <Title level={3} className="page-sub-title" >Archivos</Title>
                        </Col>

                        <Col span={4} className=" flex-right">
                            {this.props.user.tipo === 2 ? null : <Button className="btn-plus right" title="Agregar" onClick={() => { this.modalAgregar() }}>
                                <IconPlus />
                            </Button>}
                        </Col>
                    </Row>

                    <Row className="section-content">
                        <Col span={24}>
                            <List
                                itemLayout="horizontal"
                                dataSource={this.state.carpeta.archivos}
                                className="list-Documentos"
                                renderItem={(file, index) => (
                                    <List.Item className="component-list-item">
                                        <Card className="card-list">
                                            <Row style={{ width: '100%' }}>
                                                <Col sm={0} md={2}className="center">
                                                    {file.filename.includes('.pdf') ? <IconPDF /> : <img src={"/images/png.svg"} width={50} height={60} />}
                                                </Col>
                                                <Col xs={12} md={7} className="center">
                                                    <Text>{file.nombre}</Text>
                                                </Col>
                                                <Col xs={8} md={0} className="center">
                                                    <Text>{file.descripcion}</Text>
                                                </Col>
                                                <Col xs={5} sm={0} md={3}className="center">
                                                    <Text>{moment(file.created_at).format('DD-MM-YYYY')}</Text>
                                                </Col>
                                                <Col xs={12} sm={4} md={4} className="center">
                                                    <Button className="btn btn-view" onClick={() => this.viewFile(file.typefile, file.filename)}>
                                                        <IconEyeGreen />
                                                    </Button>
                                                    {this.props.user?.tipo === 2 ? null : <Popconfirm
                                                        title="¿Deseas Eliminar?"
                                                        placement="topRight"
                                                        onConfirm={e => {
                                                            this.deleteFile(file._id)
                                                        }}
                                                        onCancel={e => e.stopPropagation()}
                                                        okText="Si"
                                                        cancelText="No"
                                                        onClick={e => e.stopPropagation()}
                                                    >
                                                        <Button className="btn btn-delete"
                                                            onClick={e => e.stopPropagation()}>
                                                            <IconDelete />
                                                        </Button>
                                                    </Popconfirm>}
                                                </Col>
                                            </Row>
                                        </Card>
                                    </List.Item>
                                )}
                            />
                        </Col>
                    </Row>

                </Spin>


                <ModalArchivo
                    visible={this.state.visible}
                    accionModal={this.state.accionModal}
                    closeMethod={this.hideModal}
                    casa_id={this.state.casa_id}
                    file={this.state.file}
                    accept={() => {
                        this.hideModal();
                    }}
                />
                {/*Modal para visualizar las imagenes*/}

                <Modal
                    visible={this.state.viewFileVisible}
                    onCancel={() => this.setState({ viewFileVisible: false })}
                    title={null}
                    footer={null}
                >
                    <img
                        style={{
                            width: '100%',
                            borderRadius: '5px'
                        }}
                        src={`${axios.defaults.baseURL}/upload/${this.state.fileImage}`}
                        alt={`${axios.defaults.baseURL}/upload/${this.state.fileImage}`}
                    />
                </Modal>
            </section>
        );
    }
}


export default function (props) { return <Archivos {...props} user={useContext(Logged)} /> }

import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Input, DatePicker, message, Spin, InputNumber } from 'antd';
//Componentes
import { IconCloseModal } from '../../Widgets/Iconos';
import TextArea from "antd/lib/input/TextArea";
//css
import './../../../Styles/modales.css'


const axios = require('axios');
const moment = require('moment');
const { Title } = Typography;


export default class ModalSalonUsosMultiples extends Component {

    formModalSalonUsosMultiples = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            disabled: true,
            fecha_inicio_disabled: {}
        }
    }

    /**
     * @methodOf Usuarios
     * @function componentDidMount
     *
     */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
    }


    /**
    * @memberof ModalSalonUsosMultiples
    *
    * @method   hideModal
    * @description  Cierra el modal por medio del metodo closeMethod() que es pasado por props
    *
    **/
    hideModal = () => {
        this.props.closeMethod();
        this.setState({ disabled: true })
    }

    /**
    * @memberof ModalSalonUsosMultiples
    *
    * @method   onFinish
    * @description  Metodo que se ejecuta al dar guradar en el formulario
    *
    **/
    onFinish = (values) => {
        this.addEvento(values)
    }

    /**
    * @memberof ModalSalonUsosMultiples
    *
    * @method   addEvento
    * @description  Añade un Evento a la base de datos 
    *
    **/
    addEvento = async (values) => {
        axios.post('/eventos/add', {
            nombre: values.nombre,
            fecha_inicio: values.fecha_inicio,
            fecha_final: values.fecha_final,
            invitados: values.invitados,
            descripcion: values.descripcion,
            estatus: 1

        }).then(success => {
            if(success.data.success){
                message.success('Se añadio el Evento');
                this.hideModal();
            }
            else{
                if(success.data.eventosRegistrados != undefined){
                    var error = success.data.message +", se encontraron eventos registrados en el horario ingresado."
                    message.error(error);
                    
                }
                else{
                    message.error(success.data.message);
                }


            }

        }).catch(error => {
            console.log(error);

            message.error('No se guardo el Evento')
        })
    }

    /**
    * @memberof ModalSalonUsosMultiples
    *
    * @method   onOk
    * @description  Establece la fecha y hora de inicio del evento y habilita el campo de fecha final
    *
    **/
    onOk = (value) => {
        this.setState({
            disabled: false,
            fecha_inicio_disabled: value
        })
    }

    /**
    * @memberof ModalSalonUsosMultiples
    *
    * @method   disabledDate
    * @description  desabilita las fechas anteriores de la actual
    *
    **/
    disabledDate = (current) => {
        return current < moment().subtract(1, 'days')
    }

    /**
    * @memberof ModalSalonUsosMultiples
    *
    * @method   disabledDate
    * @description  desabilita las fechas anteriores de la actual seleccionada en el campo fecha_inicio
    *
    **/
    disabledDate2 = (current) => {
        return current < moment(this.state.fecha_inicio_disabled) - 1
    }

    /**
    * @memberof ModalSalonUsosMultiples
    *
    * @method   disabledDate
    * @description  desabilita las horas y minutos anteriores de la fecha de inicio
    *
    **/
    disabledDateTime = (current) => {
        let dateInicio = moment(this.state.fecha_inicio_disabled).format('L')
        let horaInicio = moment(this.state.fecha_inicio_disabled).format('h')
        let minInicio = moment(this.state.fecha_inicio_disabled).format('m')
        if (current !== null) {
            if (moment(current).format('L') === dateInicio) {
                if (moment(current).format('h') === horaInicio) {
                    return {
                        disabledHours: () => range(0, horaInicio),
                        disabledMinutes: () => range(0, minInicio),
                    }
                } else {
                    return {
                        disabledHours: () => range(0, horaInicio),
                    }
                }
            } else return {}
        }
        return {
            disabledHours: () => range(0, horaInicio),
            disabledMinutes: () => range(0, minInicio),
        };
    }


    render() {
        const { accionModal, visible, evento, userAdmin } = this.props;
        let tituloModal = "", contenido = null;

        if (accionModal === "Crear") {
            tituloModal = "Solicitud de Evento"
            contenido = <Form
                layout="vertical"
                className="mdl-form frm-salonUsosMultiples"
                name="formulario-SUM"
                onFinish={this.onFinish}
                ref={this.formModalSalonUsosMultiples}
            >
                <Row align="center">
                    <Col xs={22} xl={{ span: 10, pull: 1 }} >
                        <Form.Item
                            label="Nombre"
                            name="nombre"//------------------------------------------------------------nombre
                            rules={[{
                                required: true,
                                message: "Por favor, ingrese nombre"
                            }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col xs={22} xl={{ span: 10, push: 1 }} >
                        <Form.Item
                            label="Fecha y Hora de Inicio"
                            name="fecha_inicio"//------------------------------------------------------------fecha_inicio
                            rules={[{
                                required: true,
                                message: "Por favor, ingrese fecha y hora de inicio"
                            }]}
                        >
                            <DatePicker
                                format="DD-MM-YYYY HH:mm"
                                onOk={this.onOk}
                                showTime={{ format: 'HH:mm' }}
                                disabledDate={this.disabledDate}

                            />
                        </Form.Item>
                    </Col>

                    <Col xs={22} xl={{ span: 10, pull: 1 }} >
                        <Form.Item
                            label="Fecha y Hora de Termino"
                            name="fecha_final"//------------------------------------------------------------fecha_final
                            rules={[{
                                required: true,
                                message: "Por favor, ingrese fecha y hora de termino"
                            }]}
                        >
                            <DatePicker
                                format="DD-MM-YYYY HH:mm"
                                showTime={{ format: 'HH:mm' }}
                                disabled={this.state.disabled}
                                disabledDate={this.disabledDate2}
                                disabledTime={this.disabledDateTime}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={22} xl={{ span: 10, push: 1 }} >
                        <Form.Item
                            label="Número de Invitados"
                            name="invitados"//------------------------------------------------------------invitados
                            rules={[{
                                required: true,
                                message: "Por favor, ingrese número de invitados"
                            }]}
                        >
                            <InputNumber min={0} />
                        </Form.Item>
                    </Col>




                    <Col span={22} >
                        <Form.Item
                            label="Descripción del Evento"
                            name="descripcion"//------------------------------------------------------------descripcion
                            rules={[{
                                required: true,
                                message: "Por favor, ingrese descripción del evento"
                            }]}
                        >
                            <TextArea />
                        </Form.Item>
                    </Col>
                </Row>

                <Row style={{ width: "100%", textAlign: "center" }}>
                    <Col span={24}>
                        <Form.Item >
                            <Button htmlType="submit" type="primary" className="btn-verde" >
                                Guardar
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        }

        else if (accionModal === "Detalle") {
            
            tituloModal = "Ver Evento";
            contenido = <Form layout="vertical" className="mdl-form frm-salonUsosMultiples" name="formulario-SUM" onFinish={this.onFinish} ref={this.formModalSalonUsosMultiples} >
                <Row align="center">
                    <Col xs={22} xl={{ span: 10, pull: 1 }} >
                        <Form.Item label="Nombre" name="nombre">
                            <Title level={5}>{evento?.nombre}</Title>
                        </Form.Item>
                    </Col>

                    <Col xs={22} xl={{ span: 10, push: 1 }} >
                        <Form.Item label="Estatus" name="estatus" >
                            <Title level={5}> {evento?.estatus === 1 ? 'En revision' : evento?.estatus === 2 ? 'Aprobado y Agendado' : 'Denegado'}</Title>
                        </Form.Item>
                    </Col>

                    <Col xs={22} xl={{ span: 10, pull: 1 }} >
                        <Form.Item label="Fecha y Hora Inicio" name="fecha">
                            <Title level={5}>{moment(evento?.fecha_inicio).format('l LT')}</Title>
                        </Form.Item>
                    </Col>

                    <Col xs={22} xl={{ span: 10, push: 1 }} >
                        <Form.Item label="Fecha y Hora Final" name="fecha_final" >
                            <Title level={5}>{moment(evento?.fecha_final).format('l LT')}</Title>
                        </Form.Item>
                    </Col>

                    {userAdmin ? <Row align="center" style={{ width: "100%" }}>
                        <Col xs={22} xl={{ span: 10, pull: 1 }} >
                            <Form.Item label="Usuario" name="usuario">
                                <Title level={5}>{(evento?.colono != undefined) ? evento?.colono.nombre : "Administración"}</Title>
                            </Form.Item>
                        </Col>

                        <Col xs={22} xl={{ span: 10, push: 1 }} >
                        {evento?.casa_id != undefined ? 
                            <Form.Item label="Casa" name="casa" >
                                <Title level={5}>{(evento?.casa_id != undefined) ? evento?.casa_id.nombre : ""}</Title>
                            </Form.Item>
                         : null }
                        </Col>
                    </Row> : null}

                    <Col xs={22}>
                        <Form.Item label="Número de Invitados" name="invitados" >
                            <Title level={5}>{evento?.invitados}</Title>
                        </Form.Item>
                    </Col>



                    <Col span={22} >
                        <Form.Item label="Descripción del Evento" name="descripcion" >
                            <Title level={5}>{evento?.descripcion}</Title>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        }


        return (
            <Modal
                visible={visible}
                onCancel={this.hideModal}
                title={null}
                footer={null}
                closable={false}
                className="modal-form"
                destroyOnClose={true}
                zIndex={1000}
                width={800}
            >
                <Row className="modal-header">
                    <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={this.hideModal}>
                        <IconCloseModal />
                    </Button>
                    <Title level={5} className="modal-title">{tituloModal}</Title>
                </Row>

                <Spin spinning={this.state.loading} >
                    {contenido}
                </Spin>
            </Modal>
        )
    }
}


function range(start, end) {
    const result = [];
    for (let i = start; i < end; i++) {
        result.push(i);
    }
    return result;
}

import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Drawer, Layout, Menu, Col, Typography } from 'antd';
//componentes


import Logged from './../../Hooks/Logged';
import { IconSdDashboard, IconSdOrdenesTrabajo, IconSdMensajes, IconSdMantenimiento, IconSdDocumentos, IconSdProveedores, IconSdFooter, IconUsuarios } from '../Widgets/Iconos.js'

import { TagsOutlined } from '@ant-design/icons'

//css
import './../../Styles/sidebar.css'

const { Sider } = Layout;
const { Title, Text } = Typography;
const { SubMenu } = Menu;

export default class SiderBar extends Component {

    static contextType = Logged
    constructor(props) {
        super(props)
        this.state = {
            collapsed: false,
            modo: "",
        }
    }


    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });

    };

    render() {
        const user = this.context;

        return (
            <Sider
                width={300}
                className="sider"
                breakpoint="lg"
                collapsedWidth="0"
            >
                <Menu
                    className="sider-menu"
                    mode="inline"
                    defaultSelectedKeys={['n1']}
                    defaultOpenKeys={['sub1']}
                    style={{ height: '100%', borderRight: 0 }}
                >
                    <Menu.ItemGroup key="n0" title="NAVEGACIÓN">
                        <Menu.Item key="n1" icon={<IconSdDashboard />}>
                            <Link to={"/colono/dashboard"}>Dashboard</Link>
                        </Menu.Item>

                        <Menu.Item key="n2" icon={<IconSdOrdenesTrabajo />}>
                            <Link to={"/colono/ordenesTrabajo"}>Ordenes De Trabajo</Link>
                        </Menu.Item>

                        <Menu.Item key="n3" icon={<IconSdMensajes />}>
                            <Link to={"/colono/mensajes"}>Mensajes</Link>
                        </Menu.Item>

                        {/* <Menu.Item key="n4" icon={<IconSdSalonMultiple />}>
                            <Link to={"/colono/salonUsosMultiples"}>Salón de Usos Multiples</Link>
                            </Menu.Item>  */}

                        <Menu.Item key="n5" icon={<IconSdMantenimiento />}>
                            {user.tipo === 1 ? <Link to={"/admin/mantenimiento"}>Mantenimiento</Link> :
                                <Link to={"/colono/mantenimiento"}>Mantenimiento</Link>}
                        </Menu.Item>
                    </Menu.ItemGroup>
                    <Menu.Divider />
                    <Menu.ItemGroup key="c0" title="MI CASA">

                        {user.tipo === 1 ? <Menu.Item key="usrs" icon={<IconUsuarios />}>
                            <Link to={"/admin/usuarios"}>Usuarios</Link>
                        </Menu.Item> : null}

                        {user.tipo === 1 ? <Menu.Item key="ctgrs" icon={<TagsOutlined size={16} style={{ fontSize: 25 }} />}>
                            <Link to={"/admin/categorias"}>Categorias</Link>
                        </Menu.Item> : null}

                        {user.tipo === 1 ? <Menu.Item key="c1" icon={<IconSdDocumentos />}>
                            <Link to={"/admin/documentos"}>Documentos</Link>
                        </Menu.Item> : <Menu.Item key="c1" icon={<IconSdDocumentos />}>
                            <Link to={`/colono/archivos/${user.casa_id?._id}`}>Documentos</Link>
                        </Menu.Item>}
                        {/*                             
                            {user.tipo === 1 ? <Menu.Item key="ft-1" icon={<IconSdProveedores />}>
                                <Link to={"/admin/proveedores"}>Proveedores</Link>
                            </Menu.Item>: <Menu.Item key="ft" icon={<IconSdProveedores />}>
                                <Link to={"/colono/proveedores"}>Proveedores</Link>
                            </Menu.Item>}  */}
                        {user.tipo === 1 ? <Menu.Item key="cs" icon={<IconSdProveedores />}>
                            <Link to={"/admin/casas"}>Casas</Link>
                        </Menu.Item> : null}
                    </Menu.ItemGroup>

                </Menu>
                <div className="footerSd">

                    <div className="icon-div">
                        <IconSdFooter className="icon-sidebar-ft " />
                        <Title className="" level={1}>Parque Esmeralda</Title>
                    </div>
                    <div className="">
                        <Title level={2} className="text"> © 2021 Todos Los Derechos Reservados</Title>
                    </div>
                    <div className="">
                        <Title level={2} className="text">Powered by I See You Tech</Title>
                    </div>

                </div>
            </Sider>
        )
    }
}



import React, { Component } from 'react';
import { Row, Col, Button, Radio, List, Card, Typography, Popconfirm, message, Pagination } from 'antd'
import { Link } from "react-router-dom";
import axios from 'axios';
//componentes
import { IconPlus, IconEdit, IconDelete } from '../../Widgets/Iconos.js'
import Logged from '../../../Hooks/Logged';
//modal
import ModalCasas from '../Modales/Casas/ModalCasas';
//css
import './../../../Styles/Global.css';

const {Text} = Typography;

export default class Casas extends Component{
	constructor(props){
		super(props)
		this.state = {
            loading: true,
			casas: [],
            modalCasasVisible: false,
            modalTitle: 'Añadir',
            casa_id: undefined, 


           /*Paginador*/
            page: 1,
            limit: 10,
            pageCount: 0,
            itemCount: 1,
		}
	}

	/**
     * @methodOf Casas
     * @function componentDidMount
     *
     */
    componentDidMount() {
        this.props.updateNavigationInformation('Casas');
        this.listCasas(1)
    }


    /**
     * @methodOf Casas
     * @function hideModal
     *
     */
    hideModal =() => {
        this.setState({modalCasasVisible: false, casa_id: undefined})
        this.listCasas(this.state.page)
    }

    
    /*
    *
    * @methodOf Casas
    * @function listCasas
    *
    * @description retorna la lista de casas
    */
    listCasas = (page) => {
        this.setState({loading: true})
        axios.get('/casas/list',{
            params:{
                page: page,
                limit: 10
            }
        }).then(response => {
            this.setState({
                casas: response.data.data.itemsList,
                pageCount: response.data.data.paginator.pageCount,
                itemCount: response.data.data.paginator.itemCount,
                page: response.data.data.paginator.currentPage,

            })
        }).catch(error => {
            console.log(error)
            message.error('Error al cargar las casas')
        }).finally(()=>{this.setState({loading: false})})
    }

    /*
    * @methodOf Casas
    * @function deleteCategoria
    *
    * @description Elimina una categoria
    **/
    deleteCategoria = (id) => {
        axios.post('/casas/delete',{
            _id: id
        }).then(response => {
            message.success('La Casa se ha eliminado')
        }).catch(error => {
            console.log(error)
            message.error('No se ha eliminado la Casa')
        }).finally(()=>{this.listCasas(this.state.page)})
    }

	render(){
		return(
			<div>
				<section className="section">
					<Row className="section-header">
						<Col span={12} className="">
							<Radio.Group className="radio-grp-green" value="b">
      							<Radio.Button value="b">Casas: {this.state.itemCount}</Radio.Button>
							</Radio.Group>
						</Col>
						<Col span={12} className=" flex-right">
							<Button className="btn-plus" style={{ float: 'right' }}  onClick={()=>{this.setState({modalCasasVisible: true, modalTitle: 'Añadir'})}}>
								<IconPlus/>
							</Button>
						</Col>
					</Row>
					<Row className="section-content">
						<Col span={24}>
							 <List
                                className="component-list"
                                itemLayout="horizontal"
                                loading={this.state.loading}
                                dataSource={this.state.casas}
                                renderItem={item => (
                                    <List.Item className="component-list-item">
                                        <Card className="card-list">
                                            <Row style={{width: '100%'}} className="">
                                                <Col span={10} className="flex-left">
                                                    <Text strong>{item.nombre}</Text>
                                                </Col>
                                                <Col xs={9} md={10} className="flex-left">
                                                    <Text>$ {item.cuota_mto.toMoney(2)} MXN</Text>
                                                </Col>  
                                                <Col xs={5} md={4} className="center">
                                                    <Button className="btn btn-edit" onClick={(e) => {this.setState({modalCasasVisible: true, casa_id: item._id, modalTitle: 'Editar'})}}>
                                                        <IconEdit/>
                                                    </Button>
                                                    <Popconfirm
                                                        title="¿Deseas Eliminar?"
                                                        placement="topRight"
                                                        onConfirm={e => {
                                                            this.deleteCategoria(item._id)
                                                        }}
                                                        onCancel={e => e.stopPropagation()}
                                                        okText="Si"
                                                        cancelText="No"
                                                        onClick={e => e.stopPropagation()}
                                                    >
                                                        <Button className="btn btn-delete"
                                                                onClick={e => e.stopPropagation()}>
                                                            <IconDelete/>
                                                        </Button>
                                                    </Popconfirm>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </List.Item>
                                )}
                            />
						</Col>
					</Row>

                    <Row style={{ margin: "1em 2em", paddingBottom:"3em" }}>
                        <Pagination defaultCurrent={1} current={this.state.page} total={this.state.itemCount} onChange={(page)=>this.listCasas(page)} />
                    </Row>

				</section>
                <ModalCasas
                    visible={this.state.modalCasasVisible}
                    modalTitle={this.state.modalTitle}
                    closeMethod={this.hideModal}
                    casa_id={this.state.casa_id}
                />
			</div>
		)	
	}
}


Casas.contextType = Logged;
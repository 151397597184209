import React from 'react'
import { Redirect, Route, Switch } from "react-router-dom";
import Login from "../Components/Auth/Login";
import PasswordRecovery from "../Components/Auth/PasswordRecovery";

//css
import './../Styles/Global.css';

function PublicRoutes(props_routes) {


    return (
        <Switch>


            <Route
                exact
                path="/" >
                <Redirect to="/login" />
            </Route>

            <Route
                exact
                path="/login"
                render={(props) => <Login {...props} {...props_routes} />}
            />

            <Route
                // exact
                path="/recovery/email-:email/token-:token"
                component={PasswordRecovery}
            />


            <Route
                exact
                path="/register/email-:email/token-:token"
                render={(props) => <PasswordRecovery {...props} title="Bienvenido" />}
            />

        </Switch>
    )
}

export default PublicRoutes;

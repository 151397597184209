import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Button, Col, Form, Input, Modal, Row, Spin, Layout, Typography, Image, Checkbox } from 'antd';

import '../../Styles/auth.css';
import { FaMapMarker } from 'react-icons/fa';
import Cookies from 'universal-cookie';
// ES6 import or TypeScript

import { SocketContext } from '../../Hooks/Socket';


const axios = require('axios').default;

const { Content } = Layout;

const { Title } = Typography;

export default class Login extends Component {

    static contextType = SocketContext

    constructor(props) {
        super(props);
        this.state = {
            log: false,
            isAdmin: false,
            isLogged: false,
            redirect: false,
            isRecoveryLoading: false
        }
    }

    //Referencia
    recoveryPassword = React.createRef();


    /**
     * @memberof Login
     * @method   renderRedirect
     * @description Renderisa el redireccionamiento a vista si el inicio de sesión fue exitoso
     *
     **/
    renderRedirect = () => {
        if (this.state.redirect) {
            return <Redirect to={"/colono/dashboard"} />;
        }
    };



    /**
  * @memberof Login
  * @method   redirectTo
  * @description Establece valores a redirect
  *
  **/
    redirectTo = (to) => {
        this.to = to;
        this.setState({ redirect: true })
    }



    /**
     * @memberof Login
     * @method   onFinish
     * @description Metodo de inicio de sesión
     *
    */
    onFinish = async ({ email, password, session }) => {

        axios.post('/login', {
            email, password
        }).then(success => {
            const cookies = new Cookies();
            sessionStorage.setItem('token', success.headers.authorization);
            sessionStorage.setItem('username', success.data.user.nombre);
            sessionStorage.setItem('user', success.data.user._id);
            this.props.setUser(success.data.user)
            this.context.emit("start", success.headers.authorization)
            cookies.remove('token');
            if (session == true) {
                cookies.set("token", success.headers.authorization)
            }

            this.redirectTo('/colono/dashboard')


        }).catch(error => {
            console.log(error)
            Modal.warning({
                title: "No es posible iniciar sesión",
            });
        })


    };




    handleRecoveryPassword = value => {

        console.log('TTT');
        this.setState({ isRecoveryLoading: true })
        axios.put("/password/recovery", { email: value.email })
            .then(res => {
                this.setState({
                    isRecovery: false
                })
                Modal.success({
                    title: 'Restablece tu contraseña.',
                    content: '¡El correo ha sido enviado! Revisa tu bandeja de entrada.'
                })
                this.recoveryPassword.current.resetFields();
            })
            .catch(res => {
                Modal.warning({
                    title: 'Error',
                    content: 'El correo no se ha podido enviar. Verifica que sea el mismo con el que te registraste.'
                });
            })
            .finally(() => {
                this.setState({
                    isRecoveryLoading: false
                })
            })
    }


    render() {

        return (
            <Content className="bg-auth" style={{
                background: "#F4F8FB"
            }}>
                {this.renderRedirect()}
                <Row style={{ width: '100%' }} >
                    <Col xs={24} lg={15} className="hide">
                        <div className="background-content" style={{ width: '100%', height: '100%', minHeight: '100vh', backgroundImage: 'url(/images/background-login.jpg)' }} />
                        {/* <Image src="/images/background-login.jpg" /> */}
                        {/* <Title level={1} style={{
                            verticalAlign: "middle",
                            margin: "auto",
                            textAlign: "center",
                            margin: "45% 20px",
                        }}>
                            Carrusel de Imagenes
                        </Title> */}
                    </Col>
                    <Col xs={24} lg={9} >
                        <Row className="main-row ">
                            <Col span={24} className="col-image ">
                                <Image width={270} src={"/images/logo.png"} preview={false} />
                            </Col>
                            <Col span={24} className=" col-text element">

                                <Button type="primary" htmlType="submit" className="btn-verde btn-login" style={{ maxWidth: 135, paddingTop: '5px', paddingBottom: '5px', }} onClick={() => window.location.replace("/home")}>
                                Ir al Inicio
                                </Button>

                                <Title level={1} className="title">Asociación de Colonos</Title>
                                <Title level={2} className="sub-title">Inicia Sesión</Title>
                            </Col>
                            <Col span={24}>
                                <Form
                                    onFinish={this.onFinish}
                                    layout="vertical"
                                    className="login-form "
                                    name="basic" initialValues={{
                                        // email: "av@iseeyoutech.com",
                                        // password: "123456",
                                    }}>
                                    <Form.Item
                                        label="Correo Electrónico"
                                        name="email"
                                        rules={[{
                                            required: true,
                                            message: 'Para poder iniciar sesión, debe haber ingresado su correo electrónico'
                                        }]}
                                    >
                                        <Input disabled={this.state.disponible} />
                                    </Form.Item>

                                    <Form.Item
                                        label="Contraseña"
                                        name="password"
                                        rules={[{
                                            required: true,
                                            message: 'Para poder iniciar sesión, debe haber ingresado su contraseña'
                                        }]}
                                    >
                                        <Input.Password disabled={this.state.disponible} />
                                    </Form.Item>


                                    <a
                                        onClick={() => this.setState({ isRecovery: true })}
                                        style={{ color: '#05B72C', fontSize: "13pt" }}
                                    >
                                        ¿Olvidaste tu contraseña?
                                     </a>
                                    <Form.Item >
                                        <Button type="primary" htmlType="submit" className="btn-verde btn-login" disabled={this.state.disponible}>
                                            Iniciar Sesión
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Modal
                    title="Olvidé mi contraseña"
                    className="modal-recovery"
                    visible={this.state.isRecovery}
                    onOk={() => this.recoveryPassword.current.submit()}
                    onCancel={() => this.setState({ isRecovery: false })}
                    maskClosable={!this.state.isRecoveryLoading}
                    okText="Enviar"
                    cancelText="Cancelar"
                    cancelButtonProps={{ className: 'btnCancel' }}
                    okButtonProps={{ className: 'btnOk recovery-button' }}
                >
                    <Spin spinning={this.state.isRecoveryLoading}>
                        <Form
                            onFinish={this.handleRecoveryPassword}
                            layout={"vertical"}
                            className="pd-2"
                            ref={this.recoveryPassword}>
                            <Form.Item
                                name="email"
                                label="Escribe tu correo electrónico."
                                rules={[{
                                    required: true,
                                    message: 'Por favor, ingresa tu correo electrónico.'
                                }]}>
                                <Input type="email" size="large" className="input-box" placeholder="Escribe tu correo electronico" />
                            </Form.Item>
                        </Form>

                    </Spin>
                </Modal>



            </Content>
        )
    }
}

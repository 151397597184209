import React, { Component } from 'react';
import { Row, Col, Button, Radio, List, Card, Typography, Popconfirm, message, Pagination } from 'antd'
import axios from 'axios';
//componentes
import { IconPlus, IconEdit, IconDelete } from '../../Widgets/Iconos.js'
//modal
import ModalUsuarios from '../Modales/Usuarios/ModalUsuarios';
//css
import './../../../Styles/Global.css';

const {Text} = Typography;

export default class Usuarios extends Component{
	constructor(props){
		super(props)
		this.state = {
			loading: false,
			modalTitle: 'Crear',
			modalUsuarioVisible: false,
			usuarios: [],
			tipo: 2, //tipo usuario
			user_id: undefined,

			/*Paginador*/
			page: 1,
			limit: 10,
			pageCount: 0,
			itemCount: 1,
		}
	}

    
	/**
     * @methodOf Usuarios
     * @function componentDidMount
     *
     */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.props.updateNavigationInformation('Usuarios');
        this.listUsuarios(1)
    }


    /**
     * @methodOf Usuarios
     * @function hideModal
     *
     */
    hideModal =() => {
        this.setState({modalUsuarioVisible: false, user_id: undefined})
        this.listUsuarios(1)
    }


    /**
     * @methodOf Usuarios
     * @function listUsuarios
     * @description Trae el listado de Usuarios
     *
     */
    listUsuarios = (page) => {
    	this.setState({loading: true})
        axios.get('/usuarios/list',{
        	params:{
        		page: page,
        		limit: 10,
        		tipo: this.state.tipo,
        	}
        }).then(response => {          
        	this.setState({
        		usuarios: response.data.data.itemsList,
        		pageCount: response.data.data.paginator.pageCount,
        		itemCount: response.data.data.paginator.itemCount,

        	})
        }).catch(error=>{
        	console.log(error)
        	message.error('Error al cargar los usuarios')
        }).finally(()=>{this.setState({loading:false})})
    }


    /**
     * @methodOf Usuarios
     * @function deleteUsuario
     * @description Elimina un usuario
     *
     */
    deleteUsuario = (id) => {
    	this.setState({loading: true})
        axios.post('/usuarios/delete',{
        	_id: id
        }).then(response => {
        	message.success('Usuario Eliminado')
        }).catch(error=>{
        	message.error('Error al eliminar el usuario')
        }).finally(()=>{this.listUsuarios(1)})
    }

	render(){
		return(
			<div>
				<section className="section">
					<Row className="section-header">
						<Col sx={24} sm={12} className="">
							<Radio.Group
								className="radio-grp-green"
								options={[
									{label: 'Colonos', value: 2 },
									{label: 'Admin', value: 1 }
								]}
								defaultValue={2}
								optionType="button"
								onChange={({target})=>{this.setState({tipo: target.value},()=>{
									this.listUsuarios(1)
								})}}

								
							/>
						</Col>
						<Col xs={24} sm={12} style={{ float: 'right', marginTop: '1rem' }} >
							<Button className="btn-plus right" onClick={()=>{this.setState({modalUsuarioVisible: true, modalTitle: 'Crear'})}}>
								<IconPlus/>
							</Button>
						</Col>
					</Row>
					<Row className="section-content">
						<Col span={24}>
							<List
                                className="component-list"
                                itemLayout="horizontal"
                                dataSource={this.state.usuarios}
                                loading={this.state.loading}
                                renderItem={user => (
                                    <List.Item className="component-list-item">
                                        <Card className="card-list">
                                            <Row style={{width: '100%'}} className="">
                                                <Col xs={10} md={7} className="center">
                                                    <Text strong>{user.nombre}</Text>
                                                </Col>
                                                <Col xs={7} md={0} className="center">
                                                	<Text strong>{user.email}</Text>
                                                </Col>
                                                <Col xs={9} md={6} className="center">
                                                	<Text strong>{user.casa_id? user.casa_id?.nombre : 'N/A'}</Text>
                                                </Col>
                                                <Col xs={5} md={4} className="center">
                                                    <Button className="btn btn-edit" onClick={(e) => {
                                                    	this.setState({user_id: user._id, modalUsuarioVisible: true, modalTitle: 'Editar'})
                                                    }}>
                                                        <IconEdit/>
                                                    </Button>
                                                    <Popconfirm
                                                        title="¿Deseas Eliminar?"
                                                        placement="topRight"
                                                        onConfirm={e => {
                                                            this.deleteUsuario(user._id)
                                                        }}
                                                        onCancel={e => e.stopPropagation()}
                                                        okText="Si"
                                                        cancelText="No"
                                                        onClick={e => e.stopPropagation()}
                                                    >
                                                        <Button className="btn btn-delete"
                                                                onClick={e => e.stopPropagation()}>
                                                            <IconDelete/>
                                                        </Button>
                                                    </Popconfirm>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </List.Item>
                                )}
                            />
						</Col>

                        <Row style={{ margin: "1em 2em", paddingBottom:"3em" }}>
                            <Pagination defaultCurrent={1} total={this.state.itemCount} onChange={(page)=>this.listUsuarios(page)} />
                        </Row>
					</Row>

				</section>
				<ModalUsuarios
					visible={this.state.modalUsuarioVisible}
					modalTitle={this.state.modalTitle}
					closeMethod={this.hideModal}
					user_id={this.state.user_id}
                    tipoUsuario={this.state.tipo}
				/>
			</div>
		)	
	}
}
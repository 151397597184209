import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Image, Input, Select, DatePicker, message, Spin, InputNumber, Divider } from 'antd';

import { IconCloseModal, IconArrowSelect } from '../../Widgets/Iconos';

import { Paypear } from '../../Widgets/paypear';

import './../../../Styles/modales.css'

import TextArea from "antd/lib/input/TextArea";

const axios = require('axios');

const { Title } = Typography;

const { Option } = Select;



export default class ModalMantenimiento extends Component {

    formModalMantenimiento = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            loading: false,

            det_folio: "",
            det_concepto: "",
            det_mes: "",
            det_estatus: "",
            det_monto: 0,
            det_arrayPendientes: []

        }
    }



    //referencia
    paypear = React.createRef();
    formRefPlanes = React.createRef();
    modalPayment = React.createRef();




    componentDidUpdate() {
        console.log(" --- ");
        console.log(this.props);

    }


    /**
    * @memberof ModalMantenimiento
    *
    * @method   hideModal
    * @description  Cierra el modal por medio del metodo closeMethod() que es pasado por props
    *
    **/
    hideModal = () => {
        this.props.closeMethod();
    }


    onFinish = (values) => {
        // this.setState({loading: true})
        console.log(values);
    }


    render() {
        const { id, accionModal, visible } = this.props;
        let {
            det_folio
            , det_concepto
            , det_mes
            , det_estatus
            , det_monto
            , det_arrayPendientes

        } = this.state;

        let tituloModal = "", contenido = null;

        let monto = this.props.det_monto;

        if (accionModal == "Pagar") {
            tituloModal = "";
            contenido = <Row align="center">
                <Col span={24} >
                    <Title level={3} style={{ textAlign: "center", marginTop:"-50px"  }}>Pago</Title>
                </Col>
                <Col span={24} >
                    <Paypear
                        ref={this.paypear}

                        user={{ nombre: sessionStorage.getItem("username") }}
                        width="95%"
                        /*
                        Tipo de procesador de pago
                        * */
                        total={monto}
                        type="stripe"
                        /*
                        Esta es la lista de opciones de envio que se presetnara en el paso 2, debe de tener el nombre como minimo.
                        Si no se indica cost, en automatico es 0
                        * */

                        /*
                        Lista de compras. Se requiere el costo y el nombde. La descripcion es opcional
                        * */
                        buyList={det_arrayPendientes}
                        /*
                        Lista de impuestos, promociones, etcetra a descontar.
        
                        Vienen en dos tipos, percetanje (que sera un suma o resta al subtotal de la lista de productos).
                        Decimal (es una cantidad absoluta, se le decuentan 10, 20, 30 pesos.)
                        * */
                        additionalCharges={[
                            // {
                            //     type: 'percentage',
                            //     operation: '+',
                            //     quantity: 16,
                            //     description: 'Impuesto IVA'
                            // }
                        ]}

                        paymentHandledSubmit={async (token, promo) => {
                            console.log('token', token)
                            console.log('promo', promo)
                            /*
                            Result ->
                            *
                            * */

                            console.log("R1");


                            this.setState({ loading: true });

                            //Procedimiento de pago para stripe
                            let datosPersonales = this.paypear.current.props;
                            console.log(datosPersonales);
                            console.log(this.props);
                            
                            
                          var pagos = (Array.isArray(this.props.det_arrayPendientes)) ? this.props.det_arrayPendientes.map(pago => pago._id ) : [];
                          console.log( "pagos :" + pagos);


                          let userId = sessionStorage.getItem('user');
                          console.log(userId);
                  
                          await axios({
                              method: 'post',
                              url: '/mantenimiento/checkout',
                              headers: { Authorization: sessionStorage.getItem('token') },
                              data: {
                                    userId: userId,
                                    token: token.token,
                                    pagos: pagos,
                                    total: monto
                                }
                            })
                                .then((response) => {
                                    
                                    console.log("res : " , response)

                                    if(response.data.success) {
                                        this.setState({
                                            visible: false,
                                            loading: false,
                                        });

                                        if(response.data.charge != undefined){
                                            this.setState({
                                                pago_id:  response.data.charge.id,
                                            });
                                        }
    
                                        message.success('Pago realizado correctamente.');
                                        document.getElementById('btn-modalPaymentClose').click();
                                    }

                                    else{
                                        message.error('Error.', response.data.message);
                                    }
                                })

                                .catch((error) => {
                                    this.setState({ loading: false });

                                    console.log("error", error.response);
                                    message.error('Error.');
                                });

                               
                        }}
                    />
                </Col>
            </Row>

        }
        else if (accionModal == "Ticket") {
            tituloModal = "";
            contenido = <div>

                <Form layout="vertical" className="mdl-form frm-mantenimiento" name="formulario-manto" onFinish={this.onFinish} ref={this.formModalMantenimiento} >
                    <Row align="center">

                        <Row style={{ display: "inline-flex", padding: "10px", width: "100%" }} className="row-logo">
                            <Image width={400} src={"/images/logo.png"} preview={false} />
                        </Row>

                        <Col xs={20} xl={{ span: 9, pull: 1 }} >
                            <Form.Item label="Folio" name="folio">
                                <Title level={5}>{det_folio}</Title>
                            </Form.Item>
                        </Col>
                        <Col xs={20} xl={{ span: 9, push: 1 }} >
                            <Form.Item label="Concepto" name="concepto">
                                <Title level={5}>{det_concepto}</Title>
                            </Form.Item>
                        </Col>
                        <Col xs={20} xl={{ span: 9, pull: 1 }} >
                            <Form.Item label="Mes" name="invimestados" >
                                <Title level={5}>{det_mes}</Title>
                            </Form.Item>
                        </Col>


                        <Col xs={20} xl={{ span: 9, push: 1 }} >
                            <Form.Item label="Estatus" name="estatus" >
                                <Title level={5}>{det_estatus}</Title>
                            </Form.Item>
                        </Col>

                        <Col span={20} className="col-monto">
                            <Form.Item label="Monto" name="monto" >
                                <Title level={5}>{det_monto}</Title>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <Row width={100}>
                    <Col span={24} className="cnt-imagenTicket">
                        <Image className="imgTicket" src={"/images/imgTicket.svg"} alt="imgticket" preview={false} />
                    </Col>
                </Row>
            </div>

        }


        return (
            <Modal
                visible={visible}
                onCancel={this.hideModal}
                title={null}
                footer={null}
                closable={false}
                className={(accionModal == "Pagar") ? "modal-form " : "modal-ticket"}
                destroyOnClose={true}
                zIndex={1000}
                width={580}
            >
                <Row className="modal-header">
                    <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={this.hideModal} id="btn-modalPaymentClose">
                        <IconCloseModal />
                    </Button>

                </Row>

                <Spin spinning={this.state.loading} >
                    {contenido}

                </Spin>
            </Modal>
        )
    }
}

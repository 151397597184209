import React, { Component } from "react";
import ReactToPrint from "react-to-print";
import { Row, Col, Table, Modal, Button, Image, Form, Typography } from 'antd';

import '../../../Styles/ticketMantenimiento.css'

const { Title } = Typography;

const moment = require('moment');
const formatterDolar = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })

var consulta = 0;

const data_infoM = {
    author: "Alberto Virrey",
    fecha: "06-10-2020",
    fecha_inicio: "31-07-2020",
    fecha_fin: "30-10-2020",
    proyectos: ["Parque esmeralda", "Grupo manantial", "x"],
    cuentas: ["1", "2"],
    area: "Area 0",
    rubro: "Rubro 0",
    subrubro: "Subrubro 0",
    transacciones: [
        { index: 1, fecha: "01-10-2020", concepto: "1", cuenta: "x", proyecto: "Parque esmeralda", area: "Area 0", rubro: "Rubro 0", subrubro: "Subrubro 0", tipo: "Ingreso", monto: 10 },
        { index: 2, fecha: "02-10-2020", concepto: "1", cuenta: "x", proyecto: "Grupo manantial", area: "Area 0", rubro: "Rubro 0", subrubro: "Subrubro 0", tipo: "Ingreso", monto: 10 },
        { index: 3, fecha: "03-10-2020", concepto: "1", cuenta: "x", proyecto: "Grupo manantial", area: "Area 0", rubro: "Rubro 0", subrubro: "Subrubro 0", tipo: "Ingreso", monto: 1 },
        { index: 4, fecha: "04-10-2020", concepto: "a", cuenta: "x", proyecto: "x", area: "Area 0", rubro: "Rubro 0", subrubro: "Subrubro 0", tipo: "Ingreso", monto: 100 }
    ],
    sinFiltros: false,
    total: 121
}

const data_info = {
    author: "Alberto Virrey",
    fecha: "06-10-2020",
    fecha_inicio: "31-07-2020",
    fecha_fin: "30-10-2020",

    proyectos: ["Parque esmeralda", " Grupo manantial", "x"],
    cuentas: ["1", "2"],
    area: "Area 0",
    rubro: "Rubro 0",
    subrubro: "Subrubro 0",

    transacciones: [
        { index: 1, fecha: "01-10-2020", proyecto: "1", cuenta: "x", concepto: "1", area: "Area 0", rubro: "Rubro 0", subrubro: "Subrubro 0", tipo: "Ingreso", monto: 10 },
        { index: 2, fecha: "01-10-2020", proyecto: "1", cuenta: "x", concepto: "1", area: "Area 0", rubro: "Rubro 0", subrubro: "Subrubro 0", tipo: "Ingreso", monto: 20 },
        { index: 3, fecha: "01-10-2020", proyecto: "1", cuenta: "x", concepto: "1", area: "Area 0", rubro: "Rubro 0", subrubro: "Subrubro 0", tipo: "Ingreso", monto: 30 },
        { index: 4, fecha: "01-10-2020", proyecto: "1", cuenta: "x", concepto: "2", area: "Area 1", rubro: "Rubro 0", subrubro: "Subrubro 0", tipo: "Ingreso", monto: 40 },
        { index: 5, fecha: "01-10-2020", proyecto: "1", cuenta: "x", concepto: "3", area: "Area 3", rubro: "Rubro 0", subrubro: "Subrubro 0", tipo: "Ingreso", monto: 50 },
        { index: 6, fecha: "01-10-2020", proyecto: "1", cuenta: "x", concepto: "4", area: "Area 4", rubro: "Rubro 0", subrubro: "Subrubro 0", tipo: "Ingreso", monto: 50 },
        { index: 7, fecha: "01-10-2020", proyecto: "1", cuenta: "x", concepto: "a", area: "Area 0", rubro: "Rubro 0", subrubro: "Subrubro 0", tipo: "Ingreso", monto: 40 },
        { index: 8, fecha: "02-10-2020", proyecto: "1", cuenta: "x", concepto: "b", area: "Area 1", rubro: "Rubro 0", subrubro: "Subrubro 0", tipo: "Ingreso", monto: 30 },
        { index: 9, fecha: "03-10-2020", proyecto: "1", cuenta: "x", concepto: "c", area: "Area 2", rubro: "Rubro 2", subrubro: "Subrubro 2", tipo: "Ingreso", monto: 20 },
        { index: 10, fecha: "04-10-2020", proyecto: "1", cuenta: "x", concepto: "d", area: "Area 4", rubro: "Rubro 4", subrubro: "Subrubro 4", tipo: "Ingreso", monto: 10 },

        { index: 11, fecha: "05-10-2020", proyecto: "1", cuenta: "x", concepto: "e", area: "Area 9", rubro: "Rubro 9", subrubro: "Subrubro 9", tipo: "Ingreso", monto: 100 },
        { index: 12, fecha: "06-10-2020", proyecto: "1", cuenta: "x", concepto: "f", area: "Area 8", rubro: "Rubro 8", subrubro: "Subrubro 8", tipo: "Ingreso", monto: 200 },
        { index: 13, fecha: "07-10-2020", proyecto: "1", cuenta: "x", concepto: "g", area: "Area 5", rubro: "Rubro 5", subrubro: "Subrubro 5", tipo: "Ingreso", monto: 300 },
        { index: 14, fecha: "02-10-2020", proyecto: "1", cuenta: "x", concepto: "x", area: "Area 6", rubro: "Rubro 5", subrubro: "Subrubro 4", tipo: "Ingreso", monto: 400 },
        { index: 15, fecha: "01-10-2020", proyecto: "1", cuenta: "x", concepto: "1", area: "Area 0", rubro: "Rubro 0", subrubro: "Subrubro 0", tipo: "Ingreso", monto: 500 },
        { index: 16, fecha: "01-10-2020", proyecto: "1", cuenta: "x", concepto: "1", area: "Area 0", rubro: "Rubro 0", subrubro: "Subrubro 0", tipo: "Ingreso", monto: 500 },
        { index: 17, fecha: "01-10-2020", proyecto: "1", cuenta: "x", concepto: "1", area: "Area 0", rubro: "Rubro 0", subrubro: "Subrubro 0", tipo: "Ingreso", monto: 400 },
        { index: 18, fecha: "01-10-2020", proyecto: "1", cuenta: "x", concepto: "2", area: "Area 1", rubro: "Rubro 0", subrubro: "Subrubro 0", tipo: "Ingreso", monto: 300 },
        { index: 19, fecha: "01-10-2020", proyecto: "1", cuenta: "x", concepto: "3", area: "Area 3", rubro: "Rubro 0", subrubro: "Subrubro 0", tipo: "Ingreso", monto: 200 },
        { index: 20, fecha: "05-10-2020", proyecto: "1", cuenta: "x", concepto: "4", area: "Area 4", rubro: "Rubro 0", subrubro: "Subrubro 0", tipo: "Ingreso", monto: 100 },

        { index: 21, fecha: "05-10-2020", proyecto: "1", cuenta: "x", concepto: "a", area: "Area 0", rubro: "Rubro 0", subrubro: "Subrubro 0", tipo: "Ingreso", monto: 10 },
        { index: 22, fecha: "05-10-2020", proyecto: "1", cuenta: "x", concepto: "b", area: "Area 1", rubro: "Rubro 0", subrubro: "Subrubro 0", tipo: "Ingreso", monto: 20 },
        { index: 23, fecha: "05-10-2020", proyecto: "1", cuenta: "x", concepto: "c", area: "Area 2", rubro: "Rubro 2", subrubro: "Subrubro 2", tipo: "Ingreso", monto: 30 },
        { index: 24, fecha: "05-10-2020", proyecto: "1", cuenta: "x", concepto: "d", area: "Area 4", rubro: "Rubro 4", subrubro: "Subrubro 4", tipo: "Ingreso", monto: 40 },
        { index: 25, fecha: "05-10-2020", proyecto: "1", cuenta: "x", concepto: "1", area: "Area 0", rubro: "Rubro 0", subrubro: "Subrubro 0", tipo: "Ingreso", monto: 50 },
        { index: 26, fecha: "01-10-2020", proyecto: "1", cuenta: "x", concepto: "1", area: "Area 0", rubro: "Rubro 0", subrubro: "Subrubro 0", tipo: "Ingreso", monto: 50 },
        { index: 27, fecha: "01-10-2020", proyecto: "1", cuenta: "x", concepto: "1", area: "Area 0", rubro: "Rubro 0", subrubro: "Subrubro 0", tipo: "Ingreso", monto: 40 },
        { index: 28, fecha: "01-10-2020", proyecto: "1", cuenta: "x", concepto: "2", area: "Area 1", rubro: "Rubro 0", subrubro: "Subrubro 0", tipo: "Ingreso", monto: 30 },
        { index: 29, fecha: "01-10-2020", proyecto: "1", cuenta: "x", concepto: "3", area: "Area 3", rubro: "Rubro 0", subrubro: "Subrubro 0", tipo: "Ingreso", monto: 20 },
        { index: 30, fecha: "05-10-2020", proyecto: "1", cuenta: "x", concepto: "4", area: "Area 4", rubro: "Rubro 0", subrubro: "Subrubro 0", tipo: "Ingreso", monto: 10 },

        { index: 31, fecha: "05-10-2020", proyecto: "1", cuenta: "x", concepto: "a", area: "Area 0", rubro: "Rubro 0", subrubro: "Subrubro 0", tipo: "Ingreso", monto: 10 },
        { index: 32, fecha: "05-10-2020", proyecto: "1", cuenta: "x", concepto: "b", area: "Area 1", rubro: "Rubro 0", subrubro: "Subrubro 0", tipo: "Ingreso", monto: 20 },
        { index: 33, fecha: "05-10-2020", proyecto: "1", cuenta: "x", concepto: "c", area: "Area 2", rubro: "Rubro 2", subrubro: "Subrubro 2", tipo: "Ingreso", monto: 30 },
        { index: 34, fecha: "05-10-2020", proyecto: "1", cuenta: "x", concepto: "d", area: "Area 4", rubro: "Rubro 4", subrubro: "Subrubro 4", tipo: "Ingreso", monto: 40 },
        { index: 35, fecha: "05-10-2020", proyecto: "1", cuenta: "x", concepto: "1", area: "Area 0", rubro: "Rubro 0", subrubro: "Subrubro 0", tipo: "Ingreso", monto: 50 },
        { index: 31, fecha: "05-10-2020", proyecto: "1", cuenta: "x", concepto: "a", area: "Area 0", rubro: "Rubro 0", subrubro: "Subrubro 0", tipo: "Ingreso", monto: 50 },
        { index: 32, fecha: "05-10-2020", proyecto: "1", cuenta: "x", concepto: "b", area: "Area 1", rubro: "Rubro 0", subrubro: "Subrubro 0", tipo: "Ingreso", monto: 40 },
        { index: 33, fecha: "05-10-2020", proyecto: "1", cuenta: "x", concepto: "c", area: "Area 2", rubro: "Rubro 2", subrubro: "Subrubro 2", tipo: "Ingreso", monto: 30 },
        { index: 34, fecha: "05-10-2020", proyecto: "1", cuenta: "x", concepto: "d", area: "Area 4", rubro: "Rubro 4", subrubro: "Subrubro 4", tipo: "Ingreso", monto: 20 },
        { index: 35, fecha: "05-10-2020", proyecto: "1", cuenta: "x", concepto: "1", area: "Area 0", rubro: "Rubro 0", subrubro: "Subrubro 0", tipo: "Ingreso", monto: 10 },

    ],

    sinFiltros: true,
    total: 3900,

}

class ComponentToPrint extends Component {

    constructor(props) {
        super(props)
        this.state = {
            fecha: '',
            espacio: '',
            dataInfo: [],
            author: '',
            totalTran: '',
            sinFiltros: true,

            fecha_inicio: '',
            fecha_fin: '',
            area: '',
            rubro: '',
            subrubro: '',
            proyectos: [],
            cuentas: [],
            consulta: 0,


            det_folio: "{{Folio}}",
            det_concepto: "{{Concepto}}",
            det_mes: "{{Mes}}",
            det_estatus: "{{Estatus}}",
            det_monto: 0,
            det_arrayPendientes: []


        }
    }

    ImpresionRptTransaccion = React.createRef();

    componentDidMount() {
        const { rpt } = this.props;
    }

    componentDidUpdate() {
        const { rpt } = this.props;

    }

    render() {
        let {
              det_folio
            , det_concepto
            , det_mes
            , det_estatus
            , det_monto

        } = this.props;

        const columns = [
            {
                title: '#',
                dataIndex: 'index',
                key: 'index',
                width: 100
            },
            {
                title: 'Fecha',
                dataIndex: 'fecha',
                key: 'fecha',
                width: 250,
                render: (text, record) => (
                    <label className="lblTitulo">
                        {moment(new Date(record.fecha)).format('DD-MM-YYYY')}
                    </label>
                ),

            },
            {
                title: 'Concepto',
                dataIndex: 'concepto',
                key: 'concepto',
            },


            {
                title: 'Proyecto',
                key: 'proyecto',
                render: (text, record) => {
                    return (
                        record.proyecto
                    )
                },
            },

            {
                title: 'Cuenta',
                dataIndex: 'cuenta',
                key: 'cuenta',
            },


            {
                title: 'Categorias',
                dataIndex: 'concepto',
                key: 'concepto',
                render: (text, record) => {
                    return (
                        <div>
                            <p>{record.area}</p>
                            <p>{record.rubro}</p>
                            <p>{record.subrubro}</p>
                        </div>
                    )
                },
            },
            {
                title: 'Tipo',
                dataIndex: 'tipo',
                key: 'tipo',
            },



            {
                title: 'Monto',
                key: 'monto',
                dataIndex: 'monto',
                render: (text, record) => {
                    var montoTotal = formatterDolar.format(record.monto).replace('$', '');

                    return (
                        montoTotal
                    )
                },
            },
        ];

        return (
            <section className='print-source' >

                <div className="modal-ticket">

                    <Form layout="vertical" className="mdl-form frm-mantenimiento" name="formulario-manto" onFinish={this.onFinish} ref={this.formModalMantenimiento} >
                        <Row align="center">

                            <Row style={{ display: "inline-flex", padding: "10px", width: "100%" }} className="row-logo">
                                <Image width={400} src={"/images/logo.png"} preview={false} />
                            </Row>

                            <Col xs={{ span: 9, pull: 1 }} >
                                <Form.Item label="Folio" name="folio">
                                    <Title level={5}>{det_folio}</Title>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 9, push: 1 }} >
                                <Form.Item label="Concepto" name="concepto">
                                    <Title level={5}>{det_concepto}</Title>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 9, pull: 1 }} >
                                <Form.Item label="Mes" name="invimestados" >
                                    <Title level={5}>{det_mes}</Title>
                                </Form.Item>
                            </Col>


                            <Col xs={{ span: 9, push: 1 }} >
                                <Form.Item label="Estatus" name="estatus" >
                                    <Title level={5}>{det_estatus}</Title>
                                </Form.Item>
                            </Col>

                            <Col span={20} className="col-monto">
                                <Form.Item label="Monto" name="monto" >
                                    <Title level={5}>{det_monto}</Title>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>

                    <Row width={100}>
                        <Col span={24} className="cnt-imagenTicket" style={{ width: " 100% !important" }}>
                            <Image className="imgTicket" src={"/images/imgTicket.svg"} alt="imgticket" preview={false} />
                        </Col>
                    </Row>
                </div>

            </section>
        );
    }
}



class Reporte extends Component {


    render() {
        return (
            <div style={{ width: '100%' }}>
                <ReactToPrint
                    trigger={() => <a href="#"  id="btn-ticket" > <Download style={{ marginTop: "5px" }} /></a>}
                    content={() => this.ImpresionRptTransaccion}
                />
                <ComponentToPrint 
                rpt={this.props.rpt} 
                
                det_folio={this.props.det_folio}
                det_concepto={this.props.det_concepto}
                det_mes={this.props.det_mes}
                det_estatus={this.props.det_estatus}
                det_monto={this.props.det_monto}
                

                ref={el => (this.ImpresionRptTransaccion = el)} />
                
            </div>
        );
    }
}


function Download(props) {
    return <svg {...props} width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8.86347 11.1094C8.86347 8.56789 8.86347 6.3264 8.86347 4.0849C8.86347 3.03965 8.82048 1.99244 8.88887 0.953078C8.91037 0.62558 9.22887 0.317693 9.4106 0C9.60014 0.315731 9.94795 0.629502 9.95381 0.947195C9.99485 3.94959 9.97922 6.95198 9.97922 9.95633C9.97922 10.2387 9.97922 10.5211 9.97922 10.8035C10.0632 10.8604 10.1473 10.9172 10.2332 10.9741C11.3216 9.8367 12.3963 8.6836 13.5101 7.56971C13.8345 7.24614 14.1784 6.49113 14.7373 7.04022C15.3293 7.6207 14.5927 8.02076 14.2429 8.37963C12.8731 9.77983 11.4604 11.131 10.1004 12.5332C9.59623 13.0528 9.23864 13.047 8.73646 12.5273C7.28852 11.029 5.77806 9.58961 4.34186 8.08155C4.12301 7.85211 4.16209 7.37753 4.08002 7.01473C4.41025 7.10494 4.83818 7.09513 5.05703 7.30301C6.28025 8.46592 7.45071 9.6857 8.86347 11.1094Z"
            fill="white" />
        <path
            d="M17.2996 15.9283C17.2996 14.7264 17.2607 13.586 17.3248 12.4493C17.3423 12.1442 17.6804 11.8558 17.8709 11.56C18.0322 11.8502 18.3159 12.1349 18.3295 12.4307C18.3839 13.5432 18.3489 14.6595 18.3489 15.7757C18.3489 16.5757 17.9136 16.9887 17.0975 16.9906C11.8254 17.0017 6.5513 16.9999 1.27724 16.9999C0.443575 16.9999 0.0277133 16.5608 0.0160537 15.7869C-0.00143584 14.6092 -0.0150388 13.4316 0.0335432 12.2558C0.0432595 12.0195 0.328921 11.7944 0.48827 11.5637C0.680655 11.7944 1.02656 12.0195 1.03822 12.2577C1.09457 13.4558 1.06348 14.6576 1.06348 15.9283C6.48328 15.9283 11.8079 15.9283 17.2996 15.9283Z"
            fill="white" />
    </svg>

}

export default Reporte;

import React, { Component } from 'react';
import {
    Row, Col, Button, Dropdown, Menu, Checkbox, List, Modal,
    Card, Typography, Popconfirm, message, Pagination, Select
} from 'antd'
import { Link } from "react-router-dom";
import axios from 'axios'





//componentes
import { IconPlus, IconEdit, IconDelete, IconArrowSelectWhite, IconPDF } from '../../Widgets/Iconos.js'

//css
import './../../../Styles/proveedores.css';

import _Title from 'antd/lib/skeleton/Title';
import Avatar from 'antd/lib/avatar/avatar';

const { Text, Title } = Typography;
const { Option } = Select;

export default class Proveedores extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            proveedores: [],
            viewFileVisible: false,
            fileImage: undefined,
            modalTitle: 'Añadir',
            proveedor_id: undefined,
            categoriasSelected: [],
            categorias: [],

            /*Paginador*/
            page: 1,
            limit: 10,
            pageCount: 0,
            itemCount: 1,
        }
    }

    /**
     * @methodOf Proveedores
     * @function componentDidMount
     *
     */
    componentDidMount() {
        this.props.updateNavigationInformation('Proveedores');
        this.listProveedores(1)
        this.loadCategorias(1);
    }

    /**
    * @methodOf Proveedores
    * @function listProveedores
    * @description trae la lista de proveedores
    */
    listProveedores = (page) => {
        var categorias = this.state.categoriasSelected;
        this.setState({ loading: true })
        axios.get('proveedores/list', {
            params: {
                page: page,
                limit: 10,
                filtroCat: true,
                categorias: categorias
            }
        }).then(response => {
            this.setState({
                proveedores: response.data.data.itemsList,
                page: response.data.data.currentPage,
                pageCount: response.data.data.pageCount,
                itemCount: response.data.data.itemCount,
            });

        }).catch(error => {
            console.log(error)
            message.error('No se cargaron los Proveedores')
        }).finally(() => { this.setState({ loading: false }) })
    }


    loadCategorias = (search) => {
        axios.get('/categorias/list', {
            params: {
                limit: 1000,
            }
        }).then(response => {
            this.setState({ categorias: response.data.data.itemsList })
        }).catch(error => {
            console.log(error)
            message.error('No se cargaron las categorias')
        })
    }



    onCategoriaChange = (_id, status) => {

        let { categoriasSelected } = this.state;
        let filter = categoriasSelected.findIndex(item => item.toString() === _id.toString())
        if (status) {
            if (filter == -1) categoriasSelected.push(_id)
        } else {
            if (filter != -1) categoriasSelected.splice(filter, 1)
        }
        this.setState({
            categoriasSelected
        })
        this.listProveedores(1);

    };

    /**
    * @methodOf Proveedores
    * 
    * @function renderIcon
    * @description renderiza si un avatar si el catalogo es una imagen y si es un pdf pone un icono
    * @param catalogo String
    */
    renderIcon = (catalogo) => {
        if (catalogo.includes('pdf')) {
            return <IconPDF />
        } else {
            return <Avatar src={`${axios.defaults.baseURL}/upload/${catalogo}`} shape="square" style={{ borderRadius: "5px", width: "55px", height: "55px" }} />
        }
    }

    /**
    * @memberof Proveedores
    *
    * @method   viewFile
    * @description   Abre un modal  o una ventana para visualizar el archivo
    *
    **/
    viewFile = (filename) => {
        if (filename.includes('pdf')) {
            window.open(`${axios.defaults.baseURL}/upload/${filename}`, "_blank")
        } else {
            this.setState({ viewFileVisible: true, fileImage: filename })
        }
    }


    render() {
        return (
            <section className="section">
                <Row className="section-header">

                    <Row className="radio-grp-green row-enc-verde" align="left" style={{ width: "100% !important" }}>
                        <Col xs={24} xl={20} span={20} >
                            <Title level={3} className="page-sub-title text-white" style={{ marginLeft: "10px", marginTop: "5px" }}> Proveedores Recomendados </Title>
                        </Col>


                        <Col xs={24} xl={4} span={4} className="center">
                            <Dropdown
                                className="filter-element m-0"
                                overlay={
                                    <Menu>
                                        {this.state.categorias.map(({ _id, nombre, catalogo, color }, index) => (
                                            <Menu.Item key={index}>
                                                <Checkbox
                                                    onChange={(status) => this.onCategoriaChange(_id, status.target.checked)}
                                                >
                                                    {nombre}
                                                </Checkbox>
                                            </Menu.Item>
                                        ))}
                                    </Menu>
                                }>
                                <p className="text-white">Categorías <IconArrowSelectWhite className="arrow" style={{ marginLeft: "15px !important" }} /> </p>
                            </Dropdown>
                        </Col>

                    </Row>
                </Row>


              
                <Row style={{width:"100%",  margin: "1em 2em", paddingBottom:"3em" }}>
                        {this.state.proveedores.map((item, index) => (
                            <Col xs={24} lg={12} xl={8} >
                                <Card className="card-proveedor hover" onClick={() => this.viewFile(item.catalogo)}>
                                    <Row style={{ width: '100%' }} className="">
                                        <Col span={8} className="center" >
                                            {this.renderIcon(item.catalogo)}
                                        </Col>
                                        <Col span={16} className="">
                                            <Title level={3}> {item.nombre}</Title>
                                            <Title level={5}> {item.categoria_id.nombre}</Title>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        ))}

                    </Row>

                    <Row style={{width:"100%", margin: "1em 2em", paddingBottom:"3em" }}>
                        <Pagination defaultCurrent={1} total={this.state.itemCount} onChange={(page) => this.listProveedores(page)} />
                    </Row>
 

                {/*Modal para visualizar las imagenes*/}
                <Modal
                    visible={this.state.viewFileVisible}
                    onCancel={() => this.setState({ viewFileVisible: false })}
                    title={null}
                    footer={null}
                >
                    <img
                        style={{
                            width: '100%',
                            borderRadius: '5px'
                        }}
                        src={`${axios.defaults.baseURL}/upload/${this.state.fileImage}`}
                        alt={`${axios.defaults.baseURL}/upload/${this.state.fileImage}`}
                    />
                </Modal>
            </section>
        )
    }
}
import React, { Component } from 'react';
import { Card, Badge, Button, Col, Row, Radio, Input, Spin, Calendar, Modal, Layout, Typography, message, Dropdown, Menu, Popconfirm, Empty } from 'antd'
import axios from 'axios';
//componentes
import { IconMenuDots, IconDbDocumentosClock, IconPlus, IconArrowToRight as IconArrow } from '../../Widgets/Iconos'
import Logged from '../../../Hooks/Logged';
//Modales
import ModalSalonUsosMultiples from './ModalSalonUsosMultiples';
//css
import './../../../Styles/salonUsosMultiples.css';

const { confirm } = Modal;
const moment = require('moment');
const { TextArea } = Input;
const { Title } = Typography;
const { SubMenu } = Menu;

var dataFechas = [], eventoId = '', userLog, userAdmin = false;


class SalonUsosMultiples extends Component {

    constructor(props){
        super(props)
        this.state = {
            loading:false,
            eventosUsuario:[],
            eventosMensuales:[],
            modalEventoVisible: false,
            eventoView: undefined,
            viewListEventos: 'proximas',
            mes_seleccionado: undefined,
            año_seleccionado: undefined,
        }
    }

    /***
     *
     * @methodOf Dashboard
     * @function componentDidMount
     */
    componentDidMount() {
        this.props.updateNavigationInformation('Salón de Usos Múltiples');
        this.getEventos();
    }

    /**
    * @memberof SalonUsosMultiples
    *
    * @method   onChange
    * @description  Cambia la lista de eventos mostrada
    *
    **/
    onChange = (e) => {
        this.setState({viewListEventos: e.target.value})
    }

    /**
    * @memberof SalonUsosMultiples
    *
    * @method   getEventos
    * @description  Lista los eventos creados por el colono logeado o por mes
    *
    **/
    getEventos = (mes, año) => {
        this.setState({loading: true})
        axios.get('/eventos/list',{
            params: {
                mes_seleccionado: mes !== undefined ? mes : moment().month(),
                año_seleccionado: año !== undefined ? año : moment().year()
            }
        })
        .then(success => {
            this.setState({
                eventosUsuario: success.data.data.eventos.itemsList,
                eventosMensuales: success.data.data.eventosMensuales,
            })
        })
        .catch(error => {
            console.log(error)
            message.error('No se cargaron los eventos')
        }).finally(()=>this.setState({loading: false}) )
    }

    /**
    * @memberof SalonUsosMultiples
    *
    * @method   deleteEvento
    * @description  Elimina un evento, solamente si el usuario es admin o es el creador del evento
    *  se verifica en el server
    **/
    deleteEvento = (id) => {
        const {mes_seleccionado , año_seleccionado} = this.state
        axios.post('/eventos/delete',{
            _id: id
        })
        .then(success => {
            message.success('El Evento ha sido eliminado')
            this.getEventos(mes_seleccionado, año_seleccionado );
        })
        .catch(error => {
            console.log(error)
            message.error('No se cargaron los eventos')
        })
    }

    /**
    * @memberof SalonUsosMultiples
    *
    * @method   badgeEstatus
    * @description retorna el tipo de Badge correspondiente al estatus del evento
    * 
    **/
    badgeEstatus = (estatus) => {
        if(estatus === 1)
            return <Badge status="warning" text="En revision" />
        if(estatus === 2)
            return <Badge status="success" text="Aprobado y Agendado" />
        if(estatus === 3)
            return <Badge status="error" text="Denegado" />
    }

    /**
    * @memberof SalonUsosMultiples
    *
    * @method   renderCards
    * @description renderiza las cards de los eventos a mostar
    * @param eventos [{*}] : Array de Objetos con la infomacion de los eventos
    * @param user {*} : Objeto user que se manda desde el this.context 
    * 
    **/
    renderCards = (eventos,user) => {
        if(eventos.length === 0){
            return <Row className="center">
                <Empty description="No tienes ningún evento creado "/>
            </Row>
        }else{
            return eventos.map((eve) => {
                return <Card className={`card-evento ${eve.estatus === 1 ? 'warning' : eve.estatus === 2 ? 'success' : 'error'}`}>
                    <Row>
                        <Col span={22}>
                            <Title level={4}>{eve.nombre}</Title>
                            <p><IconDbDocumentosClock /> {`${moment(eve.fecha_inicio).format('l LT')} -  ${moment(eve.fecha_final).format('l LT')}`}</p>
                            <p> {this.badgeEstatus(eve.estatus)} </p>     
                        </Col>
                        <Col span={2} className="center ">
                            <Dropdown
                                trigger={['click']} 
                                placement="bottomLeft"
                                overlay={
                                    <Menu
                                        onClick={(item)=>{this.onClickUpdate(item, eve._id)}}
                                    >
                                        <Menu.Item onClick={()=>this.setState({modalEventoVisible: true, eventoView: eve})}>
                                            Ver Evento
                                        </Menu.Item>
                                        {user.tipo === 1 ? <SubMenu key="sub1" title="Actualizar">
                                            <Menu.Item key={1}>En Revisión</Menu.Item>
                                            <Menu.Item key={2}>Aprobado & Agendado</Menu.Item>
                                            <Menu.Item key={3}>Denegada</Menu.Item>
                                          </SubMenu> : null}
                                        {user.tipo === 1 ? <Menu.Item>
                                            <Popconfirm
                                                title="¿Deseas Eliminar?"
                                                placement="topRight"
                                                onConfirm={e => {
                                                    this.deleteEvento(eve._id)
                                                }}
                                                onCancel={e => e.stopPropagation()}
                                                okText="Si"
                                                cancelText="No"
                                                onClick={e => e.stopPropagation()}
                                            >
                                                Eliminar Evento
                                            </Popconfirm>
                                        </Menu.Item> : null}
                                        {userAdmin  = (user.tipo === 1)}
                                    </Menu>
                                } 
                            >
                                <Button className="btn-opcion-menuDot right">
                                    <IconMenuDots />
                                </Button>
                            </Dropdown>
                        </Col>
                    </Row>
                </Card>
            }) 

        }
    }

    /**
    * @memberof SalonUsosMultiples
    *
    * @method   changeDate
    * @description  Al cambiar la fecha seleccionada en el calendario, manda una peticion para traer los eventos
    * que conicidan con el mes y año seleecionado
    * @param e {Date} : objeto Date que manda el Calendario de antd, al cambiar de fecha seleccionada
    *
    **/
    changeDate = (e) => {
        this.setState({
            mes_seleccionado: moment(e).month(),
            año_seleccionado: moment(e).year()
        })
        this.getEventos(moment(e).month(), moment(e).year())
    }

    /**
    * @memberof SalonUsosMultiples
    *
    * @method   onClickUpdate
    * @description  Cambia el estatus de un evento 
    *
    **/
    onClickUpdate = (element, evento_id) => {
        const {mes_seleccionado , año_seleccionado} = this.state
        if(parseFloat(element.key) <= 3){
            this.setState({loading: true})
            axios.post('/eventos/update',{
                _id: evento_id,
                estatus: parseFloat(element.key),
            }).then(response =>{
                message.success('Evento Actualizado')
                this.getEventos(mes_seleccionado, año_seleccionado)
            }).catch(error=>{
                message.error('No se Actualizo el evnto')
                this.setState({loading: false})
            })
        }
    }

    render() {
        let user = this.context
        
        return (
            <section className="sec-salMultiple p-1">
                <Spin spinning={this.state.loading}>
                    <Row>
                        <Col xs={24} xl={8} >
                            <Row>
                                <Title level={3} className="page-sub-title" >Mis Eventos</Title>
                            </Row>
                            <Row>
                                <Radio.Group onChange={this.onChange} defaultValue="proximas" className="radioMensajeTipo">
                                    <Radio.Button value="proximas">Próximas</Radio.Button>
                                    <Radio.Button value="all">Todas</Radio.Button>
                                </Radio.Group>
                            </Row>

                            <Row className="cnt-cardsEventos">
                                <Col span={24}>
                                    {
                                        this.state.viewListEventos === 'proximas' ? this.renderCards(this.state.eventosUsuario, user)
                                        : this.renderCards(this.state.eventosMensuales, user)
                                    }
                                </Col>
                            </Row>
                        </Col>

                        <Col xs={24} xl={16}  className="pd-20">
                            <Row className="cnt-calendario">
                                <Col span={24}>
                                    <Calendario 
                                        eventos={this.state.eventosMensuales}
                                        getEventos={this.getEventos}
                                        changeDate={this.changeDate}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Spin>
                <ModalSalonUsosMultiples
                    accionModal="Detalle"
                    visible={this.state.modalEventoVisible}
                    evento={this.state.eventoView}
                    
                    userAdmin={userAdmin}

                    closeMethod={()=>{
                        this.setState({modalEventoVisible: false, eventoView: undefined})
                    }}
                />
            </section>
        );
    }
}


class Calendario extends Component {
    dataFechas = [];
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            spinning: false,
            mes_seleccionado: undefined,
            año_seleccionado: undefined,

            mode: 'month',
            valor: '',
            data: [],
            list: [],
            visible: false,
            accionModal: 'Crear',
            eventos: [],
            eventoView: undefined,
            windowWidth: window.innerWidth,
        }
    }


    componentDidUpdate(prevProps) {
        if(this.props.eventos !== prevProps.eventos){
            this.setState({eventos: this.getDatesFormat(this.props.eventos)})
        }
    }

 handleResize = (e) => {
  this.setState({ windowWidth: window.innerWidth });
 };

 componentDidMount() {
  window.addEventListener("resize", this.handleResize);
 }

 componentWillUnmount() {
  window.addEventListener("resize", this.handleResize);
 } 


     /**
     * @memberof Calendario
     * @method   getDatesFormat
     * @description Establecemos el formato de fecha para que el metodo de dateCellRender lo pueda leer,
     *  retorna un objeto con campos fechas, y dentro de estos un array con los eventos que hay es esa fecha
     * @param fechas [{*}] : Array de Objetos con la informacion de cada Evento del mes
     *
     * @return fechasCalendar { 
     *      fecha_1: [{evento_1},...],
     *      fecha_2: [{evento_n}]  
     *  }
     */
    getDatesFormat = (fechas) => {
        let fechasCalendar = {};
        if(fechas){
            for (const fecha of fechas){
                let fechaIndex =  moment(fecha.fecha_inicio).format('YYYY-MM-DD');
                if(!fechasCalendar[fechaIndex]){
                    fechasCalendar[fechaIndex] = []
                }
                fechasCalendar[fechaIndex].push(fecha)
            }
        }
        return fechasCalendar;

    }

    /**
     * @memberof Calendario
     * @method getDatesFormat
     * @description Renderiza los badges dentro del las celdas del calendario
     * 
     */
    dateCellRender = (value) => {
        let cellDayValue = value.format('YYYY-MM-DD');
        if(this.state.eventos[cellDayValue]){
            return (
                <ul className="events">
                    {this.state.eventos[cellDayValue].map((evento) => (
                        <Badge
                            className="hover-badge"
                            onClick={()=>{
                                this.setState({
                                    accionModal: 'Detalle',
                                    eventoView: evento,
                                    visible: true,
                                })
                            }} 
                            status={evento.estatus === 1 ? 'warning' : evento.estatus === 2 ? 'success' : 'error'}
                            text={evento.nombre} 
                            style={{ width: '100%' }} />
                    ))}
                </ul>
            )
        }
    }

    /**
     * @memberof Calendario
     * @method dateCellRenderSmall
     * @description Renderiza los badges dentro del las celdas del calendario, 
     * este metodo se llama automaticamente por cada dia del mes por el componente de antd 
     * 
     */
    dateCellRenderSmall = (value) => {

        let cellDayValue = value.format('YYYY-MM-DD');
        if(this.state.eventos[cellDayValue]){
            return <Badge className="badge-calendario" status='success' />
        }
    }

  
    modalCrear = () => {
        this.setState({
            visible: true,
            accionModal: 'Crear',

        })
    }

    hideModal = () => {
        this.props.getEventos(this.state.mes_seleccionado, this.state.año_seleccionado);
        this.setState({
            visible: false,
            spinning: false,
            eventoView: undefined,
        });
    };

    render() {
        const { Header, Sider, Content } = Layout;
        const { windowWidth } = this.state;
        


        return (
            <Row className="cnt-calendario pd-10">
                <Col span={20} className="p-1">
                    <Title level={2} className="calendar-title"  style={{ color: "#0F0F0F", margin: '0px' }}>Calendario</Title>
                </Col>
                <Col span={4} className="center ">
                    <Button className="btn-plus right" title="Agregar" onClick={this.modalCrear}>
                        <IconPlus />
                    </Button>
                </Col>

                <Col span={24} style={{marginBottom:"10px"}}>
                    <div className="div-contenedor">
                        <Calendar
                            className="div-calendario darkTheme" 
                            dateCellRender={window.innerWidth >= 576 || this.state.windowWidth >=576 ? this.dateCellRender : this.dateCellRenderSmall}
                            onPanelChange={(value)=>{
                                this.setState({ 
                                    mes_seleccionado: moment(value).month(),
                                    año_seleccionado: moment(value).year(),
                                })
                                this.props.changeDate(value)
                            }}
                            fullscreen={window.innerWidth >= 576  || this.state.windowWidth >=576 ? true : false}
                        />
                    </div>
                </Col>



                <ModalSalonUsosMultiples
                    visible={this.state.visible}
                    accionModal={this.state.accionModal}
                    closeMethod={this.hideModal}
                    evento={this.state.eventoView}
                    userAdmin={userAdmin}

                    accept={() => {
                        this.hideModal();
                    }}
                />
            </Row>

        );
    }
}

SalonUsosMultiples.contextType = Logged;


export {
    Calendario,
    SalonUsosMultiples
}

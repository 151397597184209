import React, { Component } from "react";
import { Button, Col, Form, Input, message, Modal, Row, Select, Spin, Typography } from 'antd';
import { DeleteOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons'
//componentes
import MyUpload from '../../../Widgets/Upload';
import { IconCloseModal, IconArrowSelect } from '../../../Widgets/Iconos';
//css
import './../../../../Styles/modales.css'

const moment = require('moment');
const axios = require("axios").default;


const { Option } = Select;

const { Title, } = Typography;


export default class ModalProveedores extends Component {

    formModalProveedores = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            categorias: [],
            categoria: undefined,
            fileList: undefined,

        }
    }


    /**
     * @methodOf ModalProveedores
     *
     * @function componentDidUpdate
     * @description Se ejecuta cuando se inicia el component, declara en el header el session storage
     *
     * */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.loadCategorias();
    }


    /**
    * @memberof ModalProveedores
    *
    * @method   componentDidUpdate
    * @description  Si se va Editar un usuario va y busca la informacion de este
    *
    **/
    componentDidUpdate(prevProps) {
        if (this.props.proveedor_id !== prevProps.proveedor_id && this.props.proveedor_id !== undefined) {
            this.getProveedor(this.props.proveedor_id)
        }

        if (this.props.visible === false && this.props.visible !== prevProps.visible) {
            this.loadCategorias();
        }
    }


    /**
    * @memberof ModalProveedores
    *
    * @method   hideModal
    * @description  Metodo que se ejecuta para cerrar el modal
    *
    **/
    hideModal = () => {
        this.props.closeMethod()
        this.setState({ categoria: undefined, fileList: undefined })
    }


    /**
    * @memberof ModalProveedores
    *
    * @method   onFinish
    * @description  Metodo que se ejecuta al guardar el formulario
    *
    **/
    onFinish = (values) => {
        if (this.props.modalTitle === 'Editar') {
            this.updateProveedor(values);
        } else {
            this.addProveedor(values);
        }
    }


    /**
    * @memberof ModalProveedores
    *
    * @method   loadCategorias
    * @description  lista de Categorias para desplegar en el select
    *
    **/
    loadCategorias = (search) => {
        axios.get('/categorias/list', {
            params: {
                limit: 10,
                search: search
            }
        }).then(response => {
            this.setState({ categorias: response.data.data.itemsList })
        }).catch(error => {
            console.log(error)
            message.error('No se cargaron las categorias')
        })
    }


    /**
    * @memberof ModalProveedores
    *
    * @method   addProveedor
    * @description  Guarda el proveedor en la BD
    *
    **/
    addProveedor = (values) => {
        axios.post('/proveedores/add', {
            nombre: values.nombre,
            categoria_id: values.categoria_id,
            info_contacto: values.info_contacto,
            catalogo: this.state.fileToUpload.filename,
            descripcion: values.descripcion
        }).then(response => {
            this.hideModal();
            message.success('El proveedor se agrego correctamente')
        }).catch(error => {
            console.log(error)
            message.error('No se pudo agregar al proveedor')
        })
    }


    /**
    * @memberof ModalProveedores
    *
    * @method   getProveedor
    * @description  Trae la informacion de un proveedor
    *
    **/
    getProveedor = (id) => {
        axios.get('/proveedores/get', {
            params: { _id: id }
        }).then(response => {
            this.setState({
                categoria: response.data.data.categoria_id,
                catalogo: response.data.data.catalogo
            })
            this.formModalProveedores.current.setFieldsValue({
                nombre: response.data.data.nombre,
                categoria_id: response.data.data.categoria_id._id,
                info_contacto: response.data.data.info_contacto,
                catalogo: response.data.data.catalogo,
                descripcion: response.data.data.descripcion,
            })
        }).catch(error => {
            console.log(error)
            message.error('No se pudo cargar el proveedor')
        })
    }


    /**
    * @memberof ModalProveedores
    *
    * @method   updateProveedor
    * @description  Actualiza la informacion de un proveedor
    *
    **/
    updateProveedor = (values) => {
        axios.post('/proveedores/update', {
            _id: this.props.proveedor_id,
            nombre: values.nombre,
            categoria_id: values.categoria_id,
            info_contacto: values.info_contacto,
            catalogo: this.state.fileToUpload.filename,
            descripcion: values.descripcion
        }).then(response => {
            this.hideModal();
            message.success('El proveedor se actualizo correctamente')
        }).catch(error => {
            console.log(error)
            message.error('No se actualizo el Proveedor')
        })
    }


    /**
    * @memberof ModalProveedores
    *
    * @method   selectOptions
    * @description  Metodo que reciebe un array de elementos para devolver un array de opciones para un select,
    * ademas de agrear al arreglo el elemento a editar
    *
    **/
    selectOptions = (array, addElemnt) => {

        let arr = [];
        if (array) {
            if (array.length > 0) {

                array.forEach(function (item, index) {
                    arr.push(<Option value={item._id}>{item.nombre}</Option>)
                })
            }
        }

        if (array && addElemnt) {
            if (!array.some(element => element._id === addElemnt._id)) {
                arr.push(<Option value={addElemnt._id}> {addElemnt.nombre}</Option>)
            }
        }
        return arr
    }


    /**
    * @memberof ModalProveedores
    *
    * @method   setFile
    * @description  Cuando se guarda un archivo en la BD por medio del componente Upload, la informacion del archivo es pasado
    * al this.state para manejar esa infomacion, ya que el formulario por alguna razon no detecta los valores ingresados
    *
    **/
    setFile = (file) => {
        this.setState({ fileToUpload: file })
    }

    render() {
        return (
            <Modal
                visible={this.props.visible}
                onCancel={this.hideModal}
                title={null}
                footer={null}
                closable={false}
                className="modal-form"
                destroyOnClose={true}
                zIndex={1000}
                width={500}

            >
                <div className="modal-header">
                    <Button type="ghost" title="Cerrar" className="btn-close-modal" onClick={this.hideModal}>
                        <IconCloseModal />
                    </Button>
                    <Title level={3} className="modal-title">{this.props.modalTitle} Proveedor</Title>
                </div>
                <Spin spinning={this.state.loading}>

                    <Form
                        layout="vertical"
                        className="mdl-form frm-documentos"
                        name="formulario-tajo"
                        onFinish={this.onFinish}
                        ref={this.formModalProveedores}
                    >

                        <Row align="center">
                            <Col xs={20}>
                                <Form.Item
                                    label="Nombre"
                                    name="nombre" //<--------------------------------------- nombre
                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese el nombre"
                                    }]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row align="center">
                            <Col xs={20}>
                                <Form.Item
                                    label="Categoria"
                                    name="categoria_id" //<--------------------------------------- categoria_id
                                    rules={[{
                                        required: true,
                                        message: "Por favor, seleccione una categoria"
                                    }]}
                                >
                                    <Select
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={false}
                                        onSearch={search => this.loadCategorias(search)}
                                    >

                                        {/* {this.state.categorias.map((T, number) =>
                                            <Option key={number} value={T._id}>{T.nombre}</Option>)
                                        } */}

                                        {this.selectOptions(this.state.categorias, this.state.categoria)}
                                    </Select>
                                </Form.Item>
                                <IconArrowSelect />
                            </Col>
                        </Row>

                        <Row align="center">
                            <Col xs={20}>
                                <Form.Item
                                    label="Informacion de contacto"
                                    name="info_contacto" //<--------------------------------------- info_contacto
                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese la informacion de contacto"
                                    }]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row align="center">
                            <Col xs={20}>
                                <Form.Item
                                    label="Catalogo"
                                    name="catalogo" //<--------------------------------------- catalogo
                                >
                                    <MyUpload setFile={this.setFile} fileList={this.state.catalogo} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row align="center">
                            <Col xs={20}>
                                <Form.Item
                                    label="Descripcion"
                                    name="descripcion" //<--------------------------------------- descripcion
                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese una descripcion"
                                    }]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row style={{ textAlign: "center" }}>
                            <Col span={24}>
                                <Form.Item>
                                    <Button htmlType="submit" type="primary" className="btn-green">
                                        Guardar
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        )
    }
}


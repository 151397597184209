import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Input, Select, message, Spin } from 'antd';
import TextArea from "antd/lib/input/TextArea";
//componentes
import { IconCloseModal, IconArrowSelect } from '../../../Widgets/Iconos';
import  MyUpload   from '../../../Widgets/Upload';
//css
import '../../../../Styles/modales.css'
import '../../../../Styles/documentos.css'

const axios = require('axios');
const { Title } = Typography;
const { Option } = Select;

/**
 *
 *
 * @export
 * @class ModalCarpeta
 * @extends {Component}
 * @description Modal para agregar documentos
 */

export default class ModalCarpeta extends Component {

    formModalCarpeta = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            casas: [],
            casa: undefined,

        }
    }


     /***
     * @memberof Documentos
     *
     * @function componentDidMount
     */
    componentDidMount() {
        this.loadCasas();
    }


     /***
     * @memberof Documentos
     *
     * @function componentDidUpdate
     */
    componentDidUpdate(prevPros) {
        if(this.props.carpeta_id !== prevPros.carpeta_id && this.props.carpeta_id !== undefined){
        	this.getFolder(this.props.carpeta_id)
        }

        if(this.props.visible !== prevPros.visible && this.props.visible === true){
        	this.loadCasas();
        }
    }


    /**
    * @memberof ModalCarpeta
    *
    * @method   hideModal
    * @description  Cierra el modal por medio del metodo closeMethod() que es pasado por props
    *
    **/
    hideModal = () => {
        this.props.closeMethod();
    }


    /**
    * @memberof ModalCarpeta
    *
    * @method   onFinish
    * @description  Se ejeuta al aceptar el formulario
    *
    **/
    onFinish = (values) => {
    	if(this.props.accionModal === "Crear")
        	this.addFolder(values)
       	else
       		this.updateFolder(values)
    }


    /**
    * @memberof ModalCarpeta
    *
    * @method   loadCasas
    * @description  Carga las casas que aun no tienen una carpeta asignada
    *
    **/
    loadCasas = (search) => {
        axios.get('/casas/list',{
        	params:{
        		search: search,
        		enCarpetas: true, //filtro para quitar las casas que ya tiene una carpeta
        	}
        }).then(response => {
        	this.setState({ casas: response.data.data.itemsList })
        }).catch(error => {
        	console.log(error)
        	message.error('No se cargaron las casas')
        })
    }


    /**
    * @memberof ModalCarpeta
    *
    * @method   getFolder
    * @description  trae la informacion 
    *
    **/
    getFolder = (id) => {
        axios.get('/carpetas/get',{
        	params:{
        		_id: id
        	}
        }).then(response => {
            console.log(response.data.data)
            this.setState({casa: response.data.data.casa_id})
        	this.formModalCarpeta.current.setFieldsValue({
                nombre: response.data.data.nombre,
                casa_id: response.data.data.casa_id._id
            })
        }).catch(error => {
        	console.log(error)
        	message.error('No se cargao la carpeta')
        })
    }


    /**
    * @memberof ModalCarpeta
    *
    * @method   addFolder
    * @description  Cierra el modal por medio del metodo closeMethod() que es pasado por props
    *
    **/
    addFolder = (values) => {
        axios.post('/carpetas/add',{
        	nombre: values.nombre,
        	casa_id: values.casa_id,
        }).then(response => {
        	message.success('Se añadio un folder')
        	this.hideModal();
        }).catch(error => {
        	console.log(error)
        	message.error('No se creo el Folder')
        })
    }
    

    /**
    * @memberof ModalCarpeta
    *
    * @method   updateFolder
    * @description  Actualiza la informacion de una Carpeta
    *
    **/
    updateFolder = (values) => {
        axios.post('/carpetas/update',{
        	_id: this.props.carpeta_id,
        	nombre: values.nombre,
            casa_id: values.casa_id,
        }).then(response => {
        	message.success('Se actualizo el folder')
        	this.hideModal();
        }).catch(error => {
        	console.log(error)
        	message.error('No se actualizo el Folder')
        })
    }


    /**
    * @memberof ModalOrdenTrabajo
    *
    * @method   selectOptions
    * @description  Metodo que reciebe un array de elementos para devolver un array de opciones para un select,
    * ademas de agrear al arreglo el elemento a editar
    *
    **/
    selectOptions = (array, addElemnt) => {

       let arr = [];
       if(array){
            array.forEach(function ({ _id, nombre }, index) {
                arr.push (<Option value={_id}> {nombre}</Option>)
            })
       }

       if(array && addElemnt){
            if (!array.some(element => element._id === addElemnt._id)) {
                arr.push(<Option value={addElemnt._id}> {addElemnt.nombre}</Option>)
            }
       }
        return arr
    }


    render() {
        const { id, accionModal, visible } = this.props;
        return (
            <Modal
                visible={visible}
                onCancel={this.hideModal}
                title={null}
                footer={null}
                closable={false}
                className="modal-form"
                destroyOnClose={true}
                zIndex={1000}
                width={500}
            >
                <Row className="modal-header">
                    <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={this.hideModal}>
                        <IconCloseModal />
                    </Button>
                    <Title level={3} className="modal-title">{accionModal} Folder</Title>
                </Row>

                <Spin spinning={this.state.loading} >
                    <Form 
                    	layout="vertical" 
                    	className="mdl-form frm-documentos" 
                    	name="formulario-Docto" 
                    	onFinish={this.onFinish} 
                    	ref={this.formModalCarpeta} >
                        <Row align="center">
                            <Col xs={20} >
                                <Form.Item 
                                	label="Nombre" 
                                	name="nombre"
                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese nombre"
                                    }]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col xs={20} >
                                <Form.Item 
                                	label="Casa" 
                                	name="casa_id"
                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese la casa"
                                    }]}
                                >
                                   <Select 
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={false}
                                        onSearch={search => this.loadCasas(search)} 
                                    >
                                        {this.selectOptions(this.state.casas, this.state.casa)}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row style={{ textAlign: "center" }}>
                            <Col span={24}>
                                <Form.Item >
                                    <Button htmlType="submit" type="primary" className="btn-verde" >
                                        Guardar
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        )
    }
}

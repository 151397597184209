import React, { Component } from 'react'
import { Route, Switch, Redirect } from "react-router-dom";
import { Layout } from 'antd';


import {Modal} from 'antd';

import Cookies from 'universal-cookie';
import axios from 'axios'
import { io } from "socket.io-client";


//COMPONENTES
import Logged from '../Hooks/Logged';
import SideBar from "../Components/Sidebar/Sidebar";
import Header from "../Components/Header/Header";
import Proveedores from "../Components/Admin/Proveedores/Proveedores";
import Categorias from "../Components/Admin/Proveedores/Categorias";
import Usuarios from "../Components/Admin/Usuarios/Usuarios";
import Casas from "../Components/Admin/Casas/Casas";
import Documentos from "../Components/Admin/Documentos/Documentos";
import Mantenimiento from "../Components/Admin/Mantenimiento/Mantenimiento";
import CobroMantenimiento from "../Components/Admin/Mantenimiento/CobroMantenimiento";

import {SocketContext} from "../Hooks/Socket";


//css
import './../Styles/Global.css';

const { Content } = Layout;
//Importacion de Axios para las URL.

/**
 *
 * @export
 * @class AdminRoutes
 * @extends {Component}
 */
export default class AdminRoutes extends Component {

    static contextType = SocketContext;

    state = {
        back: false,
        responsiveSidebar: false,
        showSearch: false,
        filterSearch: "",
        searching: false,
        title: "Dashboard"
    };


    constructor(props){
        super(props);
        this.state = {
            redirect: false,
        }
    }



    componentDidMount() {
        const cookies = new Cookies();

        let token = null;
        let sessionStorageToken = sessionStorage.getItem("token")
        let cookieStorageToken = cookies.get('token');


        // this.verifyLogAdmin();
        if (sessionStorageToken !== null && sessionStorageToken !== undefined)
            token = sessionStorageToken;

        if (cookieStorageToken !== null && cookieStorageToken !== undefined){
            sessionStorage.setItem("token", cookieStorageToken)
            token = cookieStorageToken;
        }
        axios.defaults.headers.common['Authorization'] = token;
        axios.get('/logged')
            .then(({ data }) => {
                console.log('data',data);
                this.props.setUser(data)
            })
            .catch(e => {
                Modal.error({
                    title: "Su sesión ha sido cerrada.",
                    content: "Debe iniciar sesión para continuar",
                    onOk: () => {window.location.replace("/login")}
                })
            })

    }


    /*
    *
    * @methodOf AdminRoutes
    * @function verifyLogAdmin
    *
    * @description verifica que si el usuario esta loegeado y sea Admin, 
    * permite el acceso a las rutas, sino esta logeado lo manda al login
    */
    // verifyLogAdmin = () => {
    //     let token = sessionStorage.getItem("token")
    //     if(token !== null && token !== undefined){
    //         axios.defaults.headers.common['Authorization'] = token;
    //         axios.get('/logged')
    //             .then(({ data }) => {

    //                 console.log('data',data);
    //                 this.props.setUser(data)
    //                 if(data.tipo !== 1){
    //                     this.setState({redirect:true})
    //                 }
    //             })
    //             .catch(e => {
    //                 window.location.replace("/login")
    //         })
    //     }else{
    //         window.location.replace("/login")
    //     }
    // }

    /**
     * Actualiza la información del header
     * */
    updateNavigationInformation = (pageTitle, back = false, onBackPressed = () => { }) => {
        this.onBack = onBackPressed;
        this.setState({
            title: pageTitle,
            back: back
        })
    };

    onBack = () => { };

    render() {
        const { title, back } = this.state;
        const { updateNavigationInformation } = this;

            // if (this.state.redirect) {
            //     return <Redirect to={'/colono/dashboard'} />;
            // }


        return (
            <Layout className="main-layout ">                
                <Header
                    title={title}
                    back={back}
                    onBack={this.onBack}
                    setUser={this.props.setUser}
                />
                <Layout className="route-layout ">
                    <SideBar
                        responsiveSidebar={this.state.responsiveSidebar}
                        hideSidebar={() => this.setState({ responsiveSidebar: false })}
                    />
                    <Layout style={{ maxHeight: "150vh", background: "#f4f8fb", overflow: "auto" }} className="">
                        <Content>
                            <Switch>
                                <Route exact path="/admin/usuarios" render={(props) => <Usuarios {...props} updateNavigationInformation={updateNavigationInformation} />} />

                                <Route exact path="/admin/proveedores" render={(props) => <Proveedores {...props} updateNavigationInformation={updateNavigationInformation} />} />

                                <Route exact path="/admin/categorias" render={(props) => <Categorias {...props} updateNavigationInformation={updateNavigationInformation} />} />

                                <Route exact path="/admin/casas" render={(props) => <Casas {...props} updateNavigationInformation={updateNavigationInformation} />} />

                                <Route exact path="/admin/documentos" render={(props) => <Documentos {...props} updateNavigationInformation={updateNavigationInformation} />} />

                                <Route exact path="/admin/mantenimiento" render={(props) => <Mantenimiento {...props} updateNavigationInformation={updateNavigationInformation} />} />

                                {/*id: colono_id*/}
                                <Route exact path="/admin/mantenimiento/:id" render={(props) => <CobroMantenimiento {...props} updateNavigationInformation={updateNavigationInformation} />} />

                            </Switch>
                        </Content>
                    </Layout>
                </Layout>
            </Layout>
        )
    }
}


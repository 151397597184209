import React, {Component} from "react";
import {Button, Col, Form, Input, message, Modal, Row, Select, Spin, Typography, InputNumber} from 'antd';
import {DeleteOutlined, LoadingOutlined, PlusOutlined} from '@ant-design/icons'
//componenetes
import {IconCloseModal} from '../../../Widgets/Iconos';
//css
import './../../../../Styles/modales.css'


const moment = require('moment');
const axios = require("axios").default;
const {Option} = Select;
const {Title,} = Typography;


class ModalCasas extends Component {

    formModaluser = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            loadingImage: false,
            form: {},

            proyectos: []

        }
    }


    /**
     * @methodOf ModalCasas
     *
     * @function componentDidUpdate
     * @description Se ejecuta cuando se inicia el component, declara en el header el session storage
     *
     * */
    componentDidMount(){
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
    }


    /**
    * @memberof ModalCasas
    *
    * @method   componentDidUpdate
    * @description  Si se va Editar un usuario va y busca la informacion de este
    *
    **/
    componentDidUpdate(prevProps) {
        if(this.props.casa_id !== prevProps.casa_id && this.props.casa_id !== undefined){
            this.getCasa(this.props.casa_id)
        }
    }

    
    /**
    * @memberof ModalCasas
    *
    * @method   onFinish
    * @description  Metodo que se ejecuta al guardar el formulario
    *
    **/
    onFinish = (values) => {
        if (this.props.modalTitle === 'Editar') {
            this.updateCasa(values);
        } else {
            this.addCasa(values);
        }
    }


    /**
    * @memberof ModalCasas
    *
    * @method   addCasa
    * @description  Guarda la casa en la BD
    *
    **/
    addCasa = (values) => {
        axios.post('/casas/add',{
            nombre: values.nombre,
            cuota_mto: values.cuota_mto,
        }).then(response => {
            this.props.closeMethod()
            message.success('La Casa se agrego correctamente')
        }).catch(error => {
            console.log(error)
            message.error('No se pudo agregar la Casa')
        })
    }


    /**
    * @memberof ModalCasas
    *
    * @method   getCasa
    * @description  Trae la infomracion de una categoria
    *
    **/
    getCasa = (id) => {
        axios.get('/casas/get',{
            params:{_id: id}
        }).then(response => {
            this.formModaluser.current.setFieldsValue({
                nombre: response.data.data.nombre,
                cuota_mto: response.data.data.cuota_mto,
            })
        }).catch(error => {
            console.log(error)
            message.error('No se pudo cargar la casa')
        })
    }


    /**
    * @memberof ModalCasas
    *
    * @method   updateCasa
    * @description  actualiza la infomracion de una categoria
    *
    **/
    updateCasa = (values) => {
        axios.post('/casas/update',{
            _id: this.props.casa_id,
            nombre: values.nombre,
            cuota_mto: values.cuota_mto
        }).then(response => {
            this.props.closeMethod()
            message.success('La casa se actualizo correctamente')
        }).catch(error => {
            console.log(error)
            message.error('No se actualizo la casa')
        })
    }


    render() {
        return (
            <Modal
                visible={this.props.visible}
                onCancel={this.props.closeMethod}
                title={null}
                footer={null}
                closable={false}
                className="modal-form"
                destroyOnClose={true}
                zIndex={1000}
                width={500}
            >
                <div className="modal-header">
                    <Button type="ghost" title="Cerrar" className="btn-close-modal" onClick={this.props.closeMethod}>
                        <IconCloseModal/>
                    </Button>
                    <Title level={3} className="modal-title">{this.props.modalTitle} casa</Title>
                </div>
                <Spin spinning={this.state.loading}>

                    <Form
                        layout="vertical"
                        className="mdl-form frm-documentos"
                        name="formulario-tajo"
                        onFinish={this.onFinish}
                        ref={this.formModaluser}
                    >

                        <Row align="center">
                            <Col xs={20}>
                                <Form.Item
                                    label="Nombre"
                                    name="nombre" //<--------------------------------------- nombre
                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese el nombre"
                                    }]}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row align="center">
                            <Col xs={20}>
                                <Form.Item
                                    label="Cuota Mantenimiento"
                                    name="cuota_mto" //<--------------------------------------- cuota_mto
                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese la Cuota de Mantenimiento"
                                    }]}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row style={{textAlign: "center"}}>
                            <Col span={24}>
                                <Form.Item>
                                    <Button htmlType="submit" type="primary" className="btn-green">
                                        Guardar
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        )
    }
}

export default ModalCasas;
